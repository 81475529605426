@use "../../../styles/mixins";

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  max-width: 1010px;
  width: 100%;
  max-height: 850px;
  height: 90vh;
  background-color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  padding: 28px;

  display: flex;
  flex-direction: column;
  gap: 30px;

  .icon {
    &__container {
      background-color: #dfdfdf;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px;
      border: none;
      cursor: pointer;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    &__title {
      @include mixins.text(18px, 400, 24px);
    }
  }

  .description {
    @include mixins.text(14px, 400, 20px);
  }

  .map {
    &__container {
      display: flex;
      flex-direction: column;
      gap: 12px;
      flex: 1;
    }
  }
}

.hidden {
  display: none;
}
