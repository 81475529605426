@use "../../styles/mixins";
@use "../../styles/variables";

.group {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__title {
    @include mixins.text(14px, 400, 24px, variables.$secondary_color);
  }

  &__options {
    display: flex;
    gap: 24px;
  }
}
