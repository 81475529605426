@use "../../styles/mixins";

.buttons {
  display: flex;
  align-self: flex-end;
  gap: 60px;
}

.options {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.checkboxes {
  display: flex;
  // flex-direction: column;
  gap: 15px;
  @media (max-width: 992px) {
    flex-direction: column;
  }
}

.fullscreen {
  &__wrapper {
    display: flex;
    gap: 12px;
    align-items: center;
    cursor: pointer;
    border: none;
    background-color: inherit;
    svg{
      width: 16px;
      height: 16px;
    }
  }
  &__title {
    @include mixins.text(14px, 400, 21px);
    color: #666666;
  }
}

.map__wrapper {
  min-height: 368px;
  display: flex;
}

