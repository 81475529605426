@use "../../styles/mixins";
@use "../../styles/variables";

.radio {
  display: flex;
  align-items: center;
  gap: 10px;

  &__input {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid variables.$primary_color;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      transform: scale(0);
      background-color: variables.$primary_color;
    }

    &:checked::before {
      transform: scale(1);
    }
  }

  &__label {
    @include mixins.text(14px, 400, 21px);
    color: #666666;
    margin-bottom: 0;
  }
}
