.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.main {
  padding: 0 24px 24px;
  display: flex;
  gap: 12px;

  &__info_section {
    flex-basis: 509px;
  }

  &__map_section {
    flex: 1;
  }

  @media (max-width: 1399px) {
    flex-direction: column;
  }
}
