@use "../../styles/mixins";

.button {
  background: rgba(83, 215, 105, 0.1);
  border: 1px solid #53d769;
  box-shadow: 2px 4px 8px rgba(160, 225, 130, 0.7);
  border-radius: 100px;
  padding: 7px;
  padding-right: 16px;
  max-width: max-content;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;

  &__text {
    @include mixins.text(12px, 500, 18px);
  }

  &:hover {
    background: #49454f;
    transition: 0.7s linear;
    .button__text {
      @include mixins.text(12px, 500, 18px, #fff);
      transition: 0.7s linear;
    }

    svg {
      path {
        fill: #fff;
        transition: 0.7s linear;
      }
    }
  }
}
