.subsection {
  display: flex;
  align-items: stretch;
  gap: 18px;
}

.radiusSection {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.fitButton {
  display: flex;
  align-items: center;

  width: 50px;
  height: 50px;
}
