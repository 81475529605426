@use "../../styles/mixins";
@use "../../styles/variables";

.checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  &__label {
    @include mixins.text(14px, 400, 21px);
    color: #666666;
    margin-bottom: 0;
  }

  &__input {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    accent-color: black;
  }
}
