/*--------------------------------

hermes-dashboard-icons Web Font - built using nucleoapp.com
License - nucleoapp.com/license/

-------------------------------- */

body {
  /* background-color: transparent !important; */
  font-family: "Inter", sans-serif !important;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #666;
  background: #2053b5;
}

.GeospaceCompany .place-card.default-card .google-maps-link {
  margin-left: 26px;
}

li.menuItem.pointer.adddatelo {
  background: #e9ecef;
}
.errobox {
  color: #ff0000;
}
/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
.termConditionOverflow::-webkit-scrollbar,
.optionindataclass::-webkit-scrollbar {
  /* background-color:#fff; */
  /* width:16px; */
  width: 6px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  /* background-color:#fff */
}
::-webkit-scrollbar-track:hover {
  /* background-color:#f4f4f4 */
}

/* scrollbar itself */
.termConditionOverflow::-webkit-scrollbar-thumb,
.optionindataclass::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  /* border:5px solid #fff */
}
.termConditionOverflow::-webkit-scrollbar-thumb:hover,
.optionindataclass::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  /* border:4px solid #f4f4f4 */
}

/* set button(top and bottom of the scrollbar) */
.termConditionOverflow::-webkit-scrollbar-button,
.optionindataclass::-webkit-scrollbar-button {
  display: none;
}

.loader-container.loderoff {
  display: flex !important;
}
.optionindataclass {
  max-height: 115px;
  min-height: 115px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 12px 0;
}
.loding > div {
  justify-content: center;
}
.loader-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.834)
    url("https://media.giphy.com/media/8agqybiK5LW8qrG3vJ/giphy.gif") center
    no-repeat;
  z-index: 9999;
}
.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.834);
  z-index: 9999;
}
.indataclass {
  display: flex;
  justify-content: flex-start;
  align-content: space-between;
  align-items: center;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

.spinner1 {
  /* width: 64px;
  height: 64px; */
  /* border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%; */
  font-size: 24px;
  margin: 8px auto;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.tscroll {
  width: 400px;
  overflow-x: scroll;
  margin-bottom: 10px;
  border: solid black 1px;
}

.tps {
  width: 48%;
  /* margin-left: 47%; */
  background: #2054b6;
}
.tscroll table td:first-child {
  position: sticky;
  left: 0;
  background-color: #ddd;
}

.countryMobileWrpper123 .form-control {
  width: 100% !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  height: 48px !important;
}

.tscroll td,
.tscroll th {
  border-bottom: dashed #888 1px;
}
@font-face {
  font-family: "NucleoIcons";
  src: url("../fonts/nucleo-icons.eot");
  src: url("../fonts/nucleo-icons.eot") format("embedded-opentype"),
    url("../fonts/nucleo-icons.woff2") format("woff2"),
    url("../fonts/nucleo-icons.woff") format("woff"),
    url("../fonts/nucleo-icons.ttf") format("truetype"),
    url("../fonts/nucleo-icons.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

.dropdown-container {
  /* text-align: left;
  border: 1px solid #ccc;
  position: relative;
  border-radius: 8px; */
}

.prefixAddModal12 {
  max-width: 495px !important;
  width: 100% !important;
}
.dropdown-input {
  /* padding: 4px 4px 4px 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  background: rgba(83, 215, 105, 0.1);
  height: 40px;
  border-radius: 8px;*/
  cursor: pointer;

  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: rgba(83, 215, 105, 0.1);
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #666 !important;
  font-weight: 300;
  padding: 8px 12px;
}

.dropdown-input .dropdown-selected-value {
  color: #666666;
  flex-grow: 1;
}
.dropdown-tools .dropdown-tool svg path {
  fill: #979798;
}

.honorDropdown {
  border: none;
  position: relative;
}
.honorDropdown .addPlus {
  color: #d7d7d7;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.6px;
  gap: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 4px 18px;
}
.honorDropdown .dropdown-menu {
  border-radius: 12px !important;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 12px 0;
  margin: 0;
  border: none !important;
  max-height: inherit !important;
}
.honorDropdown .search-box {
  padding: 12px;
  background: #fff;
}
.honorDropdown .dropdown-menu .dropdown-item {
  color: #1f1f1f;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
  padding: 4px 8px;
}
.honorDropdown .dropdown-menu .dropdownOuter {
  height: 288px;
  overflow-y: auto;
}

.honorDropdown .dropdown-menu .dropdown-item:hover {
  background: #fff;
}
.honorDropdown .dropdown-menu .dropdown-item.selected {
  background: #fff;
  font-weight: 600;
}

.honorDropdown .dropdown-input {
  background: #ededed;
  border: none;
  padding: 4px 8px 4px 12px;
}

.rsvpTableWrapper.bootstraptrue .react-bootstrap-table {
  min-height: 900px;
}

.rsvpTableWrapper .table td {
  color: #49454f;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
  vertical-align: middle;
}
.scrollbar-inner {
  height: 91% !important;
  overflow-x: hidden;
}
.dropdown-menu {
  position: absolute !important;
  transform: translateY(4px) !important;
  width: 100% !important;
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
  overflow: auto !important;
  max-height: 225px !important;
  background-color: #fff !important;
  z-index: 99 !important;
  display: inline-block !important;
}

.dropdown-item {
  padding: 5px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #9fc3f870;
}

.dropdown-item.selected {
  background-color: #0d6efd;
  color: #fff;
}

.dropdown-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.dropdown-tag-item {
  background-color: #ddd;
  padding: 2px 4px;
  border-radius: 2px;
  display: flex;
  align-items: center;
}

.dropdown-tag-close {
  display: flex;
  align-items: center;
}

.search-box {
  padding: 5px;
  background-color: #eee;
}

.search-box input {
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

img {
  max-width: 100%;
}

html {
  overflow-x: hidden !important;
}
.storyzsection.storyeventlist {
  max-height: 385px !important;
  /* min-height: 336px !important; */
  min-height: inherit !important;
}

.text-end {
  text-align: right;
}

.dashboardLeftOuter {
  background: #2053b5;
}
.objectFitCover {
  object-fit: cover;
}

/* .guestDataTableWrapper .rdt_TableCell[data-column-id="1"],
.rdt_TableCol[data-column-id="1"] {
  background: transparent !important;
} */

.guestDataTableWrapper .gRqCVZ {
  max-height: none !important;
}

.modal.storyzdeletes {
  /* background-color: rgb(0, 0, 0); */
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.accountlogos {
  display: flex;
  align-items: center;
  padding: 0px;
  gap: 10px;
  width: 188px;
  height: 52px;
  background: rgba(233, 233, 233, 0.25);
  border-radius: 36px;
  justify-content: flex-start;
  padding: 6px;
}
.mobilelogos {
  width: 52px;
  height: 52px;
  border-radius: 50px;
  margin: 26px 0px 0 14px;
  object-fit: cover;
  padding: 6px;
  background: rgba(233, 233, 233, 0.25);
}
.logosavename {
  color: #fff;
}

.accountlogos img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  object-fit: cover;
}
.notifications-table tr td {
  border: none;
}
.align-items-center.table-flush.notifications-table.table {
  border: none;
}
.storyzdelet .modal-content {
  background-color: white;
  width: 30%;
  max-width: 640px;
  height: auto;
  margin: auto;
  padding: 24px;
  border: 1px solid black;
  border-radius: 10px;
}
.modal.storyzdelet {
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(
    0,
    0,
    0,
    0.4
  ); /* Overlay effect: translucent background: black w/ partial opacity */
  z-index: 1; /* Overlay effect: positioned over other containers */
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  position: fixed; /* Fix position on the top-left corner*/
  top: 0;
  left: 0;
  overflow: auto; /* Enable scroll if needed */
}
/* Modal content */
.storyzdeletes .textimgs {
  text-align: center;
  margin-top: 15px;
}
.storyzdelet .textimgs {
  text-align: center;
  margin-top: 15px;
}
.storyzdelet .textimgs h2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin-top: 10px;
  text-align: center;
  letter-spacing: 0.4px;

  /* Base / Gray */

  color: #000000;
}
.storyzdeletes .textimgs h2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin-top: 10px;
  text-align: center;
  letter-spacing: 0.4px;

  /* Base / Gray */

  color: #000000;
}
.stdeletes {
  background: rgb(32, 83, 181);
  display: table;
  width: 100%;
  padding: 16px;
}
.storyzdeletes .btn.btn-secondary {
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  gap: 10px;
  background: #147efb !important;
  border-radius: 8px;
  color: #fff;
  width: 100%;
}

.storyzdelet .accountcansel {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  gap: 10px;
  width: 100%;
  color: #000;
  background: #fff;
  border-radius: 8px;
  border: 2px solid #0000ff7d;
}
.storyzdelet .accountcansel:hover {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  gap: 10px;
  width: 100%;
  color: #000;
  background: #fff;
  border-radius: 8px;
  border: 2px solid #0000ff7d;
}
.storyzdelet .accountdelete {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  gap: 10px;
  width: 100%;
  border: 2px solid #fc3158;
  color: #fff;
  /* Primary Colors/Radical Red */

  background: #fc3158;
  border-radius: 8px;
}

.storyzdelet .accountdelete:hover {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  gap: 10px;
  width: 100%;
  border: 2px solid #fc3158;
  color: #fff;
  /* Primary Colors/Radical Red */

  background: #fc3158;
  border-radius: 8px;
}

.storyzdelet .border123 {
  border: 1px solid #e7e7e7;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.storyzdeletes .border123 {
  border: 1px solid #e7e7e7;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.storyzdeletes .storyz-are {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #000000;
}
.storyzdeletes .modal-content {
  background-color: white;
  width: 30%;
  max-width: 640px;
  height: auto;
  margin: auto;
  padding: 20px;
  border: 1px solid black;
  border-radius: 10px;
  float: right;
}
/* Close button */
.storyzdeletes .close {
  color: #aaaaaa;
  float: right; /* Positioned to the right of the parent container whichever size it is */
  font-size: 25px;
  font-weight: bold;
}
.storyzdeletes .close:hover,
.storyzdeletes .close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
}
.eventTimelinep {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #000000;
  padding: 20px;
}

.imgg1 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}
.imgMask1 {
  position: relative;
  height: 158px;
  border: 1px solid #d7d7d7;
  cursor: pointer;
  border-radius: 50%;
  background: #d7d7d7;
  width: 158px;
  overflow: hidden;
}

.time-line-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 500px;
}

.accountinvite .table td {
  border-top: 0px solid #e9ecef !important;
}
.accountinvite .gotoAccount:hover,
.accountinvite .gotoAccount {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px 4px 12px;
  background: rgba(83, 215, 105, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  width: 145px;
  height: 32px;
  color: #000 !important;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.accountinvite .resendinvite img,
.accountinvite .gotoAccount img {
  width: 16px;
  height: 16px;
  object-fit: contain;
  margin-right: 8px;
}
.accountinvite .imagein {
  margin-right: 10px;
  border: 1px solid #00000017;
  border-radius: 50%;
  padding: 0px;
  width: 50px;
  height: 50px;
  -o-object-fit: cover;
  object-fit: cover;
  overflow: hidden;
  border-radius: 50%;
}
.accountinvite .table .thead-light [data-sort]::after {
  float: right !important;
}
.accountinvite .resendinvite {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px 4px 12px;
  background: rgba(254, 203, 46, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  width: 145px;
  height: 32px;

  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.dropdownPlaceholder .form-control {
  height: auto !important;
  min-height: 35px !important;
}
.list {
  width: 200px;
  height: 50%;
  border-radius: 10px;
  box-shadow: 2px 2px silver;
}
.itemRenderer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: solid 1px silver;
  background-color: white;
  color: silver;
  width: 100%;
  padding: 5px;
}

.itemRendererSelected {
  background-color: rgb(219, 223, 230);
}

.itemRenderer:hover {
  background-color: rgb(219, 223, 230);
}
.itemImage {
  flex: 1 0 40px;
  border-radius: 30px;
  width: 40px;
}
.itemTitleContainer {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4px;
}

.itemTitle {
  color: grey;
  font-size: 14px;
  align-self: center;
}
.itemContent {
  color: silver;
  font-size: 10px;
  align-self: center;
}

.nav-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  background: -moz-linear-gradient(top, #eeeeee 0%, #cccccc 100%);
  background: -webkit-linear-gradient(
    top,
    #eeeeee 0%,
    #cccccc 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #eeeeee 0%, #cccccc 100%);
  height: 50px;
  color: grey;
  align-items: center;
  background: #eeeeee;
  border: 1px solid silver;
  border-bottom: none;
}

.mode-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  justify-self: flex-end;
  margin: 0px 20px;
  gap: 12px;
}
.mode-container-title {
  margin: 0px 20px;
  flex: 1 1 auto;
  margin: 0px 20px;
  text-shadow: 0.5px 0.5px white;
  font-family: Helvetica, sans-serif;
  font-size: 22px;
  font-weight: bolder;
}

.mode-container-item {
  flex: 0 0 auto;
  width: 80px;
  padding: 5px;
  border: solid 1px transparent;
  background-color: transparent;
  color: #000;
  font-size: 14px;
  text-align: center;
  cursor: pointer;

  transform: none !important;
  border-color: #979798 !important;
  position: relative;
  text-transform: none;
  transition: all 0.15s ease;
  letter-spacing: 0.025em;
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
  line-height: 1.5;
  border-radius: 0.25rem !important;
  background-color: #fff;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
}

.operation-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  justify-self: flex-end;
}

.mode-button {
  background-color: #333333;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin: 0px 3px;
}

.mode-button:hover {
  background-color: rgb(202, 33, 212);
}

.mode-container-item-left {
  border-top-left-radius: 17px;
  border-bottom-left-radius: 17px;
}

.mode-container-item-right {
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
}

.mode-container-item-editable-toggle {
  margin-left: 20px;
  width: 150px;
  display: none;
}
.text123 {
  display: block;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: 0.4px;

  /* Grayscale/Grey Very Dark */

  color: #1f1f1f;
}
.popdataWrapper {
  margin: 0 80px 24px;
}

.popdata {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
}
.popdata .popdate {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #1f1f1f;
}
.popdata .podImg {
  width: 79px;
  height: 53px;
}
.popdata .podImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.popdata .title {
  color: #1f1f1f;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.popdata .subTitle {
  color: #1f1f1f;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 12px;
  letter-spacing: 0.4px;
}
.popdataOrg {
  padding: 0;
}
.popdataOrg .podImg {
  width: 60px;
  height: 60px;
}
.popdataOrg .podImg img {
  border-radius: 100%;
}
.popdataOrg .title {
  color: #49454f;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.4px;
}

.popdataOrg .subTitle {
  color: #49454f;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.4px;
}

.storyzDeleteModal .modal-content {
  max-width: 420px !important;
}
.popdataStoryz {
  padding: 0;
}
.popdataStoryz .podImg {
  width: 48px;
  height: 48px;
}
.popdataStoryz .podImg img {
  border-radius: 100%;
}

.text345 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height, or 120% */

  letter-spacing: 0.4px;

  /* Grayscale/Grey Very Dark */

  color: #1f1f1f;
}
.imgMask {
  position: relative;
  height: 200px;
  border: 1px solid #d7d7d7;
  cursor: pointer;
  border-radius: 6px;
  background: #d7d7d7;
}
.imgMask-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  opacity: 0.5;
}
.imgMask1:hover .imgMask-content {
  opacity: 1;
}
.imgg {
  position: absolute;
  width: 359px;
  height: 200px;
  top: 39px;
  left: 16px;
  opacity: 0;
  cursor: pointer;
}

.imageuplode {
  position: relative;
  height: 200px;
  background: #d7d7d7;
  border-radius: 14px;
}

.disabledNew {
  opacity: 0.5;
  cursor: not-allowed;
}
#blah1 {
  /* position: absolute; */
  width: 100%;
  height: 200px;
  /* top: 0; */
  display: block;
  /* left: 14px; */
  border-radius: 14px;
  object-fit: cover;
}
#blah {
  position: absolute;
  width: 93%;
  height: 51%;
  top: 40px;
  left: 14px;
  border-radius: 17px;
}
.lablepoint {
  display: flex;
  justify-content: space-between;
}
.buticon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  cursor: pointer;
}
.buticon .iconBtn {
  height: 20px;
  width: 20px;
  min-width: 20px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 20px;
  font-size: 16px;
}
.buticon .iconBtn:active,
.buticon .iconBtn:focus,
.buticon .iconBtn:hover {
  box-shadow: none !important;
  transform: none;
}
.rberror {
  color: rgb(176, 0, 32);
  font-size: 12px;
  margin-left: 12px;
  margin-top: 4px;
}
.captionBox {
  width: 100%;
  min-height: 96px;
  background: rgba(207, 207, 207, 0.15);
  border-radius: 6px;
  padding: 12px;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #000000;
}
.captionBox1 {
  height: 86px;
  margin-top: 20px;
}

.hcolor {
  color: #1f1f1f;
}

.tododeleteionModal {
  max-width: 360px !important;
}

/* .AdDLiSt_BuTtON {
  padding-top: 0;
  text-align: center;
  left: 0;
  right: 0;
  background: #fff;
  padding: 20px 0;
  position: sticky;
  top: calc(-76px + 60px + 14px);
  top: 0;
  width: 100%;
  z-index: 9;
  padding: 0;
} */

.AdDLiSt_BuTtON {
  padding-top: 0;
  text-align: center;
  left: 0;
  right: 0;
  padding: 20px 0;
  position: sticky;
  top: calc(-76px + 60px + 14px);
  width: 100%;
  z-index: 9;
  padding: 0;
  padding-bottom: 12px;
  width: fit-content;
  margin: 0 auto;
}

.rts___tab___selected {
  background-color: transparent;
  background-color: transparent;
  color: transparent;
  color: transparent;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
}
.rts___btn {
  border: 0px solid transparent !important;
}
.rts___tab___selected {
  background-color: transparent !important;
  background-color: transparent !important;
  box-shadow: rgb(50 50 93 / 11%) 0px 1px 4px !important;
}
.we2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  background: rgba(207, 207, 207, 0.28);
  width: 263px;
  height: 27px;
}

.greetingDescription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px;
  gap: 10px;
  /* width: 100%; */
  height: 48px;
  background: rgba(207, 207, 207, 0.4);
  border-radius: 6px;
}

.selectToall {
  position: relative;
}
.css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
  max-height: 180px;
  overflow: hidden;
}

.deletebuttone {
  padding: 4px 16px 4px 12px;
  gap: 6px;
  width: 180px;
  background: #b3162e;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  float: inline-end;
}
.selectToall .selectToall {
  position: relative;
}
.selectToall .selecttodata label {
  margin-top: 0;
}
.selectToall .selecttodata .buttonselect {
  margin-top: 0px;
}
.selecttodata div {
  outline: none !important;
}
.selectToall .selecttodata {
  max-height: 290px;
  overflow-y: hidden;
  position: absolute;
  width: 100%;
  background: #fff;
  z-index: 9999999;
}
.storytoimage {
  width: 148px;
  border-radius: 50%;
  height: 148px;
  object-fit: cover;
}

.storytoimage346 {
  width: 100%;
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 1;
}

.evimages {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 12px;
  width: 79px;
  height: 53px;
  border-radius: 12px;
  object-fit: cover;
}
.storytoimageev {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 1;
}
.wedding {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  text-transform: capitalize;
}
.imgclast {
  margin-left: 20px;
}

.moredetails {
  display: flex;
  justify-content: flex-end;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #000000;
}
.stclass {
  justify-content: center;
  align-items: center;
  padding: 6px 8px;

  width: 57px;
  height: 24px;

  background: #ffffff;
  border-radius: 16px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */

  letter-spacing: 0.4px;

  color: #000000;
}
.storyzname {
  font-family: "Great Vibes";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 32px;
  color: #666666;
}
.fontSize12 {
  font-size: 12px;
}
.fontSize48 {
  font-size: 48px;
}
.fontSize10 {
  font-size: 10px;
}
.fontSize18 {
  font-size: 18px;
}
.fontSize24 {
  font-size: 24px;
}
.fontSize20 {
  font-size: 20px;
}
.coverimage {
  display: flex;
  justify-content: space-between;
}

.fontSize36 {
  font-size: 36px;
  padding: 0;
  margin: 0;
  text-align: right;
}

.daclass {
  background: #778a6e;
  display: block;
  padding: 5px 18px 5px 18px;
  color: #fff;
  width: fit-content;
  border-radius: 50px;
}
.fontSize18 {
  font-size: 18px;
}
.dateyourlocal {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.evavter {
  display: flex;
  justify-content: space-evenly;
}
.progress {
  background-color: #fff !important;
}
.progress-bar {
  background-color: #fc3158 !important;
}
.caldiv {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
}

.pagesubtitle {
  padding: 10px;
  margin: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: #49454f;
}
.exportbillingdata {
  margin: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #49454f;
}
.blankdiv {
  margin: 5px;
  width: 230px;
  height: 0px;
  border: 0.009px solid rgba(253, 253, 253, 0.5);
}

.g-sidenav-hidden .blankdiv {
  width: 72px;
}

.newstoryzto {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  width: 107px;
  height: 36px;
  background: transparent;
  border-radius: 100px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #1d192b;
  letter-spacing: 0.4px;
  float: right;
  margin-top: 95px;
}
.newstoryz:focus,
.newstoryz:hover,
.newstoryz {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  width: fit-content;
  height: 39px;
  background: rgba(198, 193, 203, 0.2);
  border-radius: 100px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #1d192b;
  letter-spacing: 0.4px;
  float: right;
  margin-top: 10px;
}
.exportbillingdataimag {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
.g-sidenav-hidden .navbar-vertical.navbar-expand-xs {
  max-width: 82px !important;
  transition: all 0.5s ease;
  /* padding-left: 10px !important; */
}

/* .reports,
.organizationdashboard {
  padding-top: 30px !important;
} */

.organizationdashboard .cardWrapper {
  height: 100%;
  padding-bottom: 30px;
}
.organizationdashboard .cardWrapper a {
  color: #49454f;
}
.organizationdashboard .cardWrapper .card {
  margin-bottom: 0px !important;
  height: 100%;
}
.organizationdashboard .organizaion-Files {
  min-height: 350px;
}
.pagetool {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hrclass {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.page-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #000000;
  padding: 23px;
  border-bottom: 1px solid #e7e7e7;
}

.locatorWrapper .card {
  min-height: 870px !important;
}

.layer {
  background-color: #f8f9feb0;
  padding: 24px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* min-height: 948px; */
  min-height: 928px;
}

.layer .badge {
  padding: 2px 4px;
  color: #fdfdfd;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.6px;
  border-radius: 4px;
  background: #666;
  font-family: "Inter", sans-serif !important;
  text-transform: inherit;
  margin-bottom: 12px;
  margin-top: 8px;
}

.storyabout .coverimagest {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
}
.storydaall p {
  font-size: 18px;
  font-weight: 600;
}
.buttonfute {
  cursor: pointer;
  background: #2053b5 !important;
  color: #fff !important;
}
.storydaall {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
button.buttonselect {
  width: 100%;
  text-align: inherit;
}
.buttonselect.css-in3me7-ButtonBase {
  background: rgba(83, 215, 105, 0.1);
}
.storyabout .imgMask1 {
  position: relative;
  height: 200px;
  border: 1px solid #d7d7d7;
  cursor: pointer;
  border-radius: 50%;
  background: #d7d7d7;
  width: 200px;
  overflow: hidden;
}
.storyabout .information {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #666666;
  margin-bottom: 3px;
}
.backgroeventdata {
  /* background-image: url("../../../img/e7d345fae0c7184e121fa3b3520ce064.png"); */
  /* background-repeat: no-repeat !important; */
  /* background-size: 100% 1200px !important; */
  position: relative;
  border-top-right-radius: 12px;
}
.storyabout .imgg1 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
  height: 200px;
  border: 1px solid #d7d7d7;
  cursor: pointer;
  border-radius: 50%;
  background: #d7d7d7;
  width: 200px;
  overflow: hidden;
}
.backgroevent {
  background-image: url("../../../img/e7d345fae0c7184e121fa3b3520ce064.png");
  /* background-image: url("../../../img/storyBgNew.png"); */
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  border-radius: 12px;
  /* padding: 24px; */
  position: relative;
}
a {
  color: #525f7f;
}

.newTodo {
  background: rgba(198, 193, 203, 0.2);
  color: #000 !important;
  padding: 10px 15px 10px 15px;
  border-radius: 50px;
  font-size: 14px;
  float: right;
  /* margin-left: 28px; */
  /* position: absolute;
  right: 0;
  bottom: 0; */
}

.storyzDashboard .storyzsection .story1 img {
  border-radius: 15px 15px 15px 15px;
  margin-right: 12px;
  width: 100px;
  height: 100px;
}
.storyzDashboard .storyzsection .story1 .title a,
.storyzDashboard .storyzsection .story1 .title {
  color: #1f1f1f;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 0.4px;
  margin-bottom: 4px;
}
.storyzDashboard .storyzsection .story1 .subtitle {
  color: #1f1f1f;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.6px;
  margin-bottom: 4px;
}
.storyzDashboard .storyzsection .story1 .subtitle1 {
  color: #1f1f1f;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  margin-bottom: 4px;
}
.storyzDashboard .storyzsection .story1 .description {
  color: #1f1f1f;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.6px;
}

.todolistst {
  margin: 10px 0;
}
.todo-list {
  margin: 10px 0;
  max-height: 606px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 20px;
  margin-right: -20px;
}
font .Jakarta {
  font-family: "Plus Jakarta Sans", sans-serif !important;
}

.GreatVibes {
  font-family: "Great Vibes", cursive !important;
}

.loctain {
  display: flex;
  background: #778a6e;
  padding: 13px;
  color: #fff;
  border-radius: 13px;
  margin: 0 !important;
}
.todo-list .todo-item {
  padding: 15px;
  margin: 10px 0;
  border-radius: 0;
  background: #f7f7f7;
  display: flex;
  justify-content: space-between;
}
.JakartaMedium300 {
  font-weight: 300;
}
.Jakartanormal {
  font-weight: 400;
}
.JakartaMedium500 {
  font-weight: 500;
}
.Jakartasemibold600 {
  font-weight: 600;
}
.Jakartabold700 {
  font-weight: 700;
}

select#exampleFormControlSelect3 {
  background: rgba(83, 215, 105, 0.1);
}
.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
  border-color: #000 !important;
}
.custom-control-input:checked ~ .custom-control-label:before {
  background-color: #000 !important;
  border-color: #000 !important;
  box-shadow: 0 3px 2px rgb(233 236 239 / 5%);
  color: #fff;
}
/*------------------------
    base class definition
-------------------------*/
.ni {
  display: inline-block;
  font: normal normal normal 14px/1 NucleoIcons;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.alinkactive {
  color: rgb(255, 255, 255) !important;
}
.CurrentPaymentMethod {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  display: flex;
  align-items: center;
  color: #666666;
  margin: 0px;
}

.emstore {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  align-items: center;
}
.BillingHistory {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #000000;
  margin: 0;
}
.makeaPayment {
  border: none !important;
  margin-top: 19px;
  /* margin-top: 64px; */
  /* float: right; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  width: 123px;
  height: 39px;
  background: #fc3158;
  border-radius: 100px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: #fdfdfd;
}
.exportbilling {
  background: rgba(83, 215, 105, 0.1) !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  border-radius: 10px !important;
}
.coverimagest {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
}
.coverimagest p {
  margin-left: 10px;
}

.stcaldiv {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  padding: 10px;
}
.floatingSearchDialog .modal-content {
  border-radius: 30px !important;
}
.ptop {
  padding-top: 0px;
}
/* .navbar-vertical.navbar-expand-xs {
  z-index: 999;
} */
.topbarnavbar {
  width: 100%;
  /* z-index: 99; */
}
.deleteaccountowner .modal-content {
  background-color: #fff !important;
  color: #000 !important;
}
.deleteaccountowner .modaltitle {
  font-size: 18px;
  font-weight: 300;
}
.deleteaccountowner .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0px 24px 0px 24px !important;
}
.floatingSearchDialog .modal-header {
  background: #ff8b85;
  border-radius: 30px 30px 0px 0px;
}
.floatingSearchDialog .story1ev img {
  margin-right: 10px;
  width: 59px;
}
.floatingSearchDialog .story1 img {
  border-radius: 20px;
  margin-right: 20px;
  width: 100px;
  height: 100px;
}
.floatingSearchDialog .modal-footer {
  background: #ff8b85;
  border-radius: 0px 0px 30px 30px;
}
.floatingSearchDialog .title {
  margin-left: 15px;
  font-size: 18px;
  color: #ffff;
  font-weight: 400;
}

tbody.clientdata tr {
  background: rgba(207, 207, 207, 0.15) !important;
}
.billtable th,
.billtable td {
  padding: 6px !important;
}
.mooo {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  padding: 0px 0px 0px 20px;
  margin: 0px;
  letter-spacing: -0.02em;
  color: #fdfdfd;
}
.daon {
  display: block;
  background: #fecb2e;
  border-radius: 4px;
  position: relative;
  color: #fff;
  padding: 2px;
  top: -7px;
  color: #fdfdfd;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 900;
  line-height: 10px;
  letter-spacing: 0.1px;
  height: 16px;
}
.order-Summary span {
  display: block;
  line-height: 22px;
  font-size: 10px;
}

button.btn-neutral.changePlan.btn.btn-secondary {
  border-radius: 50px;
  background: #147efb;
  color: #fff;
}

.eventCreate {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  color: #fdfdfd;
}
.bgcard {
  background-image: url("../../../img/cardimg1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
button.btn-neutral.change-Plan.btn.btn-default.btn-sm {
  border: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  height: 39px;
  background: #fdfdfd;
  border-radius: 100px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: #1d192b;
  width: 107.62px;
}
/*------------------------
  change icon size
-------------------------*/
.ni-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.ni-2x {
  font-size: 2em;
}
.ni-3x {
  font-size: 3em;
}
.ni-4x {
  font-size: 4em;
}
.ni-5x {
  font-size: 5em;
}

/*----------------------------------
  add a square/circle background
-----------------------------------*/
.ni.square,
.ni.circle {
  padding: 0.33333333em;
  vertical-align: -16%;
  background-color: #eee;
}
.ni.circle {
  border-radius: 50%;
}
/*------------------------
  list icons
-------------------------*/
.ni-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}
.ni-ul > li {
  position: relative;
}
.ni-ul > li > .ni {
  position: absolute;
  left: -1.57142857em;
  top: 0.14285714em;
  text-align: center;
}
.ni-ul > li > .ni.lg {
  top: 0;
  left: -1.35714286em;
}
.ni-ul > li > .ni.circle,
.ni-ul > li > .ni.square {
  top: -0.19047619em;
  left: -1.9047619em;
}
.story1 {
  display: flex;
  /* align-items: center; */
  margin-bottom: 4px;
  padding: 4px;
}
.story1 .titlee {
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: #1f1f1f;
}
.story1 img {
  border-radius: 100%;
  margin-right: 8px;
  width: 79px;
  height: 79px;
  object-fit: cover;
}
.story1 .date {
  width: 90px;
  min-width: 90px;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #1f1f1f;
}
.eventimag {
  min-width: 136px !important;
  border-radius: 15px !important;
}
.story1 .title {
  font-size: 20px;
  line-height: 24px;
  margin: 0px;
  font-weight: 300;
  color: #1f1f1f;
}
.storyzSlide {
  max-height: 190px !important;
  min-height: 190px !important;
}
.storyzSlide .story1:hover {
  background: #f8f8f8;
}
/* .storyzsection {
  min-height: 320px;
  overflow-y: auto !important;
  overflow-x: hidden !important;
} */
.story1 .subtitle {
  font-size: 12px;
  line-height: 24px;
  margin: 0px;
  color: #1f1f1f;
}
.story1 .description {
  font-size: 12px;
  line-height: 24px;
  margin: 0px;
}
.story1 .subtitle1 {
  font-size: 12px;
  line-height: 24px;
  margin: 0px;
  color: #1f1f1f;
}
.organizationlocator .storyzsection .story1 img {
  border-radius: 15px 15px 15px 15px;
  margin-right: 20px;
}
.organizationdiy .storyzsection {
  max-height: 342px !important;
  overflow-y: auto;
  overflow-x: hidden;
}
.storyzsection {
  max-height: 210px;
  min-height: 210px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 12px 0;
}
.eventSidebarPopup {
  max-height: 210px;
  min-height: 210px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 12px 0;
}

.eventListSection {
  max-height: 372px;
  min-height: inherit;
  /* min-height: 210px; */
}

.storyzsection .story1:hover {
  background: #f8f8f8;
}

/* .storyzsection .story1ev {
} */
/* .storyzsection.storyzsection23 {
  max-height: 300px !important;
  min-height: 300px !important;
} */
.storyzsection.storyzsection23 .story1 img {
  border-radius: 50px;
  margin-right: 8px;
  width: 100px !important;
  height: 100px !important;
  object-fit: cover;
}
.user-pading {
  display: grid;
  grid-template-columns: 60px auto;
  grid-column-gap: 20px;
  padding-left: 5px;
}
.user-pading .avatar {
  vertical-align: middle;
  width: 60px !important;
  height: 60px !important;
  border-radius: 50%;
}
/* .sp {
  display: flex;
  justify-content: space-around;
  text-align: center;
}
.sp div {
  width: 124px;
}
.sp div .icomn {
  height: 40px;
  object-fit: scale-down;
}
.sp p {
  font-size: 16px;
  font-weight: 300;
  margin: 3px;
  color: #49454f;
} */

.organizationdiy .storyzsection {
  max-height: 425px;
  overflow-y: auto;
  overflow-x: hidden;
}
.organizationdiy .storyzsection .story1 img {
  border-radius: 15px 15px 15px 15px;
  margin-right: 20px;
}
.organizationlocator .card-header {
  display: flex;
  border-bottom: 1px solid #e7e7e7 !important;
}
.gotobilling {
  text-align: center;
  margin-left: 25px;
  margin-top: 5px;
}
.gotobilling p {
  font-size: 12px;
  background: rgba(198, 193, 203, 0.2);
  padding: 6px 14px 5px 14px;
  border-radius: 33px;
  margin-top: 6px;
  font-weight: 500;
}
.professional span {
  display: block;
}
.input-group-prepend {
  margin-right: 2px !important;
}
::placeholder {
  color: #adb5bd;
  opacity: 1;
  font-style: italic;
}
.errorborder {
  border: 1px solid #fb6340 !important;
}
.error {
  color: #ff0000a1;
  font-size: 14px;
  line-height: 24px;
  padding: 4px;
}
.pc {
  display: flex !important;
  flex-direction: row;
  justify-content: flex-end !important;
  align-items: stretch !important;
  flex-wrap: nowrap !important;
}
.usernameonly {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: #000000;
  display: block;
  margin-bottom: 12px;
}
.incls {
  width: 100%;
  border: none;
  padding: 0.625rem 0.75rem;
}
.companyBrand {
  border: 1px solid rgba(198, 193, 203, 0.4);
  border-radius: 50%;
  padding: 4px;
  object-fit: cover;
}
.companyBrandBtn {
  display: flex;
  height: 39px;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: rgba(198, 193, 203, 0.2);
  width: fit-content;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  font-family: "Inter", sans-serif !important;
  color: #1d192b;
}
.upload-btn-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  width: 107px;
  height: 39px;
  background: rgba(198, 193, 203, 0.2);
  border-radius: 100px;
  color: #1d192b !important;
  position: relative;
}
.upload-btn-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}
.upload-btn-wrapper .btn {
  border: 1px solid rgba(198, 193, 203, 0.2);
  color: gray;
  background-color: rgba(198, 193, 203, 0.2);
  padding: 8px 20px;
  border-radius: 30px;
  font-size: 12px;
  font-weight: bold;
}
.evname {
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  text-align: right;
  letter-spacing: 0.4px;
  color: #1f1f1f;
}
.organizationimg {
  border: 1px solid rgba(198, 193, 203, 0.4);
  border-radius: 50%;
  padding: 10px;
  margin-right: 8px;
}
.divround .img-fluid {
  width: 35px;
  height: 35px;
  object-fit: cover;
  max-width: 35px;
  max-height: 35px;
}
.divround {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.orgDashImg {
  overflow: hidden;
  padding: 0;
}
.orgDashImg .img-fluid {
  height: 57px;
  max-height: 57px;
  max-width: 57px;
  object-fit: cover;
  width: 57px;
  padding: 0;
}

/*------------------------
  spinning icons
-------------------------*/
.ni.spin {
  -webkit-animation: nc-spin 2s infinite linear;
  -moz-animation: nc-spin 2s infinite linear;
  animation: nc-spin 2s infinite linear;
}
@-webkit-keyframes nc-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes nc-spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@keyframes nc-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*------------------------
  rotated/flipped icons
-------------------------*/
.ni.rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.ni.rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.ni.rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}
.ni.flip-y {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.ni.flip-x {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1);
}
/*------------------------
    font icons
-------------------------*/

.ni-active-40::before {
  content: "\ea02";
}

.ni-air-baloon::before {
  content: "\ea03";
}

.ni-album-2::before {
  content: "\ea04";
}

.ni-align-center::before {
  content: "\ea05";
}

.ni-align-left-2::before {
  content: "\ea06";
}

.ni-ambulance::before {
  content: "\ea07";
}

.ni-app::before {
  content: "\ea08";
}

.ni-archive-2::before {
  content: "\ea09";
}

.ni-atom::before {
  content: "\ea0a";
}

.ni-badge::before {
  content: "\ea0b";
}

.ni-bag-17::before {
  content: "\ea0c";
}

.ni-basket::before {
  content: "\ea0d";
}

.ni-bell-55::before {
  content: "\ea0e";
}

.ni-bold-down::before {
  content: "\ea0f";
}

.ni-bold-left::before {
  content: "\ea10";
}

.ni-bold-right::before {
  content: "\ea11";
}

.ni-bold-up::before {
  content: "\ea12";
}

.ni-bold::before {
  content: "\ea13";
}

.ni-book-bookmark::before {
  content: "\ea14";
}

.ni-books::before {
  content: "\ea15";
}

.ni-box-2::before {
  content: "\ea16";
}

.ni-briefcase-24::before {
  content: "\ea17";
}

.ni-building::before {
  content: "\ea18";
}

.ni-bulb-61::before {
  content: "\ea19";
}

.ni-bullet-list-67::before {
  content: "\ea1a";
}

.ni-bus-front-12::before {
  content: "\ea1b";
}

.ni-button-pause::before {
  content: "\ea1c";
}

.ni-button-play::before {
  content: "\ea1d";
}

.ni-button-power::before {
  content: "\ea1e";
}

.ni-calendar-grid-58::before {
  content: "\ea1f";
}

.ni-camera-compact::before {
  content: "\ea20";
}

.ni-caps-small::before {
  content: "\ea21";
}

.ni-cart::before {
  content: "\ea22";
}

.ni-chart-bar-32::before {
  content: "\ea23";
}

.ni-chart-pie-35::before {
  content: "\ea24";
}

.ni-chat-round::before {
  content: "\ea25";
}

.ni-check-bold::before {
  content: "\ea26";
}

.ni-circle-08::before {
  content: "\ea27";
}

.ni-cloud-download-95::before {
  content: "\ea28";
}

.ni-cloud-upload-96::before {
  content: "\ea29";
}

.ni-compass-04::before {
  content: "\ea2a";
}

.ni-controller::before {
  content: "\ea2b";
}

.ni-credit-card::before {
  content: "\ea2c";
}

.ni-curved-next::before {
  content: "\ea2d";
}

.ni-delivery-fast::before {
  content: "\ea2e";
}

.ni-diamond::before {
  content: "\ea2f";
}

.ni-email-83::before {
  content: "\ea30";
}

.ni-fat-add::before {
  content: "\ea31";
}

.ni-fat-delete::before {
  content: "\ea32";
}

.ni-fat-remove::before {
  content: "\ea33";
}

.ni-favourite-28::before {
  content: "\ea34";
}

.ni-folder-17::before {
  content: "\ea35";
}

.ni-glasses-2::before {
  content: "\ea36";
}

.ni-hat-3::before {
  content: "\ea37";
}

.ni-headphones::before {
  content: "\ea38";
}

.ni-html5::before {
  content: "\ea39";
}

.ni-istanbul::before {
  content: "\ea3a";
}

.ni-key-25::before {
  content: "\ea3b";
}

.ni-laptop::before {
  content: "\ea3c";
}

.ni-like-2::before {
  content: "\ea3d";
}

.ni-lock-circle-open::before {
  content: "\ea3e";
}

.ni-map-big::before {
  content: "\ea3f";
}

.ni-mobile-button::before {
  content: "\ea40";
}

.ni-money-coins::before {
  content: "\ea41";
}

.ni-note-03::before {
  content: "\ea42";
}

.ni-notification-70::before {
  content: "\ea43";
}

.ni-palette::before {
  content: "\ea44";
}

.ni-paper-diploma::before {
  content: "\ea45";
}

.ni-pin-3::before {
  content: "\ea46";
}

.ni-planet::before {
  content: "\ea47";
}

.ni-ruler-pencil::before {
  content: "\ea48";
}

.ni-satisfied::before {
  content: "\ea49";
}

.ni-scissors::before {
  content: "\ea4a";
}

.ni-send::before {
  content: "\ea4b";
}

.ni-settings-gear-65::before {
  content: "\ea4c";
}

.ni-settings::before {
  content: "\ea4d";
}

.ni-single-02::before {
  content: "\ea4e";
}

.ni-single-copy-04::before {
  content: "\ea4f";
}

.ni-sound-wave::before {
  content: "\ea50";
}

.ni-spaceship::before {
  content: "\ea51";
}

.ni-square-pin::before {
  content: "\ea52";
}

.ni-support-16::before {
  content: "\ea53";
}

.ni-tablet-button::before {
  content: "\ea54";
}

.ni-tag::before {
  content: "\ea55";
}

.ni-tie-bow::before {
  content: "\ea56";
}

.ni-time-alarm::before {
  content: "\ea57";
}

.ni-trophy::before {
  content: "\ea58";
}

.ni-tv-2::before {
  content: "\ea59";
}

.ni-umbrella-13::before {
  content: "\ea5a";
}

.ni-user-run::before {
  content: "\ea5b";
}

.ni-vector::before {
  content: "\ea5c";
}

.ni-watch-time::before {
  content: "\ea5d";
}

.ni-world::before {
  content: "\ea5e";
}

.ni-zoom-split-in::before {
  content: "\ea5f";
}

.ni-collection::before {
  content: "\ea60";
}

.ni-image::before {
  content: "\ea61";
}

.ni-shop::before {
  content: "\ea62";
}

.ni-ungroup::before {
  content: "\ea63";
}

.ni-world-2::before {
  content: "\ea64";
}
button.kep-login-facebook.metro {
  width: 100%;
  padding: 10px;
  margin-bottom: 14px;
  border-radius: 12px;
  font-size: 14px;
  text-transform: inherit;
}
.ni-ui-04::before {
  content: "\ea65";
}

/* all icon font classes list here */

.btn-neutral {
  border-color: #979798 !important;
  box-shadow: none !important;
  transform: none;
}
.btn-neutral.btn-icon.btn.btn-default {
  width: 100%;
  margin-bottom: 14px;
  border-radius: 12px;
}
.kep-login-facebook .fa {
  padding: 3px;
  background: #fff;
  color: #4c69ba;
  border-radius: 100%;
  width: 16px;
  font-size: 10px;
  height: 16px;
  font-weight: 900;
}
button.kep-login-facebook.metro {
  width: 100%;
  padding: 10px;
  margin-bottom: 14px;
  border-radius: 12px;
  font-size: 14px;
}
.googlelogin {
  width: 100%;
  justify-content: center;
  border-radius: 12px !important;
  color: #1f1f1f !important;
}

.copyright.text-center.text-xl-left.text-muted {
  text-align: right !important;
  color: #fff !important;
}
.copyright a {
  color: #fff;
}
body.g-sidenav-show.g-sidenav-pinned.bg-default {
  background-image: none;
}
.g-sidenav-pinned,
body.g-sidenav-show.g-sidenav-pinned,
body.g-sidenav-hidden {
  /* background-image: url("../../../img/brand/4f69709755fd616a8a8572fd5d1e9f03.png"); */
  /* background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed; */
  background: #2053b5;
}

.main-content.login-backimage {
  background-image: url("../../../img/icons/common/fe308856b7810ce92b3df66fec740aa2.png");
  background-repeat: no-repeat;
  background-size: 100%;
}

.bgsecondary.border-0.mb-0.card {
  padding: 0px 12px 0px 12px;
}
.form-check-input {
  margin-top: 0.2rem !important;
}
.card-header {
  border-bottom: none !important;
}
.my-4.btn.btn-secondary {
  width: 100%;
  background: #ff8b85;
  border-radius: 12px;
}
.twofactorauth .card {
  margin-bottom: 30px !important;
  box-shadow: 0px 4px 3px 0px rgb(0 0 0 / 25%) !important;
  border-radius: 4px !important;
}
.authenticatetow:hover,
.authenticatetow {
  color: #000;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: rgba(83, 215, 105, 0.1);
  font-weight: 300;
  padding: 4px 12px;
  height: 32px;
  transform: none;
}

.authenticatorBtn {
  color: rgb(0, 0, 0);
  font-size: 14px;
  font-weight: 300;
  margin: 0;
  white-space: nowrap;
  padding: 4px 12px;
  line-height: 24px;
  border-radius: 8px;
  letter-spacing: 0.4px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: rgba(83, 215, 105, 0.1);
  height: 32px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
.authenticatorBtn:disabled {
  background: #e7e7e7;
  color: #979798;
}
.sendSMSBtn {
  color: rgb(0, 0, 0) !important;
  border: none !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  margin: 0;
  white-space: nowrap;
  padding: 4px 12px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: rgba(83, 215, 105, 0.1);
  height: 32px;
}

.basicinformation {
  padding: 12px;
  background: rgba(198, 193, 203, 0.3);
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  border-radius: 6px;
  color: #000;
  gap: 12px;
  display: flex;
}
.basicinformation img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
footer#footer-main {
  background: #000;
}

.sidenav {
  height: calc(100vh - 51px);
}

nav.sidenav.navbar-vertical.navbar-expand-xs.navbar-light.bg-white.fixed-left.navbar {
  background: #2053b5 !important;
  transition: all 0.5s ease;
  z-index: 999;
}
.topbarnavbar .menuheader {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.CSVImporter_ProgressDisplay__status {
  display: none;
}
.topbarnavbar .menuheader .dashIcon {
  padding-right: 12px;
  cursor: pointer;
}
.topbarnavbar .menuheader .dashIcon1 {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  object-fit: cover;
}
.topbarnavbar .menuheader .title {
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  margin-left: 12px;
  color: #fdfdfd;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 0;
  /* font-family: "Great Vibes"; */
}

.topbarnavbar .menuheader .badge {
  padding: 2px 4px;
  color: #fdfdfd;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.6px;
  border-radius: 4px;
  margin-bottom: 4px;
  background: #666;
  font-family: "Inter", sans-serif !important;
  text-transform: inherit;
}

.topbarnavbar .dashIcon2 {
  margin-left: auto;
}

.form-control-label {
  color: #666666 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
select#exampleFormControlSelect1 {
  background: rgba(83, 215, 105, 0.1);
}
.css-1rhbuit-multiValue {
  background-color: #e9e7ff !important;
}
.accountname {
  font-size: 14px;
  font-weight: 400;
  padding: 10px 24px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: rgba(83, 215, 105, 0.1);
}
p.accountname2Client {
  background: rgba(207, 207, 207, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 13px;
  width: 225px;
}
.accountname2Client1 {
  font-size: 12px;
  font-weight: 400;
  padding: 5px 13px;
  width: 200px;
  color: #666666;
}
.accountname2Client1.bold {
  font-weight: 700;
}
.Messageaccountname2 {
  background: rgba(83, 215, 105, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 13px;
  width: 116px;
  margin-top: 15px;
}
.Messageaccountname2.disabled {
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 13px;
  width: 116px;
  margin-top: 15px;
}
.g-sidenav-show.g-sidenav-hidden .sidenav .sidenav-toggler {
  padding: 0px 0px 15px 30px !important;
  background-color: transparent;
}
.navbar-vertical {
  border-style: none !important;
  box-shadow: none !important;
}
.accountname872 {
  background: rgba(83, 215, 105, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  float: right;
  color: #000000;
}

.toloader {
  position: absolute !important;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  left: 43%;
  top: 55%;
  transform: translate(-50%, -50%);
  background: #ffff;
  padding: 26px 28px;
  gap: 10px;
  position: relative;
  width: 175px;
  height: 175px;
  border: 1px solid #0000001c;
}
.accountname2 {
  background: rgba(83, 215, 105, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  padding: 9px 13px;
  width: 180px;
  color: #000;
}
.navbar-vertical.navbar-light {
  border-color: transparent !important;
}
.dataTables_filter input {
  margin-left: 0.5em;
  display: inline-block;
  width: 100% !important;
  height: 40px;
}
.container3 {
  display: block;
  position: relative;
  padding-left: 12px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container3 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 1px;
  left: -14px;
  height: 18px;
  width: 18px;
}

.cpname {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* On mouse-over, add a grey background color */
.container3 input ~ .checkmark {
  background-color: #fff;
  border: 1.5px solid #000;
  border-radius: 3px;
}

/* When the checkbox is checked, add a blue background */
.container3 input:checked ~ .checkmark {
  background-color: #000;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container3 input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container3 .checkmark:after {
  left: 5px;
  top: 0px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* .accountnameEVERYWHERE {
  margin-left: 28px;
  background: rgba(252, 49, 88, 0.2);
  border: 1px solid rgba(252, 49, 88, 0.2);
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 13px;
  width: 200px;
} */
.organization .cardheader2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.organization .cardheader {
  display: flex;
  align-items: center;
  gap: 12px;
}
.organizationdiy .cardheader2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.organizationdiy .cardheader {
  display: flex;
}

.reports input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  width: 17px;
  height: 20px;
}
.rp {
  font-size: 14px;
  font-weight: 300;
}
.reports label.form-check-label {
  padding-left: 12px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 300;
}

.accountdelete,
.accountnameclose {
  border: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  color: #000000;
  height: 32px;
  padding: 4px 16px 4px 12px;
  border-radius: 8px;
  background: rgba(252, 49, 88, 0.2);
  line-height: 24px;
  letter-spacing: 0.4px;
  gap: 4px;
}
.accountnameclose img {
  width: 24px;
  height: 24px;
  object-fit: contain;
  padding: 4px;
}
.accountdelete {
  background: #b3162e;
  border: 1px solid #b3162e;
  border-radius: 10px;
  color: #fff;
}
.accountnameclose {
  background: rgba(252, 49, 88, 0.2);
  border: 1px solid rgba(252, 49, 88, 0.2);
  border-radius: 10px;
}
.accountname2 {
  background: rgba(83, 215, 105, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  padding: 9px 13px;
  width: 180px;
}

.ownerclass {
  line-height: 20px;
  margin: 0;
  padding: 15px 12px;
}
/* .g-sidenav-hidden  {
  height: 20% !important;
}

body.g-sidenav-show.g-sidenav-pinned .sidenav-header {
  height: 25% !important;
}
.g-sidenav-show.g-sidenav-hidden .sidenav-header {
  height: 25% !important;
}
body.g-sidenav-hidden.g-sidenav-show .sidenav-header {
  height: 25% !important;
} */
body.g-sidenav-show.g-sidenav-pinned .userpading {
  margin-left: 14px;
  /* background-color: #1345a5; */
  border-radius: 50px 0 0 50px;
}
.g-sidenav-pinned .userpading {
  margin-left: 14px;
  /* background-color: #1345a5; */
  border-radius: 50px 0 0 50px;
  position: relative;
}

.g-sidenav-hidden .userpading {
  margin-left: 14px;
  position: relative;
}

.userpading {
  display: grid;
  grid-template-columns: 48px auto;
  grid-column-gap: 10px;
}
.userpading:hover {
  cursor: pointer;
}
.userpading .avatar {
  vertical-align: middle;
  width: 48px;
  height: 48px;
  max-width: 48px;
  max-height: 48px;
  border-radius: 50%;
}
.userpading .content.mobile p {
  white-space: nowrap;
}

.g-sidenav-hidden .sidenav .menudowm.navbar-brand {
  display: block;
  padding: 18px 5px 0px 5px;
}
.g-sidenav-hidden .navbar-vertical.navbar-expand-xs .navbar-nav .nav-link {
  padding: 0.675rem 18px !important;
  position: relative;
}

.g-sidenav-hidden.g-sidenav-show .userpading {
  margin-left: 15px;
  background-color: #1345a5;
  border-radius: 50px 0 0 50px;
}

.avatar {
  color: #fff;
  background-color: #adb5bd;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  border-radius: 0.375rem;
  height: 48px;
  width: 48px;
}
.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* body.g-sidenav-pinned .sidenav .sidenav-toggler {
  padding: 8px 0px 36px 26px !important;
  background-color: transparent;
} */
.sidenav .navbar-brand {
  padding: 28px 0 0px 14px !important;
}

.sidenav .navbar-nav .nav-item .navLinkNew .menuIcon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 12px;
}

.navbar-vertical .navbar-brand-img,
.navbar-vertical .navbar-brand > img {
  max-width: none !important;
  max-height: none !important;
}
ul.nav-sm.flex-column.nav .nav-item {
  display: -webkit-box;
}
.navbar-vertical.navbar-expand-xs .navbar-nav > .nav-item > .nav-link.active {
  margin-left: 0rem !important;
}
.navbar-vertical.navbar-expand-xs .navbar-nav > .nav-item > .nav-link.active {
  background: transparent !important;
}
.nav-link {
  color: #fff !important;
}
.countnumber {
  background: #f9ae1c;
  padding: 0px 7px;
  border-radius: 12px;
  min-width: 24px;
  height: 24px;
}

.g-sidenav-hidden:not(.g-sidenav-show) .sidenav .countnumber {
  opacity: 0;
}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link {
  padding: 0.675rem 18px !important;
}
.table th {
  color: #8898aa;
  background-color: transparent !important;
  border-color: transparent !important;
}
span.onlineicon {
  position: absolute;
  height: 12px;
  width: 12px;
  background-color: #f9ae1c;
  border-radius: 50%;
  left: 48px;
  margin-top: -29px;
}
span.online_icon {
  position: absolute;
  height: 12px;
  width: 12px;
  background-color: #2dce89;
  border-radius: 50%;
  left: 50px;
}

/* .g-sidenav-hidden span.online_icon{
  left: 50px;
} */

.text-muted {
  color: #000000 !important;
}
/* .g-sidenav-hidden span.online_icon {
  left: 61px !important;
} */
/* .g-sidenav-hidden .avatar {
  width: 45px;
  height: 45px;
  margin-left: 5px;
  object-fit: cover;
} */

nav.sidenav.navbar-vertical.navbar-expand-xs.navbar-light.bg-white.fixed-left.navbar {
  overflow-x: hidden;
}
.g-sidenav-pinned .onlineicon {
  display: none !important;
}
.g-sidenav-hidden.g-sidenav-show span.online_icon {
  left: 58px !important;
}
.g-sidenav-hidden.g-sidenav-show .onlineicon {
  display: none;
}
.search-label {
  width: 90% !important;
}
.table-responsive {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.professional123 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.4px;
  color: #1c1b1f;
}
.professional {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  line-height: 18px;
  text-align: center;
  /* color: #fdfdfd; */
  color: #ff8b85;
  padding: 0px 0px 0px 12px;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 0;
}
.professionalmo {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 48px;
  text-align: right;
  letter-spacing: -0.02em;
  color: #286bef;
}
.professionaldiv1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.professionaldiv {
  display: flex;
  align-items: center;
}
.professionalmo span {
  font-size: 12px;
  color: #2f2f2fad;
}
.servicediv {
  display: flex;
  align-items: center;
  padding: 5px;
}
.manageevents {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #848484;
  padding: 5px;
}
.haveservice {
  background: rgba(254, 203, 46, 0.1);
  padding: 5px;
}
.featureList {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #000000;
  margin: 0px;
}
.da2 {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 28px;
  color: #282828;
}
.downgrade {
  /* float: right; */
  background: #e3653d;
  border-radius: 24px;
  height: 48px;
  min-width: 108px;
  padding: 8px 16px;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}
.eventsdivp {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #848484;
}
.upgrade {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  height: 48px;
  background: #019587;
  border-radius: 100px;
  width: fit-content;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4px;
  color: #fdfdfd;
  white-space: nowrap;
}
.upgrade p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4px;
  color: #fdfdfd;
}
.closeOrganization {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  text-decoration-line: underline;
  color: #b3162e;
}
.scrtime {
  max-height: 180px;
  overflow-y: auto;
  overflow-x: hidden;
}
.MemberTeam:hover,
.MemberTeam {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4px;
  color: #1d192b;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  float: right;
  width: 167px;
  margin-top: 25px;
  height: 39px;
  background: #f0f0f0;
  border-radius: 100px;
}

.inviteTeamMemberBtn {
  position: absolute;
  right: 24px;
  bottom: 24px;
}
.yourStoryzBtn {
  position: absolute;
  right: 24px;
  bottom: 24px;
}

.locator {
  border-bottom: 1px solid #e5e5e5;
}
.diy {
  border-bottom: 1px solid #e5e5e5;
}
.enterprise {
  border-bottom: 1px solid #e5e5e5;
}
.singlelist {
  display: block;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #848484;
}
.downgrade p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4px;

  /* Whites/Off White */

  color: #fdfdfd;
}
.d2class {
  display: flex;
  justify-content: center;
}
.dtatcheck {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.featureListData {
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
  padding-top: 5px;
}
.sinceservice {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #848484;
  padding: 5px;
  margin: 0px;
}
.professionalService {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #000000;
  margin: 0px 0px 0px 10px;
}

.todoslist .table {
  margin-bottom: 0 !important;
}
.todoslist .table td {
  padding: 0;
}
.todoslist .table,
.react-bootstrap-table .table {
  border: none !important;
  width: 100% !important;
}
.NotStarted {
  background-image: url("../../../img/cardimg1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.helootabs {
  width: 700px;
  text-align: right;
  padding: 10px;
  float: right;
}
.rts___nav___btn:hover {
  background-color: transparent !important;
  background-color: transparent !important;
  transition: 0.5s all;
}
.rts___tab {
  padding: 0px !important;
  white-space: nowrap !important;
  vertical-align: middle !important;
  text-align: center !important;
  margin: 0px !important;
}
.todotitle {
  font-size: 16px;
  font-weight: 300;
}
select.selecttotostatus.form-control {
  border: none;
  box-shadow: none !important;
  padding: 0px !important;
}
.tabsclassevent {
  border-radius: 50px;
  padding: 0px 10px 0px 10px;
  font-size: 14px;
  border: none;
  background: #286bef;
  color: #fff !important;
  /* margin-right: 80px; */
}
.emenu1 {
  display: flex;
}
.emenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tabsclassevent.true {
  background: #f9ae1c;
  color: #fff !important;
}
.todoslist .react-bootstrap-table {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.todoslist .react-bootstrap-table-pagination {
  margin-top: 26px;
}
.ropdowncontainer select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Safari, Chrome, Opera */
  -webkit-appearance: none;
}

/* for IE10 */
.ropdowncontainer select::-ms-expand {
  display: none;
}
.subtitletodo {
  font-size: 12px !important;
  font-weight: 300 !important;
  color: #000 !important;
}
.paymentpopus.modal-dialog {
  max-width: 650px;
  width: 100%;
}

.paymentpopus .headerInner {
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.paymentpopus .modal-header {
  align-items: center;
}
.paymentpopus .modal-content {
  border-radius: 12px;
  border: 1px solid #e7e7e7;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.paymentpopus .modal-body {
  padding: 16px 32px 36px;
}
.paymentpopus .modal-header .modal-title {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.paymentpopus .tabs {
  display: flex;
}
.paymentpopus .tab {
  justify-content: center;
  align-items: center;
  background: #f8f8f8;
  border: 1px solid #d7d7d7;
  margin-right: 10px;
  border-radius: 4px 4px 0px 0px;
}
.paymentpopus .tab:not(:last-child) {
  border-right: 1px solid #d7d7d7;
}
.paymentpopus .tab.active {
  font-weight: bold;
}
.paymentpopus .input-group-prepend {
  margin-right: 0px !important;
}
.paymentpopus .form-primary .input-group-text {
  color: #000;
  background-color: #fff;
  border: 1px solid #d7d7d7;
  border-right: none;
}
.paymentpopus .input-group-alternative {
  box-shadow: none;
}
.paymentpopus .date .form-control {
  border-left: none;
  padding-left: 0;
}
.paymentpopus .form-control:focus {
  background: #fff;
  border: 1px solid #d7d7d7;
}
.paymentpopus .date .form-control:focus {
  border-left: none;
}
.paymentpopus .changeBtn {
  background: rgba(198, 193, 203, 0.2);
}
.paymentReminderBox .changeBtn {
  height: 26px;
  background: rgba(198, 193, 203, 0.2);
}
.paymentpopus .paymentReminderBox {
  vertical-align: middle;
}
/* .paymentpopus .form-primary .form-control {
  color: #000 !important;
  background-color: transparent !important;
  border-color: transparent !important;
} */
.paymentpopus ::placeholder {
  color: #000 !important;
}
.paymentpopus .panel {
  display: none !important;
  padding: 40px 24px;
  background: #f8f8f8;
  border: 1px solid #d7d7d7;
  border-radius: 0px 0px 4px 4px;
}
.paymentpopus .panel.active {
  display: block !important;
}

.expiryDateTooltip {
  border-radius: 8px;
  background: #e7e7e7;
  padding: 16px 20px;
  width: 288px;
  text-align: left;
}
.expiryDateTooltip .expiryBox {
  border-radius: 8px;
  border: 2px solid #d7d7d7;
  background: #e7e7e7;
  padding: 16px 6px 12px 14px;
  margin-bottom: 6px;
  width: 108px;
  height: 76px;
}
.expiryDateTooltip .expiryBox .num {
  color: #49454f;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.15px;
  margin-bottom: 4px;
}
.expiryDateTooltip .expiryBox .date {
  width: 30px;
  height: 30px;
  background: rgba(255, 255, 255, 0.6);
  border: solid 1px #53d769;
  margin-left: auto;
  color: #000;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.expiryDateTooltip .expiryBox .strip {
  background: #979798;
  height: 14px;
  width: 108px;
  margin: 0 -16px 4px;
}
.expiryDateTooltip h4 {
  color: #49454f;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.15px;
  margin-bottom: 6px;
}
.expiryDateTooltip p {
  color: #49454f;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.15px;
  margin: 0;
}

.paymentpopus .iconColor {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  object-fit: contain;
}

.uncontrolledTooltip .arrow {
  display: none;
}
.uncontrolledTooltip .tooltip-inner {
  max-width: 288px;
  padding: 0;
  background: transparent;
}

.billingBoxWrapper {
  display: grid;
  grid-template-columns: 1fr 320px;
  padding-top: 24px;
}
.billingBoxWrapper .billingBoxRight {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.billingBoxWrapper .billingBoxLeft {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  padding: 0 24px;
  border-right: solid 1px #f4f4f4;
}

.todo table.align-items-center.table-flush.table thead th,
.todo table.align-items-center.table-flush.table td {
  padding: 0px 0px 10px 0px !important;
}
width .ins {
  background: rgba(254, 203, 46, 0.2);
  color: rgb(0, 0, 0) !important;
  border: none !important;
  margin-top: 12px;
  font-size: 14px !important;
  font-weight: 300 !important;
  border-radius: 8px !important;
  padding: 8px 17px 7px 17px;
}
.accountinvite .card {
  box-shadow: transparent !important;
}
.g-sidenav-hidden img.navbar-brand-img.mobileimg {
  display: block;
}

.sidenav .sidenav-toggler {
  padding: 8px 0px 36px 26px !important;
  background: transparent;
}
.g-sidenav-hidden .content.mobile {
  display: none;
}
.g-sidenav-hidden.g-sidenav-show .content.mobile {
  display: block !important;
}
.g-sidenav-hidden.g-sidenav-show.g-sidenav-hidden
  img.navbar-brand-img.mobileimg {
  display: none;
}
.g-sidenav-pinned img.navbar-brand-img.mobileimg {
  display: none;
}
a.nav-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sendsms {
  background: rgba(83, 215, 105, 0.1) !important;
  color: rgb(0, 0, 0) !important;
  border: none !important;
  font-weight: 400 !important;
  border-radius: 7px !important;
  top: 48px;
}

nav.navbar-top.navbar-expand.border-bottom.navbar-dark.navbar {
  display: none;
}

.borderBottom {
  border-bottom: 1px solid rgb(231, 231, 231);
}
.infoIcon {
  color: #666;
  width: 24px;
  height: 24px;
}
.infoIcon path {
  stroke: #666;
}

.mediaModalWrapper {
  width: 480px;
  padding: 24px 24px 32px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99991;
  max-height: calc(100vh - 16px);
  overflow-y: auto;
  display: none;
  right: 8px;
  top: 8px;
  bottom: 8px;
}
.mediaOverlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
}
.mediaModalWrapper .closeIcon {
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 1;
  cursor: pointer;
}
.mediaModalWrapper .closeIcon .closeBtn {
  width: 16px;
  fill: rgba(0, 0, 0, 0.6);
}

.mediaModalWrapper.ShowModal {
  display: block;
}
.mediaModalWrapper .mediaModalHeaderWrapper {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #000000;
  padding-bottom: 8px;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 24px;
}
.mediaModalWrapper .mediaModalHeaderWrapper .mediaLogo {
  border: 4px solid #286bef;
  width: 88px;
  height: 88px;
  border-radius: 100%;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mediaModalWrapper .mediaModalHeaderWrapper .mediaLogo .mediaLogoIcon {
  color: #286bef;
  font-size: 48px;
}
.mediaModalWrapper .mediaUserDetails {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 4px 8px;
}
.mediaModalWrapper .mediaUserDetails .mediaUserImgWrapper {
  width: 48px;
  margin-right: 8px;
}
.mediaModalWrapper .mediaUserDetails .mediaUserImgWrapper .mediaUserImg {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  object-fit: cover;
}
.mediaModalWrapper .mediaImageWrapper {
  margin-bottom: 20px;
  max-width: 360px;
  margin: 0 auto 32px;
}

.mediaModalWrapper .mediaImageWrapper .date {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  text-align: center !important;
  letter-spacing: 0.25px !important;
  color: #49454f !important;
  padding: 8px !important;
  text-align: center !important;
  margin-bottom: 0 !important;
}
.mediaModalWrapper .imageCropperWrapper {
  height: 144px;
  width: 100%;
  position: relative;
  background: #d7d7d7;
  border: solid 1px #d7d7d7;
  margin-bottom: 8px;
}

.mediaModalWrapper .imageCropperWrapper .uploadImageFromLocalContent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  width: 40px;
  height: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mediaModalWrapper .imageCropperWrapper .uploadImageFromLocalContent .imgMask {
  border: none;
}
.mediaModalWrapper .imageCropperWrapper .uploadImageFromLocalContent .faPlus {
  color: rgba(0, 0, 0, 0.6);
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.mediaModalWrapper .imageCropperWrapper .imageCropper {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0 !important;
  object-fit: cover;
}

.mediaModalWrapper .imageEditIcons {
  padding: 6px 0;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
}
.mediaModalWrapper .imageEditIcons .button {
  margin-left: 20px;
  font-size: 16px;
  border: none;
  background: no-repeat;
}

.mediaUserContentWrapper .mediaUserTitle {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #000000;
}
.mediaUserContentWrapper .mediaUserDesignation {
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.6px;
  color: #1f1f1f;
}

.mediaModalWrapper .filenameWrapper {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #49454f;
  margin-bottom: 4px;
  padding: 16px 12px 4px;
}
.mediaModalWrapper .filenameWrapper span {
  font-weight: 600;
  display: inline-block;
}
.mediaFormWrapper {
  padding: 0 8px;
}
.mediaFormWrapper .formBox {
  padding: 8px;
  /* margin-bottom: 24px; */
}
.mediaFormWrapper .formBox .form-label {
  padding: 0 0 12px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #666666;
  margin: 0;
}
.mediaFormWrapper .locationMap {
  align-items: center;
  display: grid;
  grid-template-columns: 60% 1fr;
}
.mediaFormWrapper .map {
  display: inline-flex;
  flex-direction: column;
  margin: 0 0 0 40px;
  line-height: 16px;
  letter-spacing: 0.4px;
  font-size: 12px;
  color: #000;
}
.mediaFormWrapper .diamondTrunImg {
  font-size: 28px;
  width: 28px;
}
.mediaFormWrapper .formBox .form-control {
  height: 48px;
  border: 1px solid #d7d7d7;
  border-radius: 6px;
  background: #ffffff;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #1f1f1f;
}
.mediaFormWrapper .formBox .form-control::placeholder {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #979798;
}
.mediaFormWrapper .formBox textarea.form-control {
  height: 86px;
}
.mediaFormWrapper .actionWrapper {
  display: grid;
  grid-template-columns: 1fr 100px;
  grid-gap: 12px;
  margin-top: 64px;
  padding: 0;
}
.mediaFormWrapper .actionWrapper .confirmBtn {
  /* width: 327px; */
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #ffffff;
  height: 40px;
  background: #147efb;
  border-radius: 8px;
  width: 100%;
  margin: 0;
}
.locationgooglemap12 div:first-child {
  display: none;
}

.mediaFormWrapper .actionWrapper .CancelBtn {
  width: 93px;
  height: 40px;
  border: 2px solid #147efb;
  border-radius: 8px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4px;
  color: #666666;
  justify-content: center;
}
.modal-dialog.modal-dialog-centered.floatingSearchDialog.is-visible.is-visible-expend {
  width: 400px;
  height: 200px;
  left: 22px;
  position: absolute;
}
.datastorimg {
  margin-right: 10px;
  width: 195px;
  height: 90px;
  border-radius: 10px;
  object-fit: cover;
}
.modal {
  z-index: 9999 !important;
  background: rgba(0, 0, 0, 0.2);
}
.show {
  display: block !important;
}
.mediaFileListModal {
  overflow-y: auto;
}
.mediaFileListModal .modal-dialog {
  max-width: 615px;
}
.mediaFileListModal .modal-dialog .modal-content {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}
.mediaFileListModal .btn-close {
  background: none;
  border: none;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 6px;
}
.mediaFileListModal .modal-title {
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}
.mediaFileListModal .modal-header {
  border-bottom: 1px solid #e7e7e7;
  align-items: center;
  padding: 24px 24px 12px;
}
.mediaFileListModal .modal-body {
  padding-top: 20px;
  padding-bottom: 20px;
}
.mediaFileListModal .btn-close .closeBtn {
  fill: rgba(0, 0, 0, 0.6);
}
.mediaFileListModal .importImageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  float: right;
  padding-bottom: 8px;
  position: relative;
}
.fileInput {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

input[type="file"],
input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}

.mediaFileListModal .importImageListWrapper {
  padding: 0 24px;
}
.mediaFileListModal .importImageWrapper .importIcon {
  max-width: 25px;
  margin-bottom: 8px;
  border: none;
}
.mediaFileListModal .importedImageListWrapper {
  border-top: 1px solid #e7e7e7;
  padding: 3px 0;
  clear: both;
}

.mediaFileListModal .importedImageListWrapper .importedImageListInner {
  max-height: 340px;
  min-height: 100px;
  overflow-y: auto;
}
.mediaFileListModal .selectFilebtn:hover,
.mediaFileListModal .selectFilebtn {
  width: 105px;
  /* height: 39px; */
  background: rgba(198, 193, 203, 0.2);
  border-radius: 100px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #1d192b;
  padding: 7px;
  margin: 0;
  box-shadow: none;
  transform: none;
}
.mediaFileListModal .modal-footer {
  padding-top: 0;
  padding-bottom: 46px;
}

.importedImageList {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  transition: all 0.3s ease-in-out;
}
.importedImageList:hover,
.importedImageList.selected {
  background: #efefef;
  transition: all 0.3s ease-in-out;
}
.importedImageList .uploadedItem {
  margin-right: 24px;
}
.importedImageList .uploadedItem .uploadedImg {
  width: 60px;
  height: 48px;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}
.importedImageList .uploadedItem .uploadedImg .playIcon {
  background: rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(198, 193, 203, 0.4);
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
}
.importedImageList .uploadedItem .uploadedImg .playIcon .fa-play {
  font-size: 12px;
  color: #000;
}
.importedImageList .uploadedItem .uploadedImg .thum {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.importedImageList .filename {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.importFromLocalContent {
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #000000;
  display: flex;
  /* flex-wrap: wrap; */
  line-height: 24px;
  margin-bottom: 20px;
}
.importFromLocalContent .importFromLocalContentLeft {
  margin-right: 10px;
}
.importFromLocalContent .importFromLocalContentLeft .faImage {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.6);
}

.uploadImageFromLocalWrapper {
  width: 100%;
  height: 144px;
  border-radius: 6px;
  overflow: hidden;
  background: #d7d7d7;
  border: 1px solid #d7d7d7;
  position: relative;
}
.uploadImageFromLocalWrapper .uploadImageFromLocalImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.uploadImageFromLocalWrapper .uploadImageFromLocalContent {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  width: 40px;
  height: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.uploadImageFromLocalWrapper .uploadImageFromLocalContent .faPlus {
  color: rgba(0, 0, 0, 0.6);
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.importFromLocalModal .modal-header {
  padding: 12px 24px;
}

.importImageFromLocalContainer {
  flex-direction: row !important;
  overflow: hidden;
}
.importFromLocalLeftWrapper {
  width: 234px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 12px 0px 0px 12px;
  min-height: 100%;
  overflow-y: auto;
  padding-bottom: 24px;
}
.importFromLocalLeftWrapper .importFromLocalLefList {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #1f1f1f;
  display: flex;
  align-items: center;
  padding: 6px 8px;
  background: #ffffff;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.importFromLocalLeftWrapper .importFromLocalLefList.active,
.importFromLocalLeftWrapper .importFromLocalLefList:hover {
  background: #f4f4f4;
  transition: all 0.3s ease-in-out;
}
.importFromLocalLeftWrapper .importFromLocalLefList .importFromLocalIconBox {
  margin-right: 12px;
}
.importFromLocalLeftWrapper
  .importFromLocalLefList
  .importFromLocalIconBox
  .importFromLocalIcon {
  width: 48px;
  height: 48px;
}

.sidebarmenuiner {
  max-height: 544px;
  overflow: hidden;
  background: #fff;
  width: 400px;
  margin-top: 200px;
  color: #000;
  border-radius: 30px 30px 30px 30px;
  transition: all 0.3s ease-in-out;
}
.floatingSearchDialogfooter {
  background: #ff8b85;
  border-radius: 0px 0px 30px 30px;
  padding: 1.25rem;
}

.sidebar-outer {
  display: flex;
  justify-content: center;
}
.shadow-md {
  box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
}
/* .navbar {
  border-radius: 5px;
} */
.sidebar {
  position: fixed;
  top: 0;
  left: -400px;
  z-index: 1;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}
.sidebar.sidebar-outer {
  align-items: center;
  justify-content: center;
  height: 100%;
  transition: all 0.3s ease-in-out;
}
.coverimagestsp {
  display: flex;
  justify-content: space-between;
}
.sidebar.active {
  left: 260px;
  background-color: transparent;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-in-out;
}
.sd-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;

  background: #ff8b85;
  border-radius: 30px 30px 0px 0px;
}
.sd-header .title {
  color: #fff;
}
.sd-header .fa.fa-times {
  color: #fff;
  cursor: pointer;
}

.companyBrandlogo {
  border: 1px solid rgba(198, 193, 203, 0.4);
  padding: 10px;
}
.imageventsla {
  display: flex;
  justify-content: space-between;
}

.max-width312 {
  max-width: 312px;
}

.story1ev.active_item {
  background: #00000014;
}

.imgMaskevente {
  position: relative;
  height: 200px;
  border: 1px solid #d7d7d7;
  cursor: pointer;
  background: #d7d7d7;
  width: 100%;
  overflow: hidden;
  border-radius: 15px;
}
.imgfludicon {
  background: #e7e7e7;
  border-radius: 12px;
  border: 1px solid #e7e7e7;
  width: 100%;
  height: 212.31px !important;
  object-fit: cover;
}
.bgcolori {
  background: rgb(252, 49, 88) !important;
  color: rgb(255, 255, 255) !important;
  width: 120px !important;
  border-radius: 10px !important;
}
.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.sidebar-overlay.active {
  opacity: 1;
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.4);
}
.evm123 .modal-header {
  padding: 1.25rem 1.25rem 0rem 1.25rem !important;
}
.evm123 .modal-footer {
  padding: 0rem 1.25rem 1.25rem 1.25rem;
}
.SelectStoryzEvent {
  max-height: 185px;
  min-height: 185px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 12px;
  margin-right: -12px;
  padding-bottom: 12px;
}
.imgs {
  width: 48px;
  height: 48px;
  border-radius: 50px;
}
.story1 .stimage {
  width: 48px;
  min-width: 48px;
  border-radius: 50%;
  height: 48px;
  object-fit: cover;
}
.sd-body {
  padding: 10px;
  max-height: calc(100vh - 67px);
  overflow-x: hidden;
  border: 1px solid #ff8b85;
}
.sd-body ul {
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  padding: 0;
}
.sd-body ul li {
  list-style: none;
  margin-bottom: 8px;
}
.sd-body ul li .sd-link {
  display: inline-block;
  width: 100%;
  padding: 10px 16px;
  color: #475f7b;
  background-color: #e5e8ec;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.navBar {
  background: #2053b5;
  padding: 24px 24px 24px 12px !important;
}

.headingNew {
  font-size: 18px;
}

.settingBtn {
  background: rgba(207, 207, 207, 0.6);
  border-radius: 100px;
  width: 81px;
  height: 39px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #1d192b;
  letter-spacing: 0.1px;
  border: none;
}
.pointer {
  cursor: pointer !important;
}

.showDate .dateWidth {
  width: 260px;
  width: 260px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.showDate .fullcalendar-btn-next,
.showDate .fullcalendar-btn-prev {
  font-size: 24px;
}
.accountinformation {
  position: relative;
  /* z-index: 0; */
}
.accountinformation.ptop.storyzDashboard {
  padding-top: 0px;
  padding-right: 12px;
}

.storyzDashboard .card {
  border-radius: 12px;
  padding: 40px;
  margin-bottom: 0px;
}

.g-sidenav-hidden .sidebar.sidebar-outer.active {
  left: 92px;
  visibility: visible;
  opacity: 1;
}

.navbar-vertical.navbar-expand-xs.fixed-left + .main-content {
  margin-left: 82px !important;
  /* margin-left: 97px !important; */
}
.g-sidenav-pinned .sidenav.fixed-left + .main-content {
  margin-left: 250px !important;
  /* margin-left: 265px !important; */
  transition: all 0.5s ease;
  /* width: 100%; */
}
.g-sidenav-hidden.g-sidenav-pinned .sidenav + .main-content {
  margin-left: 82px !important;
  /* margin-left: 97px !important; */
}
.g-sidenav-hidden.g-sidenav-pinned img.navbar-brand-img.mobileimg {
  display: block;
}
.g-sidenav-hidden.g-sidenav-pinned .sidenav .navbar-brand {
  display: none;
}
/* .g-sidenav-hidden.g-sidenav-pinned .sidenav .sidenav-toggler {
  padding: 8px 0px 36px 26px !important
} */
.g-sidenav-hidden.g-sidenav-pinned .userpading {
  margin-left: 14px !important;
  background: none;
  
}

.g-sidenav-hidden.g-sidenav-pinned .onlineicon {
  display: block !important;
}

/* .g-sidenav-hidden,
.g-sidenav-show,
.g-sidenav-pinned {
  overflow-x: hidden;
} */

.eventLeftMenu {
  padding-left: 0 !important;
  background: #2054b6;
  max-width: 100%;
  padding-bottom: 40px;
}

.g-sidenav-show.g-sidenav-pinned.eventLeftMenu {
  width: calc(100% - 250px);
}
.g-sidenav-hidden.eventLeftMenu {
  width: calc(100% - 82px);
}

.footer {
  clear: both;
}
/* .accountinformation.ptop, */
.upgradeservice.ptop {
  padding-top: 10px;
}

.topSpace {
  padding-top: 30px;
}

.paginationWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
}
.paginationWrapper .showingPaginationText {
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.6px;
  color: #000000;
}
.customPagination .pagination {
  margin-bottom: 0;
}
.customPagination .pagination .page-link {
  width: auto;
  height: 32px;
  padding: 4px 12px;
  border-radius: 100px !important;
}
.customPagination .pagination .next .page-link,
.customPagination .pagination .prev .page-link {
  width: fit-content;
  background: #2053b5;
  padding: 4px 12px;
  color: #fff !important;
  border: none;
}
.customPagination .pagination .disabled .page-link {
  height: 32px;
  width: fit-content;
  background: #e7e7e7;
  padding: 4px 12px;
  color: #fff !important;
}
.customPagination .pagination .active {
  background: none;
}
.customPagination .pagination .dotted .page-link {
  border: none;
  padding: 0;
}
.customPagination .pagination .dotted .page-link:hover {
  background: #fff;
}
.customPagination .pagination .active .page-link {
  background: #2053b5;
  color: #fdfdfd !important;
}
.customPagination .pagination .page-item:last-child .page-link {
  margin-right: 0;
}

.storycolumn2 .accordion__section .accordion__content .accordion__text {
  padding-top: 0;
}
.storycolumn2 .accordion__section .accordion__content .accordion__text a {
  color: #fff !important;
}

.deleteGuestBtn {
  background: #e7e7e7;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  height: 32px;
  padding: 4px 12px;
  width: fit-content;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4px;
  color: #979798;
  margin-left: auto;
  margin-top: 16px;
}
.deleteGuestBtn svg {
  margin-right: 6px;
  fill: #fff;
}
.resetGeoBtn {
  background: #b3162e;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  height: 32px;
  padding: 4px 12px;
  width: fit-content;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4px;
  color: #fdfdfd;
  margin-left: auto;
  margin-top: 16px;
}
.resetGeoBtn svg {
  margin-right: 6px;
  fill: #fff;
}

.delteEventBtn {
  background: #b3162e;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  height: 32px;
  padding: 4px 12px;
  width: fit-content;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4px;
  color: #fdfdfd;
  margin-left: auto;
  margin-top: 16px;
  margin-right: 12px;
}
.delteEventBtn img {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
.delteEventBtn svg {
  margin-right: 6px;
  fill: #fff;
}

.reviewEventBtn {
  background: #286bef;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  height: 32px;
  padding: 4px 12px;
  width: fit-content;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4px;
  color: #fdfdfd;
}
.reviewEventBtn img {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
.reviewEventBtn svg {
  margin-right: 6px;
  fill: #fff;
}

.broadcastBtn {
  background: #286bef;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  height: 32px;
  padding: 4px 12px;
  width: fit-content;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4px;
  color: #fdfdfd;
  margin-left: auto;
  margin-top: 16px;
  margin-right: 12px;
}
.broadcastBtn img {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
.broadcastBtn svg {
  margin-right: 6px;
  fill: #fff;
}

.accountDeleteModal .modal-content {
  max-width: 480px !important;
}

.accountDeleteModal p {
  color: #49454f !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 300 !important;
  line-height: 24px !important;
  letter-spacing: 0.4px;
}

.fullscreenImgWrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #000;
  top: 0;
  left: 0;
  display: none;
  z-index: 9;
}
.fullscreenImgWrapper.show {
  display: block;
}
.fullscreenImgWrapper.show .imageCropper {
  max-width: 800px;
  max-height: 90vh;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fullCloseBtn {
  width: 32px;
  height: 32px;
  background: rgba(255, 255, 255, 0.5);
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  padding: 4px;
  position: absolute;
  right: 20px;
  top: 20px;
  border: none;
}
.dropdownBoxMenuWrapper.guestListWidth430 {
  width: 430px;
  right: 0;
}

.fullCloseBtn .closeBtn {
  width: 24px;
  height: 24px;
}

.errorMessage {
  color: red;
  display: block;
  margin: 0px 0 16px;
  font-size: 12px;
}

.timezone {
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.6px;
  color: #666666;
}
.smallText {
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.6px;
  color: #666666;
}

.ChangeTimezone {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #000000;
  display: flex;
  flex-direction: column;
}

.ChangeTimezone .imagevent {
  margin: 0 auto 12px;
  width: 82px;
  height: 82px;
}
.changeTimezoneForm {
  padding: 0 24px;
}
.changeTimezoneForm .form-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #666666;
}
.repeatingEventText {
  color: #000;
  font-size: 14px;
  line-height: 20px;
}
.repeatingEvent {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  padding: 16px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000;
}

.repeatingEvent .form-check-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #666666;
}

.repeatEventModalWrapper .modal-body {
  padding-bottom: 0;
}
.repeatEventModalWrapper .modal-footer {
  padding-top: 4px;
  padding-bottom: 24px;
}

.repeatEventWrapper {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #000000;
  padding-bottom: 8px;
  margin-bottom: 16px;
  flex-direction: row;
}
.repeatEventWrapper .imagevent {
  width: 88px;
  margin: 0 12px 0 0;
}

.repeateventDetails {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  margin-bottom: 16px;
}
.repeateventDetails .repeateeventDate {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #1f1f1f;
  white-space: nowrap;
}
.repeateventDetails .date {
  display: none;
}
.repeateventDetails:hover .date {
  display: block;
}

.repeateventDetails .repeateventTitle {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #1f1f1f;
}
.repeateventDetails .repeateventlocation {
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.4px;
  color: #1f1f1f;
}
.repeateventDetails .evimages {
  margin: 0 8px;
}

.evenetListStory img {
  width: 79px;
  height: 53px;
  border-radius: 12px;
}

.storyabout.ptop {
  padding-top: 145px;
}

.topmargin {
  padding-top: 168px;
}

.insideMenuWrapper button {
  background: #fff;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 14px;
}
.link.action {
  background: rgba(231, 231, 231, 0.1) !important;
  color: #fecb2e !important;
}
.insideMenuWrapper .accordion-title {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #49454f;
  margin: 0;
  flex-grow: 1;
}
.insideMenuWrapper .accordion-title .circle {
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-right: 8px;
}
.insideMenuWrapper .accordion-title .circle svg {
  fill: #c4c4c4;
}
.insideMenuWrapper .contentWrapper {
  background: #49454f;
}
.insideMenuWrapper .contentWrapper .accordion-body {
  padding: 5px 0;
}

.insideMenuWrapper .contentWrapper .accordion-body .subListMenuWrapper {
  list-style: none;
  margin: 0;
  padding: 0;
}

.insideMenuWrapper .contentWrapper .accordion-body .listItem {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #fdfdfd;
  padding: 4px 4px 4px 20px;
}
.insideMenuWrapper .contentWrapper .accordion-body .listItem .link {
  display: inline-flex;
  align-items: center;
  color: #fdfdfd;
  padding: 4px 8px 4px 4px;
  border-radius: 8px;
  justify-content: flex-start;
  background: none;
  width: fit-content;
}

/* .insideMenuWrapper .contentWrapper .accordion-body .listItem .link:hover, */
.insideMenuWrapper .contentWrapper .accordion-body .listItem .link.active {
  background: rgba(231, 231, 231, 0.1);
  color: #fecb2e;
}
.insideMenuWrapper .contentWrapper .accordion-body .innerIcon {
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: rgba(255, 255, 255, 0.6);
}
.insideMenuWrapper .contentWrapper .accordion-body .link .innerIcon svg {
  font-size: 18px !important;
}

.insideMenuWrapper .accordionMneuHead[aria-expanded="true"] {
  background: #49454f;
  /* transition: all 0.3s ease-in-out; */
}
.insideMenuWrapper .accordionMneuHead[aria-expanded="true"] .accordion-title {
  color: #fdfdfd;
}
.insideMenuWrapper .accordionMneuHead[aria-expanded="false"] .arrowIcon {
  fill: #49454f;
}
.insideMenuWrapper .accordionMneuHead[aria-expanded="true"] .arrowIcon {
  fill: #fdfdfd;
  transform: rotate(90deg);
}

.insideMenuWrapper .accordionSubMneuHead {
  background: none;
  display: inline-flex;
  padding: 0;
}
.insideMenuWrapper .accordionSubMneuHead .subMenuInner {
  color: #fff;
  padding: 4px 12px 4px 4px;
  font-size: 14px;
  border-radius: 8px;
  display: flex;
  text-align: left;
}

.insideMenuWrapper .accordionSubMneuHead .subMenuInner.active,
.insideMenuWrapper .accordionSubMneuHead .subMenuInner:hover {
  background: rgba(231, 231, 231, 0.1);
  color: #fecb2e !important;
}
.subListMenuWrapper .listItem .innerIcon1 {
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  color: rgba(255, 255, 255, 0.6);
}
.subListMenuWrapper .listItem .innerIcon1 svg {
  font-size: 8px !important;
}

.ultraSubMenu .accordion-body {
  padding-bottom: 0 !important;
}
.ultraSubMenu .accordion-body .listItem {
  padding: 4px 0 4px 16px !important;
}

.ultraSubMenu .accordion-body .listItem .link.active,
.ultraSubMenu .accordion-body .listItem .link:hover {
  color: #ff8b85 !important;
}

.aboutGeospaceModal {
  max-width: 612px !important;
  width: 100% !important;
}
.aboutGeospaceModal .modal-body {
  padding: 28px 28px 0;
}
.aboutGeoModalHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;
  margin-bottom: 24px;
}
.aboutGeoModalHeader .geospaceInfoIcon {
  width: 82px;
  height: 82px;
  margin-bottom: 12px;
}
.aboutGeoModalHeader .head {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #000000;
  padding-bottom: 8px;
}
.aboutGeospaceModal .aboutGeoContent .row {
  padding-bottom: 8px;
  margin-bottom: 24px;
}
.aboutGeospaceModal .aboutGeoContent .row:last-child {
  margin-bottom: 0;
}
.aboutGeospaceModal .aboutGeoContent p {
  color: #000;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.aboutGeospaceModal .modal-footer {
  padding: 16px 24px 46px;
}

.geospaceFullscreenWrapper {
  max-width: 100% !important;
  width: 100% !important;
  height: 100vh;
  margin: 0;
}

.geospaceFullscreenWrapper .mapGeoModal {
  height: calc(90vh - 43px);
}

.geospaceFullscreenWrapper .modal-content {
  border-radius: 0 !important;
}
.geospaceFullscreenWrapper .iframe {
  height: calc(100vh - 57px) !important;
  display: block;
  border: none;
}

.resetGeospaceModal {
  max-width: 480px !important;
  width: 100% !important;
}
.resetGeoModalHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.resetGeoModalHeader .geospaceInfoIcon {
  width: 82px;
  height: 82px;
  margin-bottom: 12px;
}
.resetGeoModalHeader .head {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #000000;
}

.resetGeospaceModal .modal-content {
  border-radius: 12px;
}

.statusofGuestaListModal {
  max-width: 380px !important;
}

.resetRSVPModal {
  max-width: 360px !important;
  width: 100%;
}
.resetRSVPModal .head {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #000000;
}
.resetRSVPModal p {
  color: #000;
}

.statusofGuestaListModal p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.changeEmailModal {
  max-width: 512px !important;
  width: 100% !important;
}
.changeEmailModal p {
  color: #000;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.changeEmailHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
}
.changeEmailHeader .changeEmailInfoIcon {
  width: 82px;
  height: 82px;
  margin-bottom: 15px;
}
.changeEmailHeader .head {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #000000;
  padding-bottom: 8px;
}
.changeEmailModal .modal-body {
  padding: 24px 24px 8px;
}
.changeEmailModal .modal-footer {
  padding: 4px 20px 28px;
}

.editEmailModal {
  max-width: 480px !important;
  width: 100% !important;
}
.editEmailModal p {
  color: #000;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.editEmailHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
}
.editEmailHeader .editEmailInfoIcon {
  width: 82px;
  height: 82px;
  margin-bottom: 15px;
}
.editEmailHeader .head {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #000000;
  padding-bottom: 8px;
}
.editEmailModal .modal-body {
  padding: 24px 24px 8px;
}
.editEmailModal .modal-footer {
  padding: 4px 20px 28px;
}

.deleteEverythingModal {
  max-width: 500px !important;
  width: 100% !important;
}
.deleteEverythingModal p {
  color: #000;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.deleteEverythingHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
}
.deleteEverythingHeader .deleteEverythingInfoIcon {
  width: 82px;
  height: 82px;
  margin-bottom: 15px;
}
.deleteEverythingHeader .head {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #000000;
  padding-bottom: 8px;
}

.deleteEverythingModal .modal-body {
  padding: 24px 24px 8px;
}
.deleteEverythingModal .modal-footer {
  padding: 4px 20px 28px;
}
.deleteEverythingThumWrapper {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  padding: 4px 8px;
}
.deleteEverythingThumWrapper .deleteEverythingThum {
  margin-right: 8px;
  width: 48px;
  height: 48px;
}
.deleteEverythingThumWrapper .deleteEverythingThum .deleteEverythingThumImg {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}
.deleteEverythingThumWrapper
  .deleteEverythingThumContent
  .deleteEverythingthumbTitle {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #1f1f1f;
}
.deleteEverythingThumWrapper
  .deleteEverythingThumContent
  .deleteEverythingthumbSubTitle {
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #1f1f1f;
}

.mergeAllModal {
  max-width: 500px !important;
  width: 100% !important;
}
.mergeAllHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 12px;
}
.mergeAllHeader .mergeAllInfoIcon {
  width: 82px;
  height: 82px;
  margin-bottom: 15px;
}
.mergeAllHeader .head {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #000000;
}

.mergeAllThumWrapper {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
}

.mergeAllThumWrapper .mergeAllThum {
  margin-right: 8px;
  width: 48px;
  height: 48px;
}
.mergeAllThumWrapper .mergeAllThum .mergeAllThumImg {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}
.mergeAllThumWrapper .mergeAllThumContent .mergeAllthumbTitle {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #1f1f1f;
}
.mergeAllThumWrapper .mergeAllThumContent .mergeAllthumbSubTitle {
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #1f1f1f;
}

.nowrap {
  white-space: nowrap;
}

.bgPannel {
  background: #2054b6 url(./../../../img/panels.png) no-repeat !important;
  background-size: cover !important;
}

.socialMediaLinksModal {
  max-width: 565px !important;
  width: 100% !important;
}

.modalHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 8px;
  margin-bottom: 16px;
}

.modalHeader .icon {
  width: 88px;
  height: 88px;
  margin-bottom: 15px;
}
.modalHeader .head {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #000000;
}
.socialMediaLinksModal .modal-footer {
  padding-bottom: 50px;
}

.prefixAddModal {
  max-width: 400px !important;
  width: 100% !important;
}
.prefixAddModal .icon {
  width: 82px;
  height: 82px;
  margin-bottom: 15px;
}

.exportGuestModal {
  max-width: 480px !important;
  width: 100% !important;
  position: absolute !important;
  right: 0;
  top: 0;
  margin: 0 !important;
}
.exportGuestModal p {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.exportGuestHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 12px;
  margin-bottom: 24px;
}
.exportGuestHeader .exportGuestInfoIcon {
  width: 82px;
  height: 82px;
  margin-right: 12px;
}
.exportGuestHeader .head {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #000000;
}

.exportGuestThumWrapper {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
}

.exportGuestThumWrapper .exportGuestThum {
  margin-right: 8px;
  width: 48px;
  height: 48px;
}
.exportGuestThumWrapper .exportGuestThum .exportGuestThumImg {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}
.exportGuestThumWrapper .date {
  font-size: 12px;
  color: #000;
}
.exportGuestThumWrapper .exportGuestThumContent .exportGuestthumbTitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #1f1f1f;
}
.exportGuestThumWrapper .exportGuestThumContent .exportGuestthumbSubTitle {
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 12px;
  letter-spacing: 0.4px;
  color: #1f1f1f;
}

.exportFileListWrapper .exportFileList {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.exportFileListWrapper .exportFileList .exportFileDate {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.6px;
  margin-left: 16px;
}
.exportFileListWrapper .exportFileList .exportFileWrapper {
  padding: 12px 24px;
  width: 84px;
  height: 90px;
  background: rgba(198, 193, 203, 0.2);
  border-radius: 24px;
  margin-right: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.exportFileListWrapper .exportFileList .exportFileWrapper .exportFile {
  max-width: 36px;
  max-height: 38px;
  margin-bottom: 12px;
}
.exportFileListWrapper .exportFileList .exportFileWrapper .exportFileText {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #000000;
}
.exportFileListWrapper .exportFileBtn {
  padding: 4px 16px 4px 12px;
  width: fit-content;
  height: 32px;
  background: rgba(83, 215, 105, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4px;
  color: #000000;
  cursor: pointer;
  white-space: nowrap;
}

.exportFileListWrapper .exportFileBtn .exportDownloadIcon {
  color: #666;
  margin-right: 6px;
  width: 18px;
  height: 18px;
}

.geospaceThumWrapper {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
}
.geospaceThumWrapper .geospaceThum {
  margin-right: 8px;
  width: 48px;
  min-width: 48px;
  height: 48px;
}
.geospaceThumWrapper .geospaceThum .geospaceThumImg {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}
.geospaceThumWrapper .geospaceThumContent .geothumbTitle {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #1f1f1f;
}
.geospaceThumWrapper .geospaceThumContent .geothumbSubTitle {
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.4px;
  color: #1f1f1f;
}

.eventThumWrapper {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
}
.eventThumWrapper .eventThum {
  margin-right: 8px;
  width: 79px;
  height: 53px;
}
.eventThumWrapper .eventThum .eventThumImg {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
}
.eventThumWrapper .eventThumContent .eventthumbTitle {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #1f1f1f;
}
.eventThumWrapper .eventThumContent .eventthumbSubTitle {
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.4px;
  color: #1f1f1f;
}

.pinkBtn {
  background: rgb(252, 49, 88) !important;
  color: rgb(255, 255, 255) !important;
  border-radius: 8px !important;
  border: none !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  box-shadow: none !important;
  transform: none !important;
}
.orangeBtn {
  color: rgb(255, 255, 255) !important;
  border-radius: 8px !important;
  border: 1px solid #e2a121;
  background: #f9ae1c;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  box-shadow: none !important;
  transform: none !important;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancelBtn {
  background: #fff !important;
  color: #666666 !important;
  border: 2px solid #147efb !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  transform: none !important;
  font-weight: 500;
  padding: 0 20px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.whiteBg {
  background: #fff !important;
}
/* .g-sidenav-show.g-sidenav-pinned{}
.g-sidenav-pinned .combinedGuestHeaderWrapper{
  right: 0;
  left: 250px;
  width: inherit;
  padding-right: 18px !important;
}
.g-sidenav-hidden .combinedGuestHeaderWrapper{
  left: 82px;
  right: 0;
  width: inherit;
  padding-right: 18px !important;
} */
.timeLine-main-header-viewPort,
.timeLine-side-title {
  flex: 0 0 120px !important;
  background: #fcf9f9 !important;
  color: #000000 !important;
}
.timeLine-side-title {
  font-size: 14px;
}
.header-bottom,
.header-middle,
.header-top {
  height: 40px !important;
  font-size: 14px !important;
  background: #fcf9f9 !important;
  color: #000000 !important;
  border-bottom: solid 0.5px silver;
}

.header-top > div {
  height: 40px !important;
  border-left: 1px solid silver !important;
}
.header-bottom > div {
  height: 40px !important;
  border-left: 1px solid silver !important;
}
.header-middle > div {
  height: 40px !important;
  border-left: 1px solid silver !important;
}

.timeLine-side-main {
  min-width: 300px !important;
}
.timeLine-side-task-row,
.timeLine-main-data-row {
  height: 80px !important;
}
.timeLine-side-task-row > div {
  height: 100% !important;
  top: 20px !important;
}
.timeLine-side-task-row {
  padding: 8px 12px;
}
.timeLine-main-data-row > div {
  height: 40px !important;
  border-radius: 20px !important;
  top: 20px !important;
}
.eventCard {
  display: flex;
  align-items: center;
  /* width: 80%; */
  width: max-content;
}
.eventCard img {
  width: 79px;
  height: 53px;
  border-radius: 12px;
  margin-right: 8px;
}
.eventCard .eventCardText {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #1f1f1f;
  text-align: left;
}
.eventCard .eventCardText .subtitle {
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.4px;
  color: #1f1f1f;
}
.statusGuestUserWrapper {
  background: #f4f4f4;
  border-radius: 12px;
  padding: 16px;
  gap: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.pending {
  padding: 10px 8px;
  height: 40px;
  background: rgba(254, 203, 46, 0.35);
  border-radius: 8px;
}
.going {
  padding: 10px 8px;
  height: 40px;
  background: rgba(83, 215, 105, 0.1);
  border-radius: 8px;
}
.notGoing {
  padding: 10px 8px;
  height: 40px;
  background: rgba(252, 49, 88, 0.1);
  border-radius: 8px;
}
.notgoing {
  padding: 10px 8px;
  height: 40px;
  background: rgba(252, 49, 88, 0.1);
  border-radius: 8px;
}
.attended {
  padding: 10px 8px;
  height: 40px;
  background: rgba(40, 107, 239, 0.1);
  border-radius: 8px;
}

input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--form-background);
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid rgba(0, 0, 0, 0.87);
  border-radius: 50%;
  transform: translateY(-0.075em);
  cursor: pointer;
  display: grid;
  place-content: center;
  background: #fff;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: rgba(0, 0, 0, 0.87);
}

input[type="radio"]:checked::before {
  transform: scale(1);
}
.form-check .form-check-label {
  margin-left: 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #666666;
  transform: translateY(-2px);
}
.AddGestUser {
  border: none;
  background: none;
  font-weight: 500;
  font-size: 12px;
  text-align: right;
  letter-spacing: 0.6px;
  color: #666666;
  line-height: 1;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  padding: 0 12px;
  cursor: pointer;
}
.AddGestUser .icon {
  color: #f9ae1c;
  width: 16px;
  height: 16px;
  margin-left: 8px;
}

.socialMediaLinkList {
  margin-bottom: 20px;
}
.socialMediaLinkList .form-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #666666;
}

.socialMediaLinkList .socialMediaLinkInput .socialIcon {
  width: 48px;
  min-width: 48px;
  height: 48px;
  object-fit: contain;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0.2);
}
.socialMediaLinkList .socialMediaLinkInput .form-control {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #000000;
  height: 48px;
  border-radius: 6px;
}
.socialMediaLinkList .socialMediaLinkInput .form-control:disabled {
  background: #f8f8f8;
  border-radius: 6px;
  border: none;
  width: 100%;
  height: 48px;
}

.socialMediaLinkList .socialMediaLinkInput .empty-form-control {
  background: #f8f8f8;
  border-radius: 6px;
  border: none;
  width: 100%;
  height: 48px;
}
.socialMediaLinkList .socialMediaLinkInput .CloseIcon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  cursor: pointer;
}
.chooseSocailHead {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #000000;
}

.mx-w {
  max-width: 50%;
}

.circleAnimation {
  border: 4px solid #e8fbff;
  border-radius: 50%;
  border-top: 4px solid #006ef0;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 1s linear infinite;
}

.circleAnimation1 {
  border: 4px solid #e8fbff;
  border-radius: 50%;
  border-top: 4px solid #006ef0;
  width: 180px;
  height: 180px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 1s linear infinite;
}

.formBoxInput {
  border-right: 1px solid #000000;
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.6px;
  color: #666666;
  cursor: pointer;
  pointer-events: none;
}

.eventThumDropdown {
  display: flex;
  align-items: center;
}
.eventThumDropdown .date {
  padding-right: 12px;
  min-width: 110px;
}
.eventThumDropdown .eventThum {
  margin-right: 8px;
  width: 79px;
  min-width: 79px;
  height: 53px;
}
.eventThumDropdown .eventThum .eventThumImg {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
}

.eventThumDropdown .eventThumContent .eventthumbTitle {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #1f1f1f;

  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.eventThumDropdown .eventThumContent .eventthumbSubTitle {
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.4px;
  color: #1f1f1f;
}

.keepsakeAlbumModal.modal-dialog {
  max-width: 360px;
  right: 0;
  max-height: calc(100vh - 16px);
  margin: 8px 8px 8px auto;
}
.keepsakeAlbumModal .modal-content {
  height: 100%;
}
.keepsakeAlbumModal .modal-header {
  padding: 12px;
  height: 48px;
  background: #286bef;
}
.keepsakeAlbumModal .modal-header .close {
  margin: 0;
  position: absolute;
  right: 12px;
  top: 12px;
  padding: 0;
  opacity: 1;
}
.keepsakeAlbumModal .modal-header .close span {
  color: #fff;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}
.keepsakeAlbumModal .modal-body {
  padding: 0;
  background: #ededed;
}

.keepsakeAlbumModal .coverImgWrapper {
  width: 100%;
  height: 300px;
  position: relative;
}
.keepsakeAlbumModal .title {
  padding: 10px 16px 8px;
  display: inline-block;
  background: #ffffff;
  border-radius: 40px;
  color: #333333;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: -0.04em;
  position: absolute;
  top: 0;
  left: 50%;
  right: auto;
  margin: 48px auto 0;
  transform: translateX(-50%);
  white-space: nowrap;
  width: fit-content;
}
.keepsakeAlbumModal .coverImgWrapper .coverImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.keepsakeAlbumModal .keepsakeAlbumContent {
  padding: 28px 24px 22px;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.04em;
  color: #000000;
}
.keepsakeAlbumAction {
  width: 288px;
  margin: 0 auto 24px;
}
.keepsakeAlbumAction .actionWrapper {
  background: #fff;
  border-radius: 40px;
  display: flex;
}
.keepsakeAlbumAction .actionWrapper .keepsakeAlbumBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  width: 144px;
  height: 44px;
  border-radius: 40px;
  cursor: pointer;
  border: none;
  background: none;
}
.keepsakeAlbumAction .actionWrapper .keepsakeAlbumBtn.active {
  background: #333333;
}

.keepsakeAlbumMediaWrapper .date {
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: -0.04em;
  color: #000000;
}
.keepsakeAlbumMediaWrapper .date img {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  margin-right: 8px;
  object-fit: cover;
}

.keepsakeAlbumMediaWrapper .icon {
  color: #000000;
  width: 24px;
  height: 24px;
}

.keepsakeAlbumSliderWrapper {
  margin-bottom: 20px;
  padding: 0 12px 0 16px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
.keepsakeAlbumSliderWrapper .sliderList {
  margin: 0 4px 0 0;
  min-width: 107px;
  max-width: 107px;
  height: 148px;
  position: relative;
  border-radius: 12px;
  scroll-snap-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
}
.keepsakeAlbumSliderWrapper .sliderList .slideListOverlay {
  display: none;
}
.keepsakeAlbumSliderWrapper .sliderList:last-child .slideListOverlay {
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.75);
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: -0.04em;
  color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  cursor: pointer;
  display: flex;
}
.keepsakeAlbumSliderWrapper .sliderList .sliderImg {
  border-radius: 12px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.coverImgDetails {
  height: 70px;
  position: relative;
  width: 100%;
}
.coverImgDetails .title {
  background: none;
  padding: 0;
  margin-top: 40px;
}
.coverImgDetails .backArrow {
  width: 24px;
  height: 24px;
  color: #000;
  position: relative;
  margin: 40px 0 0 20px;
  cursor: pointer;
  border: none;
  font-size: 24px;
}

.keepsakeUser1 {
  /* margin-bottom: 32px; */
  display: flex;
  align-items: center;
}
.keepsakeUser1 .keepsakeUser1Thum {
  margin-right: 8px;
  width: 36px;
  height: 36px;
}
.keepsakeUser1 .keepsakeUser1Thum .keepsakeUser1ThumImg {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}
.keepsakeUser1 .keepsakeUser1ThumContent .keepsakeUser1thumbTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #000000;
}
.keepsakeUser1 .keepsakeUser1ThumContent .keepsakeUser1thumbSubTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #828282;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.keepsakeAlbumModalUpload.modal-dialog {
  max-width: 360px;
  right: 0;
  height: 100%;
  max-height: calc(100vh - 16px);
  margin: 8px 8px 8px auto;
}
.keepsakeAlbumModalUpload .modal-content {
  height: 100%;
}
.keepsakeAlbumModalUpload .modal-header {
  padding: 12px;
  height: 48px;
  background: #286bef;
}
.keepsakeAlbumModalUpload .modal-header .close {
  margin: 0;
  position: absolute;
  right: 12px;
  top: 12px;
  padding: 0;
  opacity: 1;
}
.keepsakeAlbumModalUpload .modal-header .close span {
  color: #fff;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}
.keepsakeAlbumModalUpload .modal-body {
  padding: 0 16px;
  background: #ededed;
}
.keepsakeAlbumModalUpload .header {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.8px;
  position: relative;
  padding-left: 32px;
  padding-bottom: 8px;
}
.keepsakeAlbumModalUpload .header .arrowIcon {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0;
  top: 2px;
}
.keepsakeAlbumModalUpload .headerTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
  margin-bottom: 24px;
}
.keepsakeAlbumModalUpload .headerTop .date {
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  line-height: 16px;
}
.keepsakeAlbumModalUpload .headerTop .signalWrapper {
  display: flex;
  align-items: center;
  gap: 2px;
}
.keepsakeAlbumModalUpload .headerTop .signalWrapper .icon {
  width: 14px;
  height: 14px;
  fill: #000;
}
.keepsakeAlbumModalUpload .galleryGridWrapperOuter {
  height: calc(100vh - 154px);
  overflow-y: auto;
  padding-right: 12px;
  margin-right: -12px;
}
.keepsakeAlbumModalUpload .galleryGridWrapper {
  column-gap: 8px;
  columns: 2;
  padding: 16px 0;
}
.keepsakeAlbumModalUpload .galleryGridWrapper video,
.keepsakeAlbumModalUpload .galleryGridWrapper img {
  border-radius: 12px;
  width: 100%;
  margin-bottom: 8px;
}

.todoIconWrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #49454f;
  padding: 4px 8px;
  gap: 4px;
  cursor: pointer;
}
.todoIconWrapper .toIcon {
  height: 28px;
  width: 28px;
}

.pagination.react-bootstrap-table-page-btns-ul .disabled,
.pagination.react-bootstrap-table-page-btns-ul .active {
  background: none !important;
}

.accountinformation.ptop.pad-0 {
  padding: 0 !important;
}

.inviteGuestList {
  padding: 4px 8px 4px 4px;
  margin: 5px;
  background: #f4f4f4;
  border-radius: 4px;
  color: #000;
  font-size: 12px;
}
.inviteGuestList .avatar {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 4px;
}

.EventdropdownOuter .dropdownBoxWrapper .dropdownTriggered {
  height: inherit !important;
  min-height: 48px;
}

.EventdropdownOuter
  .dropdownBoxWrapper
  .dropdownTriggered
  .dropdownPlaceholder
  .form-control {
  padding: 4px 8px !important;
  min-height: 48px !important;
  align-items: center;
}

.EventdropdownOuter
  .dropdownBoxWrapper
  .dropdownTriggered
  .dropdownPlaceholder
  .form-control
  .inviteGuestList {
  margin: 2px !important;
}

.EventdropdownOuter .more {
  font-size: 12px;
  color: #666;
}

.radius12 {
  border-radius: 12px !important;
}

.zIndex99 {
  z-index: 99;
}
.zIndex1 {
  z-index: 1;
}

.dataFundaTable .react-bootstrap-table .table td {
  border-top: none !important;
  padding: 6px 24px;
  vertical-align: middle;
  color: #49454f;
  font-weight: 300;
}
.react-bootstrap-table-pagination {
  padding: 12px 0;
}
.react-bootstrap-table-pagination .pagination {
  margin: 0;
}
.react-bootstrap-table-pagination .pagination .page-item.disabled .page-link {
  background: #e7e7e7;
  color: #fff;
  border: none;
}

.react-bootstrap-table-pagination .pagination .page-item .page-link {
  height: 32px;
  width: fit-content;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  box-shadow: none;
  border: solid 1px #d7d7d7;
  color: #49454f;
  padding: 4px 12px;
  border-radius: 100px !important;
}
/* .react-bootstrap-table-pagination .pagination .page-item:last-child .page-link, */
.react-bootstrap-table-pagination .pagination .page-item.active .page-link {
  background: #2053b5;
  color: #fff;
  border: none;
}

.todoTableWrapper {
  padding: 24px;
}
.todoTableWrapper .react-bootstrap-table {
  overflow: auto;
  height: 600px;
  margin: 24px 0;
}
.todoTableWrapper .react-bootstrap-table .table .list {
  box-shadow: none;
  border-radius: 0;
}
.todoTableWrapper .react-bootstrap-table .table td {
  border-top: none;
  padding: 0;
}

.todoTableWrapperInner {
  padding: 0 0 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filterBy {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.filterBy label {
  margin-right: 8px;
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.filterBy .form-group {
  width: 112px;
}

.todoTableWrapperInner .search-label {
  margin-bottom: 0;
}

.orgTodoTableWrapper {
  padding: 24px 0;
}

.storyTodoTableWrapper {
  padding: 24px 0;
}

.orgTodoWrapper {
  display: flex;
  align-items: center;
}
.orgTodoWrapper .orgTodoList {
  display: flex;
  align-items: center;
}
.orgTodoWrapper .orgTodoList .filterBy {
  display: flex;
  align-items: center;
}
.orgTodoWrapper .orgTodoList .filterBy .form-group {
  width: 112px;
}

.storyzTodoWrapper .storyzTodoList {
  display: flex;
  align-items: center;
}
.storyzTodoWrapper .storyzTodoList .filterBy {
  display: flex;
  align-items: center;
}
.storyzTodoWrapper .storyzTodoList .filterBy .form-group {
  width: 112px;
}

.eventTodoWrapper .eventTodoList {
  display: flex;
  align-items: center;
}

.tableData td {
  border: none !important;
  padding: 0 8px !important;
}

.tableData table th {
  text-transform: capitalize;
}
.tableData table td {
  border-top: none;
  padding: 8px !important;
}
.tableData .sideBrdr {
  border-right: 1px solid #e9ecef !important;
}

.eventCardDetailsWrapper .layer .coverImgNew {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 12px;
}
.locImgWrap {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.locImgWrap .locImg {
  max-width: 160px !important;
  height: 80px;
  width: 100%;
  overflow: hidden;
  border-radius: 12px;
  object-fit: cover;
}

/* .navbar-vertical.navbar-expand-xs .navbar-inner{
  padding-bottom: 52px;
} */

.footerWrapperNew {
  /* position: relative;
  z-index: 1; */
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  /* overflow-y: hidden; */
}

/* .scrollbar-inner{
    height: calc(100% - 130px) !important;
    margin-bottom: 16px;
    overflow: auto;
 } */

.eventBoxWrapper .card {
  padding-right: 12px;
  background: none;
}

.eventBoxWrapper .content {
  background: #fff;
  border-radius: 12px;
}

.main-content {
  min-height: calc(100vh - 84px);
  z-index: 2;
  transition: all 0.5s ease;
  padding-bottom: 51px;
  /* overflow-y: hidden; */
}

.changeBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  width: fit-content;
  height: 39px;
  background: rgba(207, 207, 207, 0.6);
  border-radius: 100px;
  border: none;

  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #1d192b;
}

.changeTimezoneModal {
  max-width: 480px;
}

.changeTimezoneModalHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 8px;
}
.changeTimezoneModalHeader .changeTimezoneInfoIcon {
  width: 82px;
  height: 82px;
  margin-bottom: 12px;
}
.changeTimezoneModalHeader .head {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #000000;
}
.changeTimezoneModal .form-label {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #666666;
}
.changeTimezoneModal .form-select {
  width: 100%;
  height: 40px;
  background: rgba(83, 215, 105, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 4px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #666666;
}

.changeTimezoneModal .modal-footer {
  padding: 0;
}

.socialLoginModal {
  max-width: 480px;
}

.socialLoginModal .socialLoginModalHeader {
  display: flex;
  align-items: center;
  padding-bottom: 12px;
  margin-bottom: 24px;
}
.socialLoginModalHeader .socialLoginModalInfoIcon {
  width: 82px;
  height: 82px;
  margin-right: 12px;
}
.socialLoginModalHeader .head {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #000000;
}
.socialLoginModal p {
  color: #1f1f1f;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.socialLoginModal h3 {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #1f1f1f;
  margin: 24px 0;
}

.socialList {
  padding: 6px 40px 6px 6px;
  border-radius: 4px;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #1f1f1f;
  position: relative;
  margin-bottom: 16px;
}
.socialList .socialIcon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-right: 12px;
}

.socialList .closeIcon {
  position: absolute;
  right: 6px;
  top: 0;
  bottom: 0;
  margin: auto;
  color: rgba(0, 0, 0, 0.6);
  display: none;
}
.socialList:hover,
.socialList.active {
  background: #f8f8f8;
  color: #1f1f1f;
}
.socialList:hover .closeIcon,
.socialList.active .closeIcon {
  display: block;
}
.blueBtn:hover,
.blueBtn:focus,
.blueBtn {
  display: flex;
  height: 40px;
  padding: 10px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: #147efb;
  box-shadow: none;
  color: #fff;
  font-weight: 500;
  border-color: #147efb;
  transform: none;
}
.blueBtn.disabled,
.blueBtn:disabled {
  background: rgba(31, 31, 31, 0.12);
  color: #b7b7b7;
  pointer-events: none;
}

.loaderModal {
  width: 266px !important;
  height: 262px;
}
.loaderModal .modal-content {
  height: 100%;
}
.loaderAnim {
  border: 4px solid #e8fbff;
  border-radius: 50%;
  border-top: 4px solid #006ef0;
  width: 180px;
  height: 180px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 1s linear infinite;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}
.overflowHidden {
  overflow: hidden;
}
.fontSize14 {
  font-size: 14px !important;
}
.fontSize16 {
  font-size: 16px;
}
.fontSize12 {
  font-size: 12px;
}
.blackColor {
  color: #000;
}
.blackColor1 {
  color: #1d192b;
}
.grayColor {
  color: #666;
}
.grayColor1 {
  color: #49454f;
}
.grayColor2 {
  color: #848484;
}
.greenColor {
  color: #019587;
}

.outlineNone {
  outline: none;
}
.whiteColor {
  color: #fff;
}

.bold {
  font-weight: bold;
}
.redClr {
  color: #fc3158;
}
.heightAuto {
  height: auto !important;
}
.height40 {
  height: 40px;
}
.height44 {
  height: 44px;
}
.height46:hover,
.height46 {
  height: 46px;
}
.height48 {
  height: 48px;
}
.whiteSpace {
  white-space: nowrap;
}
.maxWidth100 {
  max-width: 100%;
}
.maxWidth450 {
  max-width: 450px !important;
}
.fontWeight300 {
  font-weight: 300;
}
.fontWeight400 {
  font-weight: 400;
}
.fontWeight500 {
  font-weight: 500;
}
.fontWeight700 {
  font-weight: 700 !important;
}

.p12 {
  padding: 12px;
}
.p12-left {
  padding-left: 12px;
}
.p12-right {
  padding-right: 12px;
}
.p12-top {
  padding-top: 12px;
}
.p12-bottom {
  padding-bottom: 12px;
}
.m12 {
  margin: 12px;
}
.m12-left {
  margin-left: 12px;
}
.m12-right {
  margin-right: 12px;
}
.m12-top {
  margin-top: 12px;
}
.m12-bottom {
  margin-bottom: 12px;
}

.iconColor path {
  stroke: rgba(0, 0, 0, 0.6);
}
.userCardNew {
  padding: 4px 8px;
  border-radius: 8px;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 8px;
}
.userCardNew:hover {
  background: rgba(207, 207, 207, 0.15);
}
.userCardNew .organizationImg {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  object-fit: cover;
}
.userCardNew .storyzImg {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  object-fit: cover;
}
.userCardNew .title {
  color: #1f1f1f;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.userCardNew .subTitle {
  color: #1f1f1f;
  font-size: 10px;
  font-family: "Roboto";
  font-weight: 300;
  line-height: 12px;
  letter-spacing: 0.4px;
}

.userCardNew .eventImg {
  width: 79px;
  min-width: 79px;
  height: 53px;
  border-radius: 12px;
  object-fit: cover;
}

.react-datepicker__input-container input {
  display: block;
  width: 100%;
  height: 46px;
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
  transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.userCardNew .date {
  font-weight: 300;
  font-size: 12px !important;
  line-height: 24px !important;
  letter-spacing: 0.4px !important;
  color: #1f1f1f !important;
  object-fit: cover;
}
.honoredCheckbox label {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #666666;
}
.honoredGuestTableBg {
  background: rgba(207, 207, 207, 0.15);
  border: 1px solid #d7d7d7;
  padding: 8px 12px;
}
/* .honoredTableWrapper {
  margin-bottom: 40px;
  height: 400px;
  overflow: auto;
} */

.guestGropuListWrapper .num {
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.4px;
  color: #49454f;
}
.guestGropuListWrapper .guestGropuList .guestGropuImg {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  object-fit: cover;
}
.guestGropuListWrapper .dotted {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #000000;
}

.deleteGuestGroupModal {
  max-width: 548px !important;
  width: 100% !important;
}
.deleteGuestGroupHeader {
  display: flex;
  align-items: center;
}
.deleteGuestGroupHeader .deleteGuestGroupInfoIcon {
  width: 82px;
  height: 82px;
  margin-bottom: 15px;
}
.deleteGuestGroupHeader .head {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #000000;
}

.deleteGuestGroupThumWrapper {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
}
.deleteGuestGroupThumWrapper .deleteGuestGroupThum {
  margin-right: 8px;
  width: 48px;
  height: 48px;
}
.deleteGuestGroupThumWrapper .deleteGuestGroupThum .deleteGuestGroupThumImg {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}
.deleteGuestGroupThumWrapper
  .deleteGuestGroupThumContent
  .deleteGuestGroupthumbTitle {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #1f1f1f;
}
.deleteGuestGroupThumWrapper
  .deleteGuestGroupThumContent
  .deleteGuestGroupthumbSubTitle {
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #1f1f1f;
}

.aboutGuestGroupModal {
  max-width: 612px !important;
  width: 100% !important;
}
.aboutGuestGroupModalHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.aboutGuestGroupModalHeader .guestGroupInfoIcon {
  width: 82px;
  height: 82px;
  margin-bottom: 15px;
}
.aboutGuestGroupModalHeader .head {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #000000;
}
.inviteThemeWrapper .leftInvite {
  width: 70px;
  min-width: 70px;
}
.inviteThemeWrapper .leftInvite img {
  width: 100px;
}

.inviteThemeWrapper .rightInvite .title {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #49454f;
}
.inviteThemeWrapper .rightInvite .subTitle {
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.6px;
  color: #1f1f1f;
  white-space: normal;
}

.centerDropdown {
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
}

.inviteStatus {
  padding: 8px 12px;
  background: rgba(18, 66, 1, 0.1);
  border-radius: 12px;
  width: fit-content;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #666666;
}
.inviteStatus.incomplete {
  background: rgba(254, 203, 46, 0.1);
}
.inviteStatus.canNotSent {
  background: rgba(252, 49, 88, 0.1);
}
.inviteStatus.readyToSend {
  background: rgba(18, 66, 1, 0.1);
}
.border-radius8 {
  border-radius: 8px !important;
}
.border-radius10 {
  border-radius: 10px !important;
}
.border-radius12 {
  border-radius: 12px !important;
}

.boxShadowNone {
  box-shadow: none !important;
}
.deleteMediaImg {
  max-width: 100%;
  max-height: 144px;
  object-fit: cover;
}

.checkList {
  background: rgba(207, 207, 207, 0.15);
  height: 44px;
  padding: 8px 12px;
}
.checkList.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.themeImgWrapper {
  width: 130px;
  height: 195px;
  background: #e7e7e7;
}

.previewActive {
  background: rgba(83, 215, 105, 0.1) !important;
  border: 1px solid rgba(0, 0, 0, 0.05) !important;
  color: #000000 !important;
}

.inviteTemplateModalWrapper {
  max-width: 508px !important;
  margin: 0 0 0 auto !important;
}
.inviteTemplateModalWrapper .modal-header {
  background: #286bef;
  padding: 12px;
  height: 48px;
}
.inviteTemplateModalWrapper .modal-header .close {
  opacity: 1;
}
.inviteTemplateModalWrapper .modal-header .close span {
  color: #fff;
  font-size: 24px;
}
.inviteTemplateModalWrapper .modal-content .themeBg {
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  z-index: 1;
  height: calc(100% + 4px);
}
.inviteTemplateModalWrapper .themeTitle {
  font-weight: 400;
  font-size: 44px;
  line-height: 125%;
  text-align: center;
  color: #333333;
  font-family: "Great Vibes";
}
.inviteTemplateModalWrapper .subTitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.4px;
  border-bottom: 1px solid #979798;
}
.inviteTemplateModalWrapper .themeImg {
  width: 100%;
  height: 212px;
}
.inviteTemplateModalWrapper .themeImg img {
  border-radius: 12px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.inviteTemplateModalWrapper .themeBox {
  padding: 0 60px;
}

.inviteTemplateModalWrapper .themeName {
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -0.04em;
  color: #333333;
  text-align: center;
}
.inviteTemplateModalWrapper .themeDate {
  background: #778a6e;
  border-radius: 100px;
  padding: 6px 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: -0.04em;
  color: #ffffff;
  width: fit-content;
  margin: 0 auto;
}
.inviteTemplateModalWrapper .themeGuestWrapper .head {
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -0.04em;
}
.inviteTemplateModalWrapper .themeGuestListWrapper {
  gap: 16px;
}
.inviteTemplateModalWrapper .themeGuestListWrapper .themeGuestList {
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.04em;
  color: #333333;
  display: flex;
  flex-direction: column;
  width: 68px;
}
.inviteTemplateModalWrapper
  .themeGuestListWrapper
  .themeGuestList
  .themeGuestImg {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  margin-bottom: 8px;
  object-fit: cover;
  min-width: 60px;
  max-width: 60px;
}

.inviteTemplateModalWrapper .themeMapWrapper {
  background: #778a6e;
  border-radius: 12px;
  width: 160px;
  min-height: 207px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.inviteTemplateModalWrapper .themeMapWrapper .title {
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -0.04em;
  color: #ffffff;
}
.inviteTemplateModalWrapper .themeMapWrapper .subTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: -0.04em;
  color: #ffffff;
}

.inviteTemplateModalWrapper .mapWrapper .directionBtn {
  background: #ffffff;
  border-radius: 12px;
  position: absolute;
  z-index: 2;
  left: 8px;
  right: 8px;
  bottom: 8px;

  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: -0.04em;
  color: #dc9584;
}

.inviteTemplateModalWrapper .themePorterWrapper {
  background: #cfd8c0;
  border-radius: 12px;
  width: 160px;
  min-height: 207px;
  text-align: center;
}
.inviteTemplateModalWrapper .themePorterWrapper .img {
  width: 75px;
  height: 75px;
  border-radius: 100%;
  object-fit: cover;
  margin: 0 0 8px 0;
}
.inviteTemplateModalWrapper .themePorterWrapper .title {
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.04em;
  color: #333333;
}
.inviteTemplateModalWrapper .themePorterWrapper .subTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.04em;
  color: #333333;
}
.inviteTemplateModalWrapper .themePorterWrapper .contactBtn {
  background: #ffffff;
  border-radius: 12px;
  position: absolute;
  z-index: 2;
  left: 8px;
  right: 8px;
  bottom: 8px;

  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: -0.04em;
  color: #778a6e;
}

.themeAppWrapper {
  height: 96px;
  background: #ffffff;
  border-radius: 12px;
}
.themeAppWrapper .head {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #333333;
}

.themeStoreApp .themeImg3 {
  font-weight: 700;
  font-size: 9px;
  line-height: 12px;
  /* identical to box height, or 133% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4px;

  color: #000000;
}

.uploadCircleWrapper {
  width: 248px !important;
  height: 248px !important;
}

.bg1 {
  background: rgba(198, 193, 203, 0.3);
  border-radius: 6px;
}

.bg2 {
  background: rgba(198, 193, 203, 0.2);
}
.bg3 {
  background: rgba(198, 193, 203, 0.3);
}
.bg4 {
  background: rgba(207, 207, 207, 0.15);
}

.combinedTableWrapper .sortable {
  vertical-align: middle;
}
.combinedTableWrapper .react-bootstrap-table .table thead tr th {
  vertical-align: middle;
}

.combinedTableWrapper .react-bootstrap-table .table thead tr th:first-child {
  width: 80px;
}
.combinedTableWrapper .react-bootstrap-table {
  overflow-x: auto;
}

.honoredTableWrapper .table {
  border-spacing: 0 12px;
  border-collapse: initial;
}

.honoredTableWrapper .table tbody tr {
  box-shadow: inset 0 0 1px #666;
  background: rgba(207, 207, 207, 0.15);
}

.honoredTableWrapper .react-bootstrap-table .table thead tr th {
  background: #fff !important;
  border-color: #fff !important;
}
.honoredTableWrapper .table td {
  padding: 12px 20px;
  vertical-align: middle;
}

.eventHonoredCard {
  display: flex;
  gap: 12px;
}

.addSupportTicketModalWrapper {
  max-width: 360px !important;
  width: 100% !important;
  position: absolute !important;
  right: 0;
  top: 0;
  margin: 0 !important;
}
.addSupportTicketModalWrapper .modal-content {
  border-radius: 12px;
}
.addSupportTicketModalWrapper .modal-header {
  background: #286bef;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 12px;
  height: 48px;
}
.addSupportTicketModalWrapper .modal-title {
  color: #fff;
  font-size: 20px;
  font-weight: 300;
}
.addSupportTicketModalWrapper .modal-header .close {
  padding: 8px 12px;
  opacity: 1;
}
.addSupportTicketModalWrapper .modal-header .close span {
  color: #fff;
}

.sendBtnInput {
  background: none !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.sendBtn {
  min-width: none !important;
  background: none !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.chatBg {
  background: #e4ebf3;
  border-radius: 12px;
}
.chatBoxWrapper {
  height: calc(100vh - 259px);
  overflow-y: auto;
}

.uploadGuestList {
  width: 183px;
  text-align: center;
}
.uploadGuestList .iconBox {
  background: rgba(198, 193, 203, 0.2);
  border-radius: 24px;
  padding: 24px 12px;
  width: 130px;
  margin-left: auto;
  margin-right: auto;
}
.uploadGuestList .DownloadBtn {
  height: 32px;
  width: 100%;
  background: rgba(83, 215, 105, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4px;
  color: #000000;
  padding: 0 8px;
}
.text-decoration-underline {
  text-decoration: underline;
}

.saveChatTemplateModal {
  max-width: 355px !important;
}
.savedChatModal {
  max-width: 330px !important;
}
.loadingModal {
  max-width: 266px !important;
}
.loadingModal .modal-content {
  height: 266px;
}
.dateTimePickerGlobal {
  /* z-index: 91; */
}

.dateTimePickerGlobal
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker {
  border: none;
  width: 599px;
}
.dateTimePickerGlobal
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container {
  width: 255px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  height: 300px;
}

.dateTimePickerGlobal
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__day {
  color: #6d6f80;
}

.dateTimePickerGlobal
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__day--keyboard-selected {
  color: #fff;
  background: #5867dd;
}

.dateTimePickerGlobal
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__navigation--next--with-time:not(
    .react-datepicker__navigation--next--with-today-button
  ) {
  right: 347px !important;
  top: 8px;
}

.dateTimePickerGlobal
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__header
  .react-datepicker__current-month {
  font-weight: 400;
}

.dateTimePickerGlobal
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__day--selected {
  color: #fff;
  background: #5867dd;
}
.dateTimePickerGlobal
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__header {
  background: #fff;
  border: none;
}
.dateTimePickerGlobal
  .react-datepicker__tab-loop
  .react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after,
.dateTimePickerGlobal
  .react-datepicker__tab-loop
  .react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.dateTimePickerGlobal
  .react-datepicker__tab-loop
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after,
.dateTimePickerGlobal
  .react-datepicker__tab-loop
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border: none;
}

.dateTimePickerGlobal
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker-time__header {
  font-weight: 400;
  /* text-align: left; */
  padding-left: 8px;
}

.dateTimePickerGlobal
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker__time-container {
  border: none;
  margin-left: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  height: 300px;
  width: 340px !important;
}
.dateTimePickerGlobal
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker__time-container
  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border: none;
  background: none;
}

.dateTimePickerGlobal
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__header--time {
}

.dateTimePickerGlobal
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  padding: 0 8px;
  width: inherit !important;
}
.dateTimePickerGlobal
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  overflow-x: hidden;
  padding-right: 10px;
}
.dateTimePickerGlobal
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  width: inherit !important;
  padding: 3px 5px !important;
  border-radius: 4px;
  height: 28px;
  color: #6d6f80;
}
.dateTimePickerGlobal
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: #5867dd;
  color: #fff;
}

.dateTimePickerGlobal
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker__day--disabled {
  background: rgba(217, 217, 217, 0.59);
  color: #ccc;
}
.dateTimePickerGlobal .react-datepicker__day-name,
.dateTimePickerGlobal .react-datepicker__day,
.dateTimePickerGlobal .react-datepicker__time-name {
  width: 2.1rem;
  line-height: 2.1rem;
  margin: 0.23rem 0;
}

.videoHeight {
  max-height: 320px;
  width: 100%;
}

.datePickerGlobal
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker {
  width: fit-content !important;
}
.deleteModalImg {
  width: 100%;
  object-fit: cover;
  height: 140px;
}

.dateTimePickerGlobal.daclass {
  min-width: 270px;
}
.dateTimePickerGlobal.daclass .form-control {
  background: no-repeat;
  border: none;
  color: #fff;
  font-size: 16px;
  padding: 0;
  height: inherit;
  text-align: center;
}

.dateTimePickerGlobal.daclass .react-datepicker__close-icon {
  display: none;
}

.dropdown .dropdown-menu-arrow {
  display: none !important;
}
.dropdown.show .dropdown-menu-arrow {
  display: inline-block !important;
}

.todoWrapperNew {
  display: flex;
  transition: all 0.3s ease;
  padding: 12px;
}
.todoWrapperNew:hover {
  background: rgba(207, 207, 207, 0.15);
  transition: all 0.3s ease;
}
.todoWrapperNew .todoDottedWrapper .dropdown .btn {
  margin-right: 0;
  width: 36px;
  height: 36px;
}
.todoWrapperNew .todoDottedWrapper.sideBrdr {
  border-right: 1px solid #e9ecef !important;
  padding-right: 24px;
  display: flex;
  align-items: center;
}
.todoWrapperNew .todoRightContentWrapper {
  padding: 0 0 0 12px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.todoWrapperNew .todotitle {
  padding: 8px 0 12px;
  margin: 0;
}
.todoWrapperNew .todoRightContentWrapper .todoContentListWrapper {
  display: grid;
  align-items: center;
  gap: 12px;
  grid-template-columns: 150px 150px 150px 1fr;
}
.todoWrapperNew .todoRightContentWrapper .todoContentListWrapper .todoList {
  color: #000;
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.6px;
}
.todoWrapperNew
  .todoRightContentWrapper
  .todoContentListWrapper
  .todoList
  .date {
  color: #000;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1px;
}
.todoWrapperNew .statusBox {
  min-width: 150px;
  height: 40px;
  padding: 4px 8px;
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 8px;
  align-items: center;
  color: #1f1f1f;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.greenBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px 4px 12px;
  background: rgba(83, 215, 105, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  width: fit-content;
  height: 36px;
  font-size: 14px;
  color: #000;
}
.greenBtn:hover {
  color: #000;
}

.billingGreenBtn {
  width: fit-content !important;
  background: #019587 !important;
  color: #fff !important;
}

.featuredAccordion.accordion {
  padding-left: 0;
  padding-right: 0;
  padding-top: 8px;
}
.featuredAccordion.accordion.active,
.featuredAccordion.accordion:hover {
  background: none;
  color: #000;
}
.featuredAccordion.accordion .accordion-item {
  width: 100%;
}
.featuredAccordion.accordion .accordion-button.collapsed,
.featuredAccordion.accordion .accordion-button {
  background: none;
  border: none;
  width: 100%;
  text-align: right;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #000;
}
.featuredAccordion.accordion .accordion-button .arrowIcon {
  margin-left: 12px;
}
.featuredAccordionListWrapper {
}
.featuredAccordionListWrapper .featuredAccordionList {
  list-style: none;
  margin: 0;
  padding: 0;
}
.featuredAccordionListWrapper .featuredAccordionList li {
  font-size: 14px;
  color: #282828;
  position: relative;
  line-height: 24px;
  padding: 4px 0 4px 32px;
}
.featuredAccordionListWrapper .featuredAccordionList li .checkIcon {
  position: absolute;
  font-size: 14px;
  left: 4px;
  top: 10px;
  color: #282828;
}
.featuredAccordion.accordion .accordion-button .FeaturedText {
  display: none;
}
.featuredAccordion.accordion .accordion-button.collapsed .FeaturedText {
  display: inline-block;
}
.featuredAccordion.accordion .accordion-button .closeText {
  display: inline-block;
}
.featuredAccordion.accordion .accordion-button.collapsed .closeText {
  display: none;
}
.featuredAccordion.accordion .accordion-button .arrowIcon {
  transform: rotate(-90deg);
  transition: all 0.3s ease-in-out;
}
.featuredAccordion.accordion .accordion-button.collapsed .arrowIcon {
  transform: rotate(0);
  transition: all 0.3s ease-in-out;
}

.alwaysFeatured.accordion .accordion-button .FeaturedText {
  display: inline-block;
}

.alwaysFeatured.accordion .accordion-button .arrowIcon {
  transform: rotate(0);
}

.serviceChangeModal {
  max-width: 653px !important;
}
.serviceChangeModal .serviceChangeHead .serviceChangeIcon {
  width: 88px;
  height: 88px;
}
.serviceChangeModal .serviceChangeHead .head {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.serviceChangeModal .modal-content {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.serviceChangeContentWrapper {
  background: rgba(254, 203, 46, 0.1);
  border-radius: 24px;
  padding: 24px 24px 48px;
  max-width: 368px;
}

.serviceChangeContentWrapper.upgradeBg {
  background: rgba(83, 215, 105, 0.1);
}

.customButtonWrapper {
  background: #fdfdfd;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  width: fit-content;
  height: 62px;
  padding: 14px 12px 16px;
  left: 0;
  right: 0;
  bottom: 0;
  position: sticky;
  top: 0;
  margin: -74px auto 0;
  z-index: 9;
}
.customButton {
  background: rgba(252, 49, 88, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  width: fit-content;
  height: 32px;
  padding: 4px 16px 4px 12px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}
.billingHistoryResponsive {
  overflow-x: auto;
}
.billingHistoryWrapper {
  min-width: 800px;
}
.billingHistoryHead {
  border: 1px solid #d7d7d7;
  border-radius: 6px 6px 0px 0px;
  background: #e7e7e7;
  height: 39px;
  display: flex;
  align-items: center;
}
.billingHistoryHead .billingHistoryList {
  padding: 6px;
  text-transform: capitalize;
  letter-spacing: 1px;
  white-space: nowrap;
  font-weight: 700;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.billingHistoryHead .billingHistoryList:nth-of-type(1) {
  min-width: 28px;
  max-width: 28px;
}
.billingHistoryHead .billingHistoryList:nth-of-type(2) {
  min-width: 90px;
  max-width: 90px;
}
.billingHistoryHead .billingHistoryList:nth-of-type(3) {
  min-width: 110px;
  max-width: 110px;
  text-align: center;
}
.billingHistoryHead .billingHistoryList:nth-of-type(4) {
  width: calc(100% - 28px - 90px - 110px - 100px);
}
.billingHistoryHead .billingHistoryList:nth-of-type(5) {
  min-width: 100px;
  max-width: 100px;
  text-align: center;
}

.billingHistoryListAcc {
  flex-direction: column;
  padding: 0 !important;
  background: none;
}
.billingHistoryListAcc.accordion:hover,
.active {
  background: none;
  color: #8898aa;
}
.billingHistoryListAcc.accordion .accordion-item {
  width: 100%;
}
.billingHistoryListAcc.accordion .accordion-item .accordion-header {
  margin: 0;
}
.billingHistoryListAcc .accordion-button.collapsed,
.billingHistoryListAcc .accordion-button {
  width: 100%;
  border: none;
  outline: none;
  padding: 0;
  background: none;
}
.billingHistoryBody {
  border: 1px solid #d7d7d7;
  background: rgba(207, 207, 207, 0.15);
  height: 39px;
  display: flex;
  align-items: center;
}
.billingHistoryBody .billingHistoryList {
  padding: 6px;
  color: #000;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  white-space: nowrap;
  text-align: left;
}
.billingHistoryBody .billingHistoryList:nth-of-type(1) {
  min-width: 28px;
  max-width: 28px;
}
.billingHistoryBody .billingHistoryList:nth-of-type(2) {
  min-width: 90px;
  max-width: 90px;
}
.billingHistoryBody .billingHistoryList:nth-of-type(3) {
  min-width: 110px;
  max-width: 110px;
  text-align: center;
}
.billingHistoryBody .billingHistoryList:nth-of-type(4) {
  width: calc(100% - 28px - 90px - 110px - 100px);
}
.billingHistoryBody .billingHistoryList:nth-of-type(5) {
  min-width: 150px;
  max-width: 150px;
  text-align: center;
}

.billingHistoryContentWrapper {
  border: 1px solid #d7d7d7;
  background: rgba(207, 207, 207, 0.15);
  display: grid;
  grid-template-columns: 30% 50% 20%;
}

.border-right {
  border-right: solid 1px #d7d7d7;
}

.react-bs-table-sizePerPage-dropdown .dropdown-menu {
  position: absolute !important;
  z-index: 1000 !important;
  display: none !important;
  min-width: 120px !important;
  max-width: 160px;
  background-color: #fff !important;
  border: 0 solid rgba(0, 0, 0, 0.15) !important;
  border-radius: 0.4375rem !important;
}
.react-bs-table-sizePerPage-dropdown .dropdown-menu.show {
  display: block !important;
}

#__filestack-picker .fsp-source-list__item[title="LABEL_IN_MENU"] {
  display: none;
}

#__filestack-picker .fsp-drop-area,
#__filestack-picker .fsp-header,
#__filestack-picker .fsp-modal__body {
  background: none;
}
#__filestack-picker .fsp-modal__sidebar {
  border-radius: 12px 0 0 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  background: #fff;
}

#__filestack-picker .fsp-modal {
  border-radius: 0;
  background: #fff;
  border-radius: 12px;
  max-width: 700px;
  width: 100%;
  height: 100%;
  max-height: 600px;
}

#__filestack-picker .fsp-modal__body {
  width: calc(100% - 180px);
}

#__filestack-picker .fsp-source-list__item:focus,
#__filestack-picker .fsp-drop-area:focus {
  box-shadow: none;
}
#__filestack-picker .fsp-source-list__item {
  height: 64px;
  color: #1f1f1f;
}
#__filestack-picker .fsp-source-list__label {
  font-weight: 300;
  font-size: 14px;
}
#__filestack-picker .fsp-modal__sidebar,
#__filestack-picker .fsp-modal__sidebar:hover {
  width: 180px;
}

#__filestack-picker .fsp-source-list__icon {
  width: 48px;
  height: 48px;
}
#__filestack-picker .fsp-source-list__item:hover {
  color: #1f1f1f;
}
#__filestack-picker .fsp-picker__close-button {
  right: 16px;
  opacity: 1;
}
#__filestack-picker .fsp-modal__body--transformer {
  width: calc(100% - 60px) !important;
}

.selectSearchDropdown .dropdownTriggered {
  min-height: 40px;
  height: auto !important;
}

.multipleSelecctChipWrapper {
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #000000;
  min-height: 40px;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  background-color: #fff;
  box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
  padding: 10px 12px;
}
.multipleSelecctChipWrapper .searchBtn {
}

.addListModalWrapper.modal-dialog {
  max-width: 396px;
}
.addListModalWrapper .modal-header {
  padding: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}
.addListModalWrapper .modal-header .icon {
  width: 82px;
  height: 82px;
  margin-bottom: 15px;
}
.addListModalWrapper .modal-header .head {
  color: #000;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.4px;
  padding-bottom: 8px;
}

.addListModalWrapper p {
  color: #000;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.addListModalWrapper .addNewListWrapper {
  padding: 6px;
}
.addListModalWrapper .addNewListWrapper .form-group {
  display: flex;
  align-items: center;
}
.addListModalWrapper .addNewListWrapper .form-group .pinkBtn {
  min-width: 77px;
}
.addListModalWrapper .modal-body {
  padding: 24px;
}
.addListModalWrapper .modal-footer {
  padding: 4px 20px 28px;
}

.size72 {
  width: 72px !important;
  height: 72px !important;
}

.adminBadge {
  background: #fc3158;
  border-radius: 4px;
  padding: 3px 4px;
  width: fit-content;
  font-weight: 900;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.1px;
  color: #fdfdfd;
}

.fitContent {
  width: fit-content;
}
.widthAuto {
  width: auto !important;
}

.aboutContentWrapper .form-control {
  /* background: transparent; */
  border: none;
  color: #000;
  resize: none;
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
}
.aboutContentWrapper textarea.form-control {
  background: rgba(0, 0, 0, 0.06);
}

.eventHeaderSlider {
  background: #2053b5;
  height: 40px;
  padding: 0 5rem 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* margin-top: -12px; */
}

.eventHeaderSlider .sliderList {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  /* min-height: 42px; */
}

.eventHeaderSlider .tps {
  width: 600px;
  /* margin-left: auto; */
  background: #2054b6;
}
.eventHeaderSlider .badge {
  padding: 2px 4px;
  color: #fdfdfd;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.6px;
  border-radius: 4px;
  background: #666;
  font-family: "Inter", sans-serif !important;
  text-transform: inherit;
  margin-right: 40px;
}

.eventHeaderSlider .slick-prev,
.eventHeaderSlider .slick-next {
  margin-top: 2px;
}
.eventHeaderSlider .slick-slide {
  width: fit-content !important;
}
/* .eventHeaderSlider .slick-slide.slick-current .tabsclassevent{
  background: #F9AE1C;
} */

.mainContentWrapper {
  padding: 24px 15px 40px;
  min-height: 800px;
}

.menuLinkWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px 16px;
}
.menuLinkWrapper .menuLink {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.menuLinkWrapper .menuLink img {
  width: 32px;
  height: 32px;
  object-fit: contain;
  margin-bottom: 4px;
}
.menuLinkWrapper .menuLink p {
  font-family: "Inter", sans-serif !important;
  text-align: center;
  color: #49454f;
  margin: 0;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.eventCardLeft .title {
  font-size: 48px;
  text-align: end;
  color: #666666;
  line-height: 54px;
  font-weight: 400;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.containerWraperOuter {
  background: #2053b5;
  padding-right: 12px;
  padding-bottom: 40px;
  max-width: 100%;
}
.containerWraperOuter .card {
  margin-bottom: 0;
  border-radius: 12px;
}

.editGeopspaceBtn {
  color: #000;
  font-size: 12px;
  border-radius: 50px;
  height: 40px;
  padding: 8px 16px;
  background: rgba(198, 193, 203, 0.2);
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 24px auto 0;
  cursor: pointer;
}

.geoSpaceModalWrapper.modal-dialog {
  max-width: 1109px;
  /* width: 90%; */
  /* margin-left: 5%;
  margin-right: 5%; */
}
.geoSpaceModalWrapper .modal-header {
  padding-bottom: 0;
}
.geoSpaceModalWrapper .modal-title {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
}
.geoSpaceModalWrapper .modal-title .icomn {
  width: 48px;
  height: 48px;
  object-fit: contain;
}

.geospaceFormModalWrapper .heading {
  background: rgba(198, 193, 203, 0.3);
  border-radius: 6px;
  height: 48px;
  padding: 12px;
  color: #000;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-transform: inherit;
}
.geospaceFormModalWrapper .geospaceBox {
  padding: 12px 16px;
}
.geospaceFormModalWrapper .form-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #666666;
  margin-bottom: 12px;
}
.geospaceFormModalWrapper .form-control {
  border: 1px solid #d7d7d7;
  border-radius: 6px;
  background: #ffffff;
  height: 48px;
  padding: 12px;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #000000;
}
.geospaceFormModalWrapper .form-control-textarea {
  height: 80px;
  resize: none;
}

.form-check.form-check-inline .form-check-label {
  color: #666666;
  letter-spacing: 0.4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  transform: translateY(0px);
  margin-left: 8px;
}
/* .geospaceFormModalWrapper .form-check.form-check-inline .form-check-label {
  color: #666666;
  letter-spacing: 0.4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  transform: translateY(0px);
  margin-left: 8px;
} */

.geospaceFormModalWrapper .error {
  font-size: 12px;
  color: #ff0000;
  font-size: 14px;
  margin: 8px 0;
}
.formBox {
  margin-bottom: 16px !important;
}

.form-group textarea.form-control,
.formBox textarea.form-control {
  height: 86px;
}
.form-group textarea.large,
.formBox textarea.large {
  height: 250px;
}
/* .geospaceFormModalWrapper  */
.inlineFormBox {
  display: flex;
  gap: 20px;
  /* flex-wrap: wrap; */
}
.geospaceFormModalWrapper .units {
  text-align: right;
  margin-top: 40px;
}

.custom-control.form-check {
  padding: 0 0 0 18px;
  margin: 0;
}
.custom-control.form-check .container3 {
  padding: 0;
  margin: 0 0 0 8px;
}
.custom-control.form-check .checkmark {
  top: 3px;
  left: -26px;
}

.mapModalWrapper.modal-dialog {
  max-width: 312px;
  height: 230px;
}
.mapModalWrapper .modal-body {
  padding: 0;
}
.mapModalWrapper .modal-footer {
  padding: 0 24px;
}
.mapModalWrapper .modal-content {
  border-radius: 28px;
  border: 1px solid #000;
  background: #eee8f4;
  overflow: hidden;
}
.mapModalWrapper .mapBtn:hover,
.mapModalWrapper .mapBtn {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: center;
  color: #6750a4;
  font-family: "Roboto";
  padding: 10px 12px;
  background: #eee8f4;
  border-radius: 0;
  margin: 0 0 0 16px !important;
  box-shadow: none;
  border: 0;
  transform: none;
}
.eventBannerPara {
  color: #000;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
  padding: 8px 18px;
  margin-bottom: 16px;
}
.eventBannerPara a {
  color: #000;
}

.dataNotFound {
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #666;
}
.avatarCardWrapper {
  display: grid;
  gap: 12px;
  grid-template-columns: 60px 1fr;
}
.avatarCardWrapper .imagevent {
}
.avatarCardWrapper .imageventContent .title {
  font-size: 18px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: #49454f;
}
.avatarCardWrapper .imageventContent .subTitle {
  font-size: 10px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.4px;
  color: #49454f;
}

.iconSize24 {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.cardBox {
  display: grid;
  padding: 12px;
  gap: 12px;
  margin-bottom: 12px;
}
.imgEventWraperNew {
  display: grid;
  gap: 12px;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #666;
  font-weight: 300;
}

.form-group {
  /* display: grid; */
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 0;
}

.formBox label,
.form-group label {
  margin: 0;
  font-size: 14px !important;
  line-height: 24px !important;
  letter-spacing: 0.4px !important;
  color: #666 !important;
  font-weight: 300 !important;
}
.formBox .form-control,
.form-group .form-control {
  border: 1px solid #d7d7d7;
  border-radius: 6px;
  background: #ffffff;
  height: 48px;
  padding: 12px;
  font-weight: 300 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  letter-spacing: 0.4px !important;
  color: #1f1f1f !important;
}
.formBox .form-control::placeholder,
.form-group .form-control::placeholder {
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}
select:focus {
  box-shadow: none !important;
}
.containerBox select.form-control,
.formBox select.form-control,
.form-group select.form-control {
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: rgba(83, 215, 105, 0.1);
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #666 !important;
  font-weight: 300;
  padding: 8px 12px;
}

.mainContentBoxWrapper {
  padding: 24px;
}
.containerBox {
  padding: 12px;
  margin-bottom: 12px;
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr;
}
.containerBox .error {
  color: #ff0000;
  font-size: 14px;
  margin: 8px 0;
}
.containerBox .form-group,
.containerBox .formBox {
  margin-bottom: 16px;
}

.containerBox .inlineFormBox {
  display: flex;
  gap: 20px;
}
.containerBox label,
.containerBox .form-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #666666;
  margin-bottom: 0;
}

.containerBox .form-control {
  border: 1px solid #d7d7d7;
  border-radius: 6px;
  background: #ffffff;
  height: 48px;
  padding: 12px;
  font-weight: 300 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  letter-spacing: 0.4px !important;
  color: #000000 !important;
}
.eventHonoredGuestDropdown {
  margin-bottom: 24px;
}
.eventHonoredGuestDropdown .dropdownTriggered {
  height: auto !important;
}
.categoryTypeDropdown .selecttodata {
  display: grid;
  gap: 12px;
}

.categoryTypeDropdown .selecttodata label {
  margin: 0;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #666;
  font-weight: 300;
}
.selecttodata .buttonselect:hover,
.selecttodata .buttonselect {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: rgba(83, 215, 105, 0.1);
  height: 48px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #666 !important;
  font-weight: 300;
}

.selecttodata .buttonselect svg {
  width: 32px;
  height: 32px;
  margin: 0;
}
.eventAboutWrapperNew .form-text {
  font-size: 10px;
  font-weight: 300;
  line-height: 12px;
  letter-spacing: 0.4px;
  text-align: right;
  color: #666;
}
.repeatingEventBox {
  padding: 8px 0;
}
.repeatingEventBox .imageeventim2 {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #666;
  font-weight: 300;
}
.eventSettingCheckbox .custom-control {
  padding: 10px 12px 10px 26px;
  background: rgba(207, 207, 207, 0.15);
}
.eventSettingCheckbox .custom-control .container3 {
  padding-left: 14px;
}
.eventSettingCheckbox .custom-control .checkmark {
  top: 3px;
}

.bakcToStoryWraper {
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 6px;
  align-items: center;
  color: #1f1f1f;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  margin-left: -5px;
  padding: 12px 0;
}
.bakcToStoryWraper .arrowIcon {
  width: 24px;
  height: 24px;
  padding: 3px;
  object-fit: contain;
}
.organization {
  padding: 48px 24px 24px;
  background: #2054b6 url("../../../img/brand/cloudImg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  margin-right: 12px;
  border-radius: 0;
}
.organization .cardWrapper {
  border-radius: 12px;
}
.organization .cardWrapper .card {
  border-radius: 12px;
}
.organization .cardWrapper,
.organization .cardWrapper .card {
  height: 100%;
  margin: 0;
}
.organization .cardWrapper {
  padding-bottom: 30px;
}

.organization .cardWrapper .card .headingNew {
  color: #49454f;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.4px;
}
.organization .cardWrapper .card .card-header .icon,
.organization .cardWrapper .card .card-header .cardheader .icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.organization .cardWrapper .card-header:first-child {
  border-radius: 12px 12px 0 0;
}
.organization .cardWrapper .card .card-body .scrtime {
  /* max-height: calc(100% - 65px);
  height: 100%; */
  height: 400px;
  max-height: 400px;
}
.organization .cardWrapper .card .card-body .scrtime .user-pading {
  margin-bottom: 12px;
}
/* .organization .cardWrapper .card .card-body .MemberTeam{
  position: relative;
  top: calc(100% - 157px);
} */

.organization .cardWrapper .card .card-body .youStoryzDashboardWrapper {
  /* max-height: calc(100% - 65px); */
  /* height: 100%; */
  height: 290px;
  overflow-y: auto;
  margin: 24px 0;
}
.organization
  .cardWrapper
  .card
  .card-body
  .youStoryzDashboardWrapper
  .title
  a {
  color: #1f1f1f;
}

.organizerWrapperMain {
  padding: 24px;
  background: #2054b6 url("../../../img/brand/cloudImg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  margin-right: 12px;
  border-radius: 12px 12px 0 0;
  min-height: 75vh;
}
.organizerWrapperMain .organizerCardWrapper {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 24px;
  /* margin: 0 12px; */
}

.storyzWrapperMain {
  padding: 24px;
  background: #2054b6 url("../../../img/brand/cloudImg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  margin-right: 12px;
  border-radius: 12px 12px 0 0;
}
.storyzWrapperMain .storyzCardWrapper {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 24px;
  /* margin: 0 12px; */
}

.headingWrapper {
  display: flex;
  align-items: center;
  padding-bottom: 12px;
}
.heading-1 {
  padding: 0px 12px;
  font-size: 18px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: #49454f;
  margin: 0;
}
.heading-2 {
  padding: 0px 12px;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: #49454f;
  margin: 0;
}

.infoToolTip {
  width: 36px;
  height: 36px;
  padding: 6px;
}
.organizationPara {
  padding: 8px 18px;
  color: #000;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.organizationPara a {
  color: #000;
}

.tableHeadNone thead {
  display: none;
}
.storyConfigureModal.modal-dialog {
  max-width: 1000px;
}
.storyConfigureModal .modal-header {
  padding: 24px 24px 16px;
}
.storyConfigureModal .modal-body {
  padding: 0 0 4px;
  margin: 0 32px;
}
.storyConfigureModal .modal-header h2 {
  display: grid;
  align-items: center;
  grid-template-columns: 48px 1fr;
  gap: 12px;
  color: #000;
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  margin: 0;
}
.storyConfigureModal .modal-footer {
  padding: 24px 24px 48px;
  justify-content: flex-start;
}
/* .selectStoryTypeDropdown{
  position: relative;
} */
.selectStoryTypeDropdown {
  border: none;
  position: relative;
}
.selectStoryTypeDropdown .addPlus {
  color: #d7d7d7;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.6px;
  gap: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 4px 18px;
}
.selectStoryTypeDropdown .dropdown-menu {
  border-radius: 12px !important;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 12px 0;
  margin: 0;
  border: none !important;
  max-height: inherit !important;
}
.selectStoryTypeDropdown .search-box {
  padding: 12px;
  background: #fff;
}
.selectStoryTypeDropdown .dropdown-menu .dropdown-item {
  color: #1f1f1f;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
  padding: 4px 8px;
}
.selectStoryTypeDropdown .dropdown-menu .dropdownOuter {
  height: 288px;
  overflow-y: auto;
}

.selectStoryTypeDropdown .dropdown-menu .dropdown-item:hover {
  background: #fff;
}
.selectStoryTypeDropdown .dropdown-menu .dropdown-item.selected {
  background: #fff;
  font-weight: 600;
}

.selectStoryTypeDropdown .dropdown-input {
  padding: 4px 8px 4px 12px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: rgba(83, 215, 105, 0.1);
}

.border-bottom-right-radius {
  border-bottom-right-radius: 0 !important;
}
.border-bottom-left-radius {
  border-bottom-left-radius: 0 !important;
}

.react-bootstrap-table-pagination .dataTables_length label,
.react-bootstrap-table-pagination-total {
  color: #000;
  font-size: 12px !important;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.6px;
}

.ChangeTimezoneModalWrapper .ChangeTimezone {
  padding-bottom: 12px;
  margin-bottom: 12px;
}
.ChangeTimezoneModalWrapper .modal-body {
  padding-bottom: 8px;
}
.ChangeTimezoneModalWrapper .modal-footer {
  padding: 4px 20px 24px;
}

.honoredRelationshipModal {
  max-width: 480px;
}
.honoredRelationshipModal .modal-body {
  padding: 24px;
}
.honoredRelationshipModal .modal-footer {
  padding: 4px 24px 32px;
}
.honoredRelationshipModal .HonoredGuestGroupHeader {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #000000;
  display: flex;
  align-items: center;
  gap: 12px;
  padding-bottom: 8px;
  margin-bottom: 16px;
}
.honoredRelationshipModal .HonoredGuestGroupHeader .icon {
  width: 88px;
  height: 88px;
}
.honoredRelationshipModal .deleteGuestGroupThumWrapper {
  padding: 4px 8px;
  margin-bottom: 16px;
  max-width: 396px;
}
.honoredRelationshipModal .honorRelationshipFormWrapper {
  padding: 12px 24px;
}
.honoredRelationshipModal .honorRelationshipFormWrapper .form-group {
  margin-bottom: 8px;
  gap: 8px;
}

.honoredRelationshipModal .honorRelationshipFormWrapper .form-group {
  color: #000;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.48px;
}

.honoredRelationshipModal .repeateventDetails {
  padding: 4px 8px;
  gap: 8px;
  display: flex;
}
.honoredRelationshipModal .repeateventDetails .evimages {
  width: 79px;
  min-width: 79px;
  height: 53px;
  margin: 0;
}
.honoredRelationshipModal .repeateventDetails .repeateventTitle {
  color: #1f1f1f;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.honoredRelationshipModal
  .repeateventDetails
  .repeateventTitle
  .repeateventlocation {
  font-size: 10px;
  line-height: 12px;
}

.removeGuestFromHonoredModal.modal-dialog {
  max-width: 480px;
}
.removeGuestFromHonoredModal .modal-header {
  padding: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}
.removeGuestFromHonoredModal .modal-header .icon {
  width: 82px;
  height: 82px;
  margin-bottom: 15px;
}
.removeGuestFromHonoredModal .modal-header .head {
  color: #000;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.4px;
  padding-bottom: 8px;
}

.removeGuestFromHonoredModal .modal-body {
  padding: 24px 24px 0 24px;
}
.removeGuestFromHonoredModal p {
  color: #000;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.removeGuestFromHonoredModal .repeateventDetails {
  padding: 0;
  margin-bottom: 16px;
}
.removeGuestFromHonoredModal .repeateventDetails .evimages {
  margin-left: 0;
}
.removeGuestFromHonoredModal .deleteGuestGroupThumWrapper {
  padding: 4px;
  margin-bottom: 16px;
}
.removeGuestFromHonoredModal
  .deleteGuestGroupThumWrapper
  .deleteGuestGroupThum {
  width: 72px;
  height: 72px;
  margin-right: 4px;
}
.removeGuestFromHonoredModal .removeGuestHonoreContent {
  max-width: 307px;
  margin: 0 auto;
}
.removeGuestFromHonoredModal .modal-footer {
  padding: 4px 20px 28px;
}

.mediaTabWrapper {
  padding: 12px 24px !important;
  margin-bottom: 16px;
}

.eventMediaGallaryOuterWrapper .eventMediaListNew {
  gap: 40px 12px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.eventMediaGallaryOuterWrapper .eventMediaListNew .eventMediaList {
  padding: 24px 0;
  max-width: 312px;
  width: 100%;
  margin: 0 auto;
}
.eventMediaGallaryOuterWrapper .eventMediaListNew .eventMediaList .imageuplode {
  /* border-radius: 0; */
}
.eventMediaGallaryOuterWrapper
  .eventMediaListNew
  .eventMediaList
  .imageuplode
  #blah1,
.eventMediaGallaryOuterWrapper
  .eventMediaListNew
  .eventMediaList
  .imageuplode
  .imgMask,
.eventMediaGallaryOuterWrapper .eventMediaListNew .eventMediaList .imageuplode {
  height: 144px;
}
.eventMediaGallaryOuterWrapper
  .eventMediaListNew
  .eventMediaList
  .imageuplode
  #blah1 {
  border-radius: 0;
}
.eventMediaGallaryOuterWrapper .eventMediaListNew .eventMediaList .lablepoint {
  padding-bottom: 12px;
}
.eventMediaGallaryOuterWrapper
  .eventMediaListNew
  .eventMediaList
  .lablepoint
  .title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.eventMediaGallaryOuterWrapper
  .eventMediaListNew
  .eventMediaList
  .lablepoint
  .buticon
  .iconBtn {
  color: #666;
  margin-left: 16px !important;
  width: 20px;
  min-width: 20px;
  height: 20px;
}
.eventMediaGallaryOuterWrapper .eventMediaListNew .eventMediaList .caption {
  padding-top: 24px;
}
.eventMediaGallaryOuterWrapper .eventMediaListNew .eventMediaList .caption p {
  margin-bottom: 12px;
}

.lablepoint .title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.organizationListWrapper {
  max-width: 589px;
  width: 100%;
  margin: 96px auto;
  padding: 0 12px;
}
.organizationInnerWrapper {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 24px;
}
.organizationInnerWrapper .organizationListTop {
  padding-bottom: 12px;
}
.organizationInnerWrapper .organizationListTop .organizationListSuperUser {
  color: #fc3158;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.organizationInnerWrapper .organizationListTop .organizationListLogo {
  color: #49454f;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
}
.organizationInnerWrapper .organizationListTop .organizationListLogo img {
  width: 36px;
  height: 36px;
  padding: 4px;
}

.organizationInnerWrapper .organizationListheader {
  color: #49454f;
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
  padding: 8px 36px;
  gap: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.organizationInnerWrapper .img {
  width: 56px;
  height: 56px;
  object-fit: contain;
}
.organizationInnerWrapper .organizationListSelectAccount {
  padding: 12px 24px;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
  margin-bottom: 12px;
}
.organizationInnerWrapper .organizationListSearchWrapper {
  padding-bottom: 12px;
}
.organizationInnerWrapper .organizationListSearchWrapper .form-group {
  padding: 18px 12px;
}
.organizationInnerWrapper .organizationListSearch {
  height: 296px;
  overflow-y: auto;
  padding: 0 12px;
}
.organizationInnerWrapper .organizationListSearch .organizationListSearchList {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease;
}
.organizationInnerWrapper
  .organizationListSearch
  .organizationListSearchList.active,
.organizationInnerWrapper
  .organizationListSearch
  .organizationListSearchList:hover {
  background: rgba(254, 203, 46, 0.2);
  transition: all 0.3s ease;
}
.organizationInnerWrapper
  .organizationListSearch
  .organizationListSearchList
  .openAccountBtn {
  padding: 8px 16px;
  color: #1d192b;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  border-radius: 100px;
  background: rgba(198, 193, 203, 0.2);
  height: 40px;
  border: none;
}

.avatarCardBox {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;
  width: fit-content;
}

.avatarCardBox .avatarCardPic {
  min-width: 32px;
  width: 32px;
  height: 32px;
}

.avatarCardBox .avatarCardPic .pic {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}

.avatarCardBox .avatarCardBody .title {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #1f1f1f;
}

.avatarCardBox .avatarCardBody .subTitle {
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.4px;
  color: #1f1f1f;
}

.specifyLocation.inlineFormBox,
.intractiveMap .inlineFormBox {
  flex-wrap: wrap;
}

.form-error,
.form-success {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.form-success {
  color: #198754;
}
.form-error {
  color: #dc3545;
}

.completeAutoWrapper .cardBox .formBox {
  margin-bottom: 0 !important;
}

.eventConfigurationModal {
  max-width: 856px;
}
.eventConfigurationModal .modal-content {
  border-radius: 12px;
  background: #fdfdfd;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.eventConfigurationModal .modal-header {
  padding: 24px;
}
.eventConfigurationModal .modal-header h2 {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin: 0;
  gap: 12px;
  display: flex;
  align-items: center;
}
.eventConfigurationModal .modal-header h2 img {
  width: 48px;
  height: 48px;
  object-fit: contain;
}
.eventConfigurationModal .modal-body {
  padding: 0 24px 24px 24px;
}
.eventConfigurationModal .modal-body h4 {
  color: #1c1b1f;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  margin-bottom: 12px;
}
.eventConfigurationModal .modal-footer {
  padding: 36px 0 12px 0;
  justify-content: flex-start;
}
.eventConfigurationModal .modal-footer .btn {
  height: 40px !important;
  transform: none !important;
  display: flex;
  align-items: center;
}

.eventConfigurationFormWrapper {
  display: grid;
  grid-template-columns: 348px 1fr;
  gap: 24px;
}

.eventConfigurationModal .react-datepicker__input-container input {
  border: 1px solid #d7d7d7;
  border-radius: 6px;
  background: #ffffff;
  height: 48px;
  padding: 12px;
  font-weight: 300 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  letter-spacing: 0.4px !important;
  color: #1f1f1f !important;
}

.eventConfigurationFormWrapper .eventConfigurationFormLeft .form-group {
  padding: 18px 12px;
}

.SelectStoryzEvent .eventConfigurCard {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;
  transition: all 0.3s ease;
}
.SelectStoryzEvent .eventConfigurCard:hover {
  background: rgba(198, 193, 203, 0.2);
  transition: all 0.3s ease;
}
.SelectStoryzEvent .eventConfigurCard .date {
  color: #1f1f1f;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.SelectStoryzEvent .eventConfigurCard .storysImg {
  width: 48px;
  height: 48px;
}
.SelectStoryzEvent .eventConfigurCard .storysImg .stimage {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}
.SelectStoryzEvent .eventConfigurCard .titlee {
  color: #1f1f1f;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
  margin: 0;
}
.SelectStoryzEvent .eventConfigurCard .subtitle {
  color: #1f1f1f;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 12px;
  letter-spacing: 0.4px;
  margin: 0;
}

.eventConfigurationFormWrapper
  .eventConfigurationFormRight
  .eventConfigureDateWrapper {
  display: grid;
  grid-template-columns: 1fr 90px 100px;
  gap: 10px;
}

.eventTodoTableNew .react-bootstrap-table {
  margin: 0 0 10px 0;
}
.eventTodoTableNew .react-bootstrap-table .table thead {
  display: none;
}

.eventDeleteModal .modal-content {
  border-radius: 12px;
  background: #fdfdfd;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 24px;
  border: none;
  max-width: 555px;
  width: 100%;
}
.eventDeleteModal p {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
}
.eventDeleteModal .head {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 16px;
}
.eventDeleteModal .head .icon {
  width: 88px;
  height: 88px;
  object-fit: contain;
  margin-bottom: 12px;
}
.eventDeleteModal .head h2 {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  padding-bottom: 8px;
  margin-bottom: 0;
}
.eventDeleteModal .modal-footer {
  padding: 4px 0;
  display: flex;
  align-items: center;
  margin: 0 -4px;
}

.addInviteBox {
  padding: 10px 16px 10px 8px;
  display: flex;
}
.addInviteBox .todoIconWrapper {
  margin-left: auto;
}
.inviteAdminTableWrapper .table-responsive {
  overflow-x: auto;
}
.inviteAdminTableWrapper table .list td,
.inviteAdminTableWrapper table .thead-light th {
  width: 25%;
  padding: 10px 24px 10px 8px;
}
.inviteAdminTableWrapper table .thead-light th {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-transform: inherit;
}

.inviteAdminTableWrapper .table {
  border-right: 0;
}
.inviteAdminTableWrapper table .list td {
  color: #49454f;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.inviteAdminTableWrapper table .budget .imagein {
  width: 24px;
  height: 24px;
  border: none;
}
.inviteAdminTableWrapper table .list td select.form-control {
  min-width: 160px;
}
.inviteAdminTableWrapper table .budget {
  color: #666 !important;
  font-size: 12px !important;
}
.inviteAdminTableWrapper .table .thead-light [data-sort]::after {
  content: url(./../../../img/sortIcon.svg);
}

/* .inviteNewAdminModal.modal-dialog {
  max-width: 1108px;
} */
.inviteNewAdminModal .modal-content {
  border-radius: 12px;
  border: 1px solid #e7e7e7;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 24px;
}
.inviteNewAdminModal .modal-header {
  padding: 0 0 8px 0;
  margin-bottom: 24px;
}
.inviteNewAdminModal .modal-header .modal-title {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  margin: 0;
}
.inviteNewAdminModal .modal-header .modal-title img {
  width: 88px;
  height: 88px;
  object-fit: contain;
  margin-right: 12px;
}
.inviteNewAdminModal .modal-header .close span {
  font-weight: 300;
  font-size: 24px;
}
.inviteNewAdminModal .modal-body {
  padding: 0;
}
.inviteNewAdminModal .modal-body p {
  color: #1f1f1f;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.inviteNewAdminModal .teamRoleWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 20px;
}
.inviteNewAdminModal .teamRoleWrapper .form-control {
  border-radius: 6px;
  background: rgba(207, 207, 207, 0.15);
  border: none;
}
.inviteNewAdminModal .inviteFormWrapper .form-control-label {
  color: #666 !important;
  font-size: 12px !important;
}
.inviteNewAdminModal .roleTeamOuter {
  padding: 12px 40px;
}

.grayBtnDisabled:hover,
.grayBtnDisabled {
  color: #000;
  font-size: 14px;
  font-weight: 300;
  margin: 0;
  white-space: nowrap;
  padding: 4px 12px;
  line-height: 24px;
  border-radius: 8px;
  letter-spacing: 0.4px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: rgba(83, 215, 105, 0.1);
  height: 32px;
  width: fit-content !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  transform: none;
  box-shadow: none;
}
.grayBtnDisabled img {
  width: 16px;
  height: 16px;
  min-width: 16px;
  object-fit: contain;
}
.grayBtnDisabled.disabled,
.grayBtnDisabled:disabled {
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: #e7e7e7;
  color: #979798;
  opacity: 1;
}

.organizationInnerWrapper .organizationListHeadSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  color: #49454f;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.4px;
  padding: 0 12px 12px;
  margin-bottom: 12px;
}
.organizationInnerWrapper p {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
  margin-bottom: 12px;
  padding: 0 6px;
}
.organizationInnerWrapper .organizationListHead {
  display: flex;
  align-items: center;
  gap: 12px;
}
.organizationInnerWrapper .logOutSec {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  cursor: pointer;
}

.guestRecordAvatar {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
}
.guestRecordAvatar .img {
  width: 60px;
  height: 60px;
}
.guestRecordAvatar .img img {
  border-radius: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.guestRecordAvatar .title {
  color: #49454f;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.4px;
}

.guestRecordAvatar .subTitle {
  color: #49454f;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.4px;
}

.greenActionBtn:hover,
.greenActionBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 16px 4px 12px;
  background: rgba(83, 215, 105, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  width: fit-content;
  height: 32px;
  color: #000 !important;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
  gap: 8px;
  cursor: pointer;
}
.guestRecordCradWrapper {
  padding: 12px 4px;
  flex-wrap: wrap;
}
.guestRecordCradWrapper .greenActionBtn {
  margin-bottom: 10px;
}
.timeZoneWrapper .accountname2Client1 {
  padding: 0;
  width: fit-content;
}
.timeZoneWrapper .inlineFormBox {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
.teamRoleClient {
  background: rgba(207, 207, 207, 0.15);
  font-size: 14px;
  font-weight: 400;
  width: fit-content;
  height: 40px;
  padding: 8px 12px;
  border-radius: 6px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #666;
  min-width: 180px;
}
.aboutTeamRoleWrapper label {
  color: #666 !important;
  font-size: 12px !important;
}
.aboutInfoWrapper .basicinformation {
  margin-bottom: 12px;
}
.AboutAuthenticationWrapper {
  gap: 0;
  margin-bottom: 0;
  padding: 12px 24px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.AboutAuthenticationWrapper .AboutAuthenticationList {
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: wrap;
}
.aboutLogoutWrapper {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.aboutLogoutWrapper .aboutLogoutList {
  display: grid;
  grid-template-columns: 1fr 280px;
  gap: 12px;
}
.aboutLogoutWrapper .logoutFromDeviceWraper {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
}

.aboutInfoWrapper .aboutInfoBox {
  padding: 12px;
}
.aboutDeleteAccount {
  display: grid;
  grid-template-columns: 1fr 200px;
  gap: 12px;
}
.aboutDeleteAccount .aboutDeleteAccountList {
  display: flex;
  justify-content: flex-end;
}

.changePasswordModal.modal-dialog {
  max-width: 380px;
}
.changePasswordModal .modal-content {
  border-radius: 12px;
  background: #fdfdfd;
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  /* padding: 24px; */
  border: none;
  width: 100%;
}
.changePasswordModal .head {
  display: flex;
  justify-content: space-between;
}
.changePasswordModal .modal-header {
  padding-bottom: 12px;
}
.changePasswordModal .modal-header .close {
  padding: 12px;
}
.changePasswordModal h6 {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  margin-bottom: 0;
}
.basicInfoAvatar {
  display: flex;
  gap: 12px;
  padding: 4px;
}
/* .basicInfoAvatar .basicInfoAvatarImg{
  width: 120px;
  height: 120px;
} */
.basicInfoAvatar .basicInfoAvatarImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid rgb(231, 231, 231);
}
.basicInfoAvatar .basicInfoAvatarImg .plusIcon {
  font-size: 40px;
  left: 50%;
  opacity: 0.5;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.basicInfoAvatar .basicInfoAvatarImg .img {
  min-width: 120px;
  min-height: 120px;
}
.basicInfoAvatar .accountTypeName {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.6px;
  padding-top: 4px;
  text-align: center;
}
.basicInfoAvatar .basicInfoAvatarContent .basicInfoMediaIcon {
  margin-bottom: 12px;
}
.basicInfoAvatar .basicInfoAvatarContent .basicInfoMediaIcon img {
  width: 34px;
  height: 34px;
  object-fit: contain;
  padding: 4px;
}
.aboutInfoBox .basicInfoFormName {
  display: grid;
  grid-template-columns: 160px 1fr;
  gap: 8px 32px;
}
.aboutInfoBox .basicInfoFormName.reverse {
  grid-template-columns: 1fr 160px;
}
.aboutInfoBox .basicInfoFormName .input-group-append .input-group-text {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 1px solid #d7d7d7;
}
.filterWrapper {
  padding: 24px 0;
}
.filterWrapper .filterInnerWrapper {
  padding: 0 0 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filterWrapper .filterInnerWrapper .filterList {
  display: flex;
  align-items: center;
}
.filterWrapper .filterInnerWrapper .filterList .search-label {
  margin-bottom: 0;
}
.tableResponsiveWrapper .react-bootstrap-table {
  margin: 0 0 10px 0;
}

.webAccessInfoWrapper p {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.webListingCustomUrl.inlineFormBox {
  flex-wrap: wrap;
}

.webSearchPreviewBox {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}
.webSearchPreviewBox .webSearchPreviewList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.webSearchPreviewBox .webSearchPreviewList .title {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  margin-bottom: 12px;
}
.webSearchPreviewBox .webSearchPreviewList img {
  max-height: 72px;
  max-width: 186px;
  object-fit: contain;
}

.webAccessModal.modal-dialog {
  max-width: 505px;
  width: 100%;
}
.webAccessModal .modal-content {
  border-radius: 12px;
  border: 1px solid #e7e7e7;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 24px;
}
.webAccessModal .modal-header {
  display: flex;
  justify-content: center;
  padding: 0 0 12px 0;
  margin-bottom: 16px;
}
.webAccessModal .modal-title {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  margin: 0;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.webAccessModal .modal-title img {
  max-width: 88px;
  max-height: 88px;
  margin-bottom: 12px;
}
.webAccessModal .modal-body {
  padding: 0;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.webAccessModal .webAccessWebsiteBox {
  display: grid;
  grid-template-columns: 1fr 140px;
  gap: 12px;
  align-items: center;
}

.webAccessModal p {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.webAccessModal .modal-footer {
  padding: 16px 0 24px;
}
.desktopWebAccessModal.modal-dialog {
  max-width: 910px;
}
.desktopWebAccessModal .modal-header {
  border-radius: 12px 12px 0px 0px;
  background: #286bef;
  padding: 12px;
}
.desktopWebAccessModal .modal-header .close {
  margin: 0;
  padding: 0;
  opacity: 1;
}
.desktopWebAccessModal .modal-header .close span {
  color: #fff;
}
.desktopWebAccessModal .modal-content {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.desktopWebAccessModal .modal-body {
  padding: 12px 12px 24px;
}
.webListingDesktopOuter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.webListingDesktopWrapper {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 0 12px;
}
.webListingDesktopWrapper .webListingImg {
  width: 90px;
  min-width: 90px;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-align: center;
}
.webListingDesktopWrapper .webListingImg img {
  width: 90px;
  height: 90px;
  border-radius: 100%;
  object-fit: cover;
  padding: 12px;
}
.webListingDesktopWrapper .webListingContent {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
}
.webListingDesktopWrapper .webListingContent .title {
  color: #000;
  font-family: "Great Vibes";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 8px;
}
.webListingDesktopWrapper .webListingContent div {
  margin-bottom: 4px;
}
.webListingDesktopWrapper .webListingContent span {
  font-weight: 700;
}
.desktopWebStoreWrapper {
  padding: 0 12px;
}
.desktopWebStoreWrapper .socialIconWrapper {
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
  align-items: center;
}
.desktopWebStoreWrapper .socialIconWrapper .socialIcon {
  margin: 0;
}

.webListingDesktopOuter .webAppStore {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-decoration-line: underline;
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
}
.mobileWebAccessModal.modal-dialog {
  max-width: 360px !important;
}
.mobileWebAccessModal .webListingDesktopOuter {
  flex-direction: column;
}
.mobileWebAccessModal .webListingDesktopOuter .webListingDesktopWrapper {
  border-bottom: solid 1px #979798;
  padding: 0 0 12px;
  margin-bottom: 24px;
}
.mobileWebAccessModal .webListingDesktopOuter .desktopWebStoreWrapper {
  padding: 0;
}
.mobileWebAccessModal .socialIconWrapper {
  margin-bottom: 24px;
}
.mobileWebAccessModal .socialIconWrapper {
  gap: 20px;
}

.dashboardLoadingWrapper {
  position: absolute;
  height: 180px;
  width: 180px;
  left: 50%;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.reportFormWrapper {
  padding: 12px 200px 12px 12px;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
}
.reportFormWrapper .reportFromList {
  display: flex;
  gap: 12px;
}
.reportFormWrapper .reportFromList .form-group {
  max-width: 190px;
  width: 100%;
}
.reportFormWrapper .reportFromList .form-group label {
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 18px !important;
  letter-spacing: 0.1px !important;
  color: #000 !important;
}
.react-bootstrap-table {
  max-height: 500px;
  overflow: auto;
  min-height: 200px;
}

/* .react-bootstrap-table{
  position: sticky;
    top: 0;
    background: red;
    opacity: 1;
    z-index: 9;
    box-shadow: 0 1px #000;
} */

.reportListGenratedWrapper {
  padding: 24px 12px 12px;
  display: flex;
  gap: 24px;
}
.reportListGenratedWrapper h5 {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  margin-bottom: 12px;
}
.reportListGenratedList {
  color: #1f1f1f;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
}
.reportListGenratedList img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.reportListManuallyWrapper .custom-control {
  margin-bottom: 8px;
}

.guestRadioMessageWraper {
  margin-bottom: 12px;
}

.adminMsgWrapper {
  padding: 12px;
  margin-top: 12px;
}
.adminMsgList h5 {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
  padding-bottom: 12px;
  margin-bottom: 24px;
}
.adminMsgList p {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.broadMsgAlertWrapper {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
  border-radius: 12px;
  background: rgba(255, 139, 133, 0.1);
  padding: 12px;
}
.websitenInPublicListWrapper .custom-control {
  margin-bottom: 12px;
}
.globalSettingListWrapper {
  padding: 12px;
}
.globalSettingListWrapper p {
  color: #1f1f1f;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.settingUnitsWrapper {
  padding-left: 24px;
}
.reportDataListWrapper {
  margin: 24px 0 60px;
}
.reportDataListWrapper .head {
  background: rgba(207, 207, 207, 0.15);
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  padding: 12px 12px 10px 12px;
  border-bottom: solid 1px #e7e7e7;
  margin-bottom: 12px;
}
.reportDataListWrapper .table {
  padding: 0 12px;
  border-right: none;
}
.reportDataListWrapper .table th {
  padding: 10px 0 10px 12px;
  vertical-align: middle;
  color: #1f1f1f;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.reportDataListWrapper .table th .heading {
  color: #1f1f1f;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  gap: 12px;
  display: flex;
  align-items: center;
  text-transform: none;
}
.reportDataListWrapper .table th .sortIcon {
  margin-left: auto;
  padding: 0 0 0 12px;
}
.reportDataListWrapper .table td {
  color: #1f1f1f;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  padding: 10px 0 10px 12px;
  vertical-align: middle;
  border: none;
}

.reportDataListWrapper .table td .date {
  display: flex;
  align-items: center;
  gap: 12px;
}
.reportDataListWrapper .table td .date img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.reportDataListWrapper .table td .closeIcon {
  margin-left: auto;
  cursor: pointer;
  display: flex;
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.reportDataListWrapper .table th:nth-of-type(1) {
  width: 230px;
  padding-left: 24px;
}
.reportDataListWrapper .table th:nth-of-type(2) {
  flex-grow: 1;
}
.reportDataListWrapper .table th:nth-of-type(3) {
  padding-right: 24px;
}
.reportDataListWrapper .table td:nth-of-type(1) {
  padding-left: 24px;
}
.reportDataListWrapper .table td:nth-of-type(3) {
  padding-right: 24px;
}

.mesageBoxWrapper {
  padding: 0 36px 24px;
  position: relative;
  z-index: 1;
}
.storyzMesageBoxWrapper {
  padding: 0 12px 24px;
  position: relative;
  z-index: 1;
}
.keepsakeImgBoxWrapper .coverimagestsp {
  padding-bottom: 12px;
}
.keepsakeImgBoxWrapper .coverimagestsp .coverimage1 {
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.keepsakeImgBoxWrapper .imgMaskevente {
  max-width: 360px;
}
.keepsakeImgBoxWrapper .imgMaskevente .storyt-oimage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 1;
}
.storyzListBox {
  display: flex;
  margin-bottom: 12px;
  padding: 4px 8px;
  gap: 8px;
}
.storyzListBox .eventimag {
  width: 136px !important;
  height: 90px;
  margin: 0 !important;
  object-fit: cover;
  border-radius: 12px !important;
}
.storyzListBox .title {
  margin-bottom: 4px;
  color: #1f1f1f;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 0.4px;
}
.storyzListBox .subtitle {
  margin-bottom: 4px;
  color: #1f1f1f;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.6px;
}
.storyzListBox .subtitle1 {
  margin-bottom: 4px;
  color: #1f1f1f;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.storyzListBox .description {
  margin-bottom: 4px;
  color: #1f1f1f;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.6px;
}
.currentPlanWrapper {
  /* flex-grow: 1; */
  margin-bottom: 0;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
}
.currentPlanWrapper h2 {
  color: #fdfdfd;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 115%;
  letter-spacing: -0.96px;
  margin: 0 0 14px 0;
}
.currentPlanWrapper .currentPlanBox {
  max-width: 200px;
  width: 100%;
  text-align: center;
}
.currentPlanWrapper .currentPlanBox .price {
  color: #fdfdfd;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.48px;
  font-family: "Plus Jakarta Sans";
  padding: 4px;
}
.currentPlanWrapper .currentPlanBox .category {
  color: #fdfdfd;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  font-family: "Plus Jakarta Sans";
  padding: 4px;
}
.currentPlanWrapper .currentPlanBox .desc {
  color: #fdfdfd;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  font-family: "Plus Jakarta Sans";
  padding: 4px;
}

.nextPaymentPlanWraper {
  border-radius: 8px;
  border: 1px solid #d7d7d7;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.1);
  /* flex-grow: 1; */
  margin-bottom: 0;
  width: 100%;
}

.nextPaymentPlanWraper .h1 {
  color: #666;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 115%;
  letter-spacing: -0.96px;
  margin: 0;
}
.nextPaymentPlanWraper .date {
  color: #666;
  font-family: "Plus Jakarta Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.paymentMethodWrapper {
  border-radius: 8px;
  max-width: 220px;
  width: 100%;
  margin-bottom: 14px;
  /* background: #F8F8F8; */
}
.paymentMethodWrapper .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.orderSummaryWrapper {
  padding: 18px;
  border-radius: 8px;
  border: 1px solid #d7d7d7;
  background: #fff;
  max-width: 220px;
  width: 100%;
}

.orderSummaryWrapper h2 {
  color: #666;
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 10px;
}
.orderSummaryWrapper .orderSummaryList {
  color: #666;
  font-family: "Plus Jakarta Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.orderSummaryWrapper .orderSummaryList span {
  font-weight: 600;
  display: inline-block;
}

.GuestcreateLoginWrappper {
  padding: 24px;
  min-height: 82vh;
  background: #fff;
}

.GuestcreateLoginHeaderWrappper {
  background: #2053b5;
  padding: 30px;
  display: flex;
  align-items: center;
  gap: 12px;
  color: #fdfdfd !important;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  margin: 0;
}
.GuestcreateLoginHeaderWrappper .arrowIcon {
  color: #fff;
  display: flex;
  align-items: center;
}

.borderLine {
  background: #e7e7e7;
  height: 1px;
  width: 100%;
}

.GuestcreateLoginInner {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 24px;
}

.existingGuestLoginBox {
  border-radius: 12px;
  background: rgba(83, 215, 105, 0.1);
  padding: 12px 36px;
  max-width: 470px;
  width: 100%;
  margin: 0 24px 24px 24px;
}
.existingGuestLoginBox .title {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.4px;
  margin-bottom: 12px;
}
.existingGuestLoginBox .inlineFormBox {
  align-items: center;
}
.existingGuestLoginBox .avatarWraper {
  width: 75px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.existingGuestLoginBox .avatar {
  width: 24px;
  height: 24px;
  min-width: 24px;
  border-radius: 100%;
  object-fit: cover;
}
.existingGuestLoginBox label {
  font-size: 16px !important;
}
.existingGuestLoginBox .form-control {
  background: transparent;
  height: 40px;
}

.existingGuestLoginWrapper {
  padding: 36px 24px 24px 24px;
  display: flex;
  position: relative;
}
.existingGuestLoginWrapper .localPassBox .form-control {
  border-right: none;
}
.existingGuestLoginWrapper .localPassBox .input-group-text {
  border-left: none;
}
.existingGuestLoginWrapper .existingGuestLoginLeft {
  width: 100%;
  padding-right: 24px;
}
.existingGuestLoginWrapper .existingGuestLoginRight {
  width: 100%;
  padding-left: 24px;
}

.orBox {
  width: 28px;
  position: absolute;
  left: 50%;
  height: calc(100% - 60px);
  top: 36px;
}
.orBox::after {
  position: absolute;
  content: "";
  width: 1px;
  background: #979798;
  height: 100%;
}
.orBoxtext {
  height: 60px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 8px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.guestLoginWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 28px 0;
  margin: 0 auto;
  max-width: 400px;
  width: 100%;
}
.loginWithFacebook:hover,
.loginWithGoogle:hover,
.loginWithApple:hover,
.loginWithFacebook,
.loginWithGoogle,
.loginWithApple {
  border-radius: 8px;
  display: flex;
  height: 36px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fdfdfd;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  box-shadow: none;
  transform: none;
  max-width: 400px;
  width: 100%;
}
.loginWithApple:hover,
.loginWithApple {
  background: #1f1f1f;
}
.loginWithFacebook:hover,
.loginWithFacebook {
  background: #3b5998;
}
.loginWithGoogle:hover,
.loginWithGoogle {
  border: 1px solid #979798;
  background: #fdfdfd;
  color: #1f1f1f;
}

.termConditionWrapper {
  padding: 24px;
  min-height: 82vh;
  background: #2053b5;
}
.termConditionTopWrapper {
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: none;
  margin-bottom: 12px;
}
.termConditionMsgWrapper {
  padding: 12px;
  border-radius: 12px;
  background: rgba(83, 215, 105, 0.1);
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
  max-width: 740px;
  width: 100%;
}
.termConditionContentWrapper {
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: none;
  margin-bottom: 12px;
}
.termConditionContentWrapper h1 {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 24px 0;
}
.termConditionContentWrapper p {
  color: #000;
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.32px;
}
.termConditionContentWrapper .termConditionOverflow {
  height: 80vh;
  overflow-y: auto;
  padding: 0 24px;
  margin: 0;
}
.termsModal.modal-dialog {
  max-width: 360px;
}
.termsProceedWraper {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.termsProceedWraper img {
  max-width: 48px;
  width: 100%;
}
.termsModal .modal-footer {
  padding-top: 4px;
  padding-bottom: 28px;
}

.aboutAccountInfo {
  display: flex;
  align-items: center;
}
.aboutAccountInfo .title {
  color: #1c1b1f;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.aboutAccountInfo .subTitle {
  color: #1c1b1f;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}
.aboutAccountInfo .img {
  margin-right: 16px;
}
.gotoBillingBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
}
.gotoBillingBtn img {
  margin-bottom: 4px;
}
.gotobilling {
  font-size: 12px;
  background: rgba(198, 193, 203, 0.2);
  padding: 6px 16px;
  font-weight: 500;
  height: 39px;
  border-radius: 100px;
  color: #1d192b;
  text-align: center;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.1px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.time-line-container .timeLine-main-data-viewPort,
.time-line-container .timeLine-main-header-viewPort {
  overflow: unset;
}

.notificationTableWrapper .table {
  border-right: none;
}
.notificationTableWrapper .table th {
  color: #1f1f1f;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-transform: none;
  position: relative;
}
.notificationTableWrapper .table th:nth-of-type(2) {
  width: 260px;
}
.notificationTableWrapper .table th:nth-of-type(2) span,
.notificationTableWrapper .table th:nth-of-type(3) span {
  position: relative;
  left: 50px;
  top: 20px;
}

.notificationTableWrapper .table th:nth-of-type(3) {
  width: 70px;
}

.react-bootstrap-table .caret-4-desc:before,
.react-bootstrap-table .caret-4-desc:after,
.react-bootstrap-table .caret-4-asc:before,
.react-bootstrap-table .caret-4-asc:after,
.react-bootstrap-table .order-4:before,
.react-bootstrap-table .order-4:after {
  content: "";
  width: 20px;
  height: 20px;
  background-size: contain;
  background-position: center;
  right: 1em;
  opacity: 1;
  cursor: pointer;
}
.react-bootstrap-table .caret-4-desc:before {
  background: url(./../../../img/sortIconDesc.svg) no-repeat;
}
.react-bootstrap-table .caret-4-desc:after {
  background: url(./../../../img/sortIconDesc.svg) no-repeat;
}
.react-bootstrap-table .caret-4-asc:before {
  background: url(./../../../img/sortIconAsce.svg) no-repeat;
}
.react-bootstrap-table .caret-4-asc:after {
  background: url(./../../../img/sortIconAsce.svg) no-repeat;
}
.react-bootstrap-table .order-4:before {
  background: url(./../../../img/sortIconNormal.svg) no-repeat;
}
.react-bootstrap-table .order-4:after {
  background: url(./../../../img/sortIconNormal.svg) no-repeat;
}

/* .notificationTableWrapper .table .thead-light [data-sort]::after {
  content: url(./../../../img/sortIcon1.svg);
  font-size: 24px;
  margin-left: 12px;
  vertical-align: middle;
  position: absolute;
  right: 8px;
} */

.notificationTableWrapper .table .thead-light [data-sort]:nth-child(2):after {
  left: 90px;
  right: inherit;
}

.notificationTableWrapper .table td,
.notificationTableWrapper .table td.budget {
  color: #1f1f1f;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  transform: none;
}
.notificationTableWrapper .table td:nth-of-type(1) {
  width: 230px;
}
.upgrateRightSection {
  max-width: 350px;
  width: 100%;
}

.eventSidebarPopup .story1 img {
  width: 79px !important;
  height: 53px !important;
  max-width: 79px;
  max-height: 53px;
}

.removeLoginBtn.disabled {
  border-radius: 8px;
  border: 2px solid rgba(0, 0, 0, 0.05);
  background: #e7e7e7;
  height: 32px;
  padding: 4px 12px;
  color: #979798;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.removedLoginModal .modal-content {
  max-width: 464px;
  width: 100%;
  border: none;
}

.deleteLoginModal .modal-content {
  max-width: 464px;
  width: 100%;
  border: none;
}

.removedLoginModal p {
  color: #49454f;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.uploadSelectTypeModal.modal-dialog {
  width: 100%;
  max-width: 800px;
}
.uploadSelectTypeModal .modal-content {
  padding: 30px;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.uploadSelectTypeModal .modal-header {
  padding: 0 0 16px 0;
  margin-bottom: 24px;
  justify-content: center;
}
.uploadSelectTypeModal .modal-header .modal-title {
  color: #333;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.8px;
}
.uploadSelectTypeModal .modal-body {
  padding: 0;
}
.uploadSelectTypeModal .modal-body h2 {
  color: #828282;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: -0.64px;
  margin-bottom: 20px;
}
.chooseImportSourceWrapper {
  display: flex;
  gap: 10px;
}
.chooseImportSourceWrapper .chooseImportSourceLeft {
}
.chooseImportSourceWrapper .chooseImportSourceRight {
}

.canelTextBtn:hover,
.canelTextBtn {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: -0.64px;
  padding: 0 !important;
  box-shadow: none;
  background: none;
  transform: none;
  width: fit-content !important;
}
.chooseImportSourceWrapper .chooseImportBox {
  padding: 15px;
  border: solid 1px #d9e7f8;
  border-radius: 12px;
  width: 100%;
  text-align: center;
}
.choosImportContentWraper {
  padding: 0;
}
.choosImportContentWraper h3 {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.56px;
  margin-bottom: 10px;
}
.choosImportContentWraper p {
  color: #828282;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.48px;
  margin-bottom: 10px;
}
.uploadBtn:active,
.uploadBtn:hover,
.uploadBtn {
  padding: 5px 10px;
  border-radius: 12px;
  background: #d9e7f8;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.56px;
  transform: none;
  box-shadow: none;
}

.chooseImportDocWrapper {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chooseImportDocWrapper .chooseImportDoc {
  width: 60px;
  height: 90px;
  padding-top: 16px;
}
.chooseImportDocWrapper .chooseImportDoc .icon {
  width: 36px;
  height: 36px;
  object-fit: contain;
  margin-bottom: 4px;
}
.chooseImportDocWrapper .chooseImportDoc .docName {
  color: #828282;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.48px;
}
.listAnotherServicesWraper .listAnotherServices {
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.listAnotherServicesWraper .listAnotherServices .icon {
  max-width: 36px;
  max-height: 36px;
  margin: 0 5px;
}
.listAnotherServicesWraper .listAnotherServices .icon.check {
  max-width: 24px;
}
.uploadSelectTypeModal .modal-footer {
  padding: 20px 12px 0 12px;
}

.webListingUpdateBtn {
  top: 0;
  margin: 0 auto -80px;
}
.webListingUpdateBtn .customButtonWrapper {
  margin: 0;
}
.saveChangesBtn:active,
.saveChangesBtn:hover,
.saveChangesBtn {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: #286bef;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 16px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  height: 40px;
  box-shadow: none;
  transform: none;
}

.saveChangesBtn .icon {
  font-size: 16px;
  color: #fff;
  width: 16px;
  height: 16px;
  object-fit: contain;
}
.deleteBtn.disabled,
.deleteBtn:disabled,
.saveChangesBtn.disabled,
.saveChangesBtn:disabled {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: #e7e7e7 !important;
  color: #979798;
  opacity: 1;
}
.deleteBtn.disabled .icon,
.deleteBtn:disabled .icon,
.saveChangesBtn.disabled .icon,
.saveChangesBtn:disabled .icon {
  color: #979798;
}

.deleteBtn:active,
.deleteBtn:hover,
.deleteBtn {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: #fff;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 16px;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  height: 40px;
  box-shadow: none;
  color: #000;
  border-radius: 8px;
  border: 1px solid #d7d7d7;
  transform: none;
}
.deleteBtn .icon {
  font-size: 16px;
  color: #000;
  width: 16px;
  height: 16px;
  object-fit: contain;
}
.deleteBtn.disabled,
.deleteBtn:disabled {
}
.deleteBtn.disabled .icon,
.deleteBtn:disabled .icon {
}

.authenticationCard {
  display: flex;
  align-items: center;
}
.authenticationCard .backArrow {
  font-size: 24px;
  margin-right: 12px;
}
.authenticationCard .img {
  width: 56px;
  height: 56px;
  margin-right: 12px;
}
.authenticationCard .img img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}
.authenticationCard .content {
}
.authenticationCard .content .title {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.4px;
  margin-bottom: 4px;
}
.authenticationCard .content .role {
  color: #1f1f1f;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.6px;
}

.authenCardWrapper {
}
.qrCodeList label,
.authenCardWrapper label {
  color: #666 !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0.4px !important;
}

.qrCodeCard {
  display: flex;
  align-items: center;
  padding: 0 12px;
}

.qrCodeCard .img {
  width: 100px;
  height: 100px;
  margin-right: 12px;
}
.qrCodeCard .img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.qrCodeCard .content {
}
.qrCodeCard .content .title {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.4px;
  margin-bottom: 4px;
}
.qrCodeCard .content .role {
  color: #666;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.authenDocWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}
.authenDocWrapper .authenDocList {
  width: 100px;
}
.authenDocWrapper .authenDocList .doc {
  width: 48px;
  height: 62px;
  background: #d7d7d7;
  margin: 0 auto 12px;
}
.authenDocWrapper .authenDocList .text {
  color: #666;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.idCardWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}
.idCardWrapper .title {
  margin-bottom: 8px;
}
.idCardWrapper .date {
  color: #666;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.idCard {
  padding: 12px;
  border-radius: 24px;
  border: 1px solid #e0e0e0;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 130px;
  display: flex;
}
.idCard .img {
  width: 52px;
  height: 52px;
}
.idCard .img img {
}
.idCard .text {
}

.authenDocWrapper.disabled,
.idCardWrapper.disabled {
  opacity: 0.5;
  background: none;
  pointer-events: none;
}

.aboutMediaWrapper {
}
.aboutMediaWrapper .aboutMediaLeft {
  max-width: 272px;
  padding: 12px;
}
.aboutMediaWrapper .aboutMediaRight {
  padding: 12px;
  max-width: 500px;
  width: 100%;
}

.selectUploadDownloadModal .modal-title {
  color: #333;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.8px;
}
.selectUploadDownloadModal .listOption {
  margin-bottom: 12px;
  padding-left: 24px;
}
.selectUploadDownloadModal .listOption li {
  color: #828282;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: -0.64px;
}
.selectUploadDownloadModal .modal-dialog {
  max-width: 710px;
}
.selectUploadDownloadModal .modal-dialog .modal-content {
  border-radius: 24px;
  border: 1px solid #ededed;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.selectUploadDownloadModal .modal-header {
  padding: 30px 30px 16px;
}
.selectUploadDownloadModal .modal-body {
  padding: 20px 30px 30px;
}
.selectUploadDownloadModal .selectUploadCheckbox {
  border-radius: 12px;
  background: #ededed;
  padding: 16px 12px;
  margin-bottom: 12px;
}
.selectUploadDownloadModal .selectUploadCheckbox .form-check-label {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.64px;
}
.selectUploadDownloadModal .uploadDownloadTableWrapper td {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.64px;
  text-transform: none;
  padding: 12px;
}
.selectUploadDownloadModal .uploadDownloadTableWrapper td .closeIcon {
  color: #e3653d;
}
.selectUploadDownloadModal .uploadDownloadTableWrapper td .arrowIcon {
  color: #019587;
}
.selectUploadDownloadModal .uploadDownloadTableWrapper td .arrowUpDown {
  display: flex;
  gap: 16px;
}

.importGuestBtn {
  display: flex !important;
  padding: 12px 20px !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 24px;
  background: #000 !important;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.72px;
  margin: 0;
}
.importGuestBtn.disabled,
.importGuestBtn:disabled {
  background: rgba(198, 193, 203, 0.4) !important;
  color: #c9cdd0;
}
.skipBtn {
  display: flex;
  align-items: center;
  border-radius: 100px;
  padding: 8px;
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.56px;
  text-decoration-line: underline;
  white-space: nowrap;
  cursor: pointer;
}

.selectUploadDownloadModal .modal-footer {
  border-top: solid 1px #e7e7e7;
  padding-top: 20px;
  margin-top: 20px;
  display: flex;
  gap: 12px;
  flex-wrap: nowrap;
}

.selectedUploaedFile {
  display: flex;
  align-items: center;
}
.selectedUploaedFile .selectedUploaedFileImg {
  margin-right: 12px;
}
.selectedUploaedFile img {
  width: 53px;
  height: 53px;
  object-fit: contain;
}
.selectedUploaedFile .title {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.56px;
  margin-bottom: 4px;
}
.selectedUploaedFile .subTitle {
  color: #828282;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.48px;
}
.selectUploadGuestList {
  display: flex;
  align-items: center;
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.56px;
}
.selectUploadGuestList img {
  width: 28px;
  height: 28px;
  object-fit: contain;
  margin-right: 12px;
}

.addSpacerBtn {
  height: 22px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  margin-top: 24px;
}
.addSpacerBtn .addSpacerBtnTxt {
  color: #828282;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.48px;
  border: dashed 1px #828282;
  border-radius: 6px;
  height: 22px;
  display: flex;
  align-items: center;
  padding: 0 8px;
}
.addSpacerBtn .icon {
  width: 20px;
  height: 20px;
  color: #828282;
}

.csfFileUploadedWrapper .CSVImporter_FormatRawPreview__scroll {
  background: #e9ecef;
}
.CSVImporter_FileStep__header,
.csfFileUploadedWrapper .CSVImporter_FormatRawPreview__pre {
  font-size: 14px !important;
  list-style: 24px;
}
.eventCardLeft .title .badge {
  padding: 2px 4px;
  color: #fdfdfd;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.6px;
  border-radius: 4px;
  margin-bottom: 12px;
  background: #666;
  font-family: "Inter", sans-serif !important;
  text-transform: inherit;
  margin-left: auto;
}

.useGuestListTableWrapper
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(1),
.useGuestListTableWrapper
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(2),
.useGuestListTableWrapper
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(3),
.useGuestListTableWrapper
  .react-bootstrap-table
  .table
  thead
  tr
  th:nth-child(1),
.useGuestListTableWrapper
  .react-bootstrap-table
  .table
  thead
  tr
  th:nth-child(2),
.useGuestListTableWrapper
  .react-bootstrap-table
  .table
  thead
  tr
  th:nth-child(3) {
  position: sticky;
  z-index: 9;
  left: 0;
  width: 110px;
  min-width: 110px;
  background: #fff !important;
}
.useGuestListTableWrapper
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(2),
.useGuestListTableWrapper
  .react-bootstrap-table
  .table
  thead
  tr
  th:nth-child(2) {
  width: 180px;
  min-width: 180px;
  left: 110px;
}
.useGuestListTableWrapper
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(3),
.useGuestListTableWrapper
  .react-bootstrap-table
  .table
  thead
  tr
  th:nth-child(3) {
  width: 80px;
  min-width: 80px;
  left: 290px;
}
.useGuestListTableWrapper tr td {
  vertical-align: middle;
}
.rsvpInviteCheckList .checkmark {
  left: 0;
  top: 3px;
}
.rsvpInviteCheckList .container3 {
  padding-left: 24px;
  font-size: 14px;
}

.createStoryzModal {
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}
.createStoryzModal .modal-content {
  background-color: white;
  /* height: auto;
  margin: auto; */
  padding: 24px;
  border-radius: 10px;
  float: right;
  max-width: 690px;
  width: 100%;
}

.createStoryzModal .textimgs {
  text-align: center;
  margin-top: 15px;
}

.createStoryzModal .textimgs h2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin-top: 10px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #000000;
}
.createStoryzModal p {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.reviewCard {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
}
.reviewCard .img {
  padding: 12px;
}
.reviewCard .img img {
  width: 112px;
  height: 48px;
  object-fit: cover;
  margin: 0;
  border-radius: 12px;
}
.reviewCard .content {
}
.reviewCard .content .title {
  color: #000;
  font-family: "Great Vibes";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
.reviewCard .content .subTitle {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.loadingModalRSVPModal.modal-dialog {
  max-width: 360px;
}
.loadingModalRSVPModal .modal-content {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 26px 28px;
}
.loadingModalRSVPModal .head {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 12px;
}
.loadingModalRSVPModal p {
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  padding: 12px 36px;
}
.loadingModalRSVPModal .RsvpLoading {
  padding: 4px;
}
.loadingModalRSVPModal .RsvpLoading .circleAnimation1 {
  width: 100px;
  height: 100px;
  margin: auto;
}

.rsvpDeadlineModal.modal-dialog {
  max-width: 592px !important;
}
.rsvpDeadlineModal p {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.confirmNotificationModal {
  max-width: 360px;
}
.confirmNotificationModal .modal-title {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.confirmNotificationModal .modal-body,
.confirmNotificationModal p {
  color: #1f1f1f;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.yourTeamCard .badgeBox {
  position: relative;
}
.yourTeamCard .badgeBox .badge {
  position: absolute;
  height: 12px;
  width: 12px;
  background-color: #fc3158;
  border-radius: 50%;
  left: 60px;
  display: block;
}

.yourTeamCard a {
  color: #666 !important;
  display: block;
}

.appleLoginBtn {
  transform: none !important;
}

.EventdropdownOuter {
  min-width: 300px;
}
.guestListSocial .socialIcon {
  width: 24px;
  height: 24px;
}

.rsvpInviteOuter {
  padding-bottom: 80px !important;
}
.allInviteDeleteBtn {
  position: absolute;
  bottom: -50px;
  right: 0;
}

/* .inviteRSVPTableWrapper .table th:nth-of-type(2){
  max-width: 50%;
  width: 100%;
} */
.fileWrapperMain .fileTopOuter {
  padding-bottom: 12px;
  margin-bottom: 12px;
}
.fileTopWrapper {
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 12px;
  background: rgba(233, 233, 233, 0.25);
}
.fileTopWrapper .fileTopList {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 40px;
}
.fileTopWrapper .fileTopList .fileTopBox {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
}
.fileTopWrapper .fileTopList .fileTopBox .fileTopBoxInner {
  display: flex;
  align-items: center;
}
.fileTopWrapper .fileTopList .fileTopBox .fileTopBoxInner .fileIcon {
  width: 32px;
  height: 32px;
}
.fileTopWrapper .fileTopList .fileTopBox .fileTopBoxInner .fileType {
  padding: 4px 8px;
}

.fileTopWrapper .fileTopList .fileTopBox .fileName {
  padding: 4px 8px;
}

.fileTopWrapper .fileTopList .arrowIcon {
  width: 32px;
  height: 32px;
}
.fileBottomWrapper {
  padding: 24px 0 24px 24px;
  display: grid;
  align-items: flex-start;
  gap: 48px;
  grid-template-columns: 1fr 400px;
}
.g-sidenav-pinned .fileBottomWrapper {
  gap: 24px;
}

.fileBottomWrapper .fileBottomList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  max-height: 415px;
  overflow-y: auto;
}
.fileBottomWrapper .fileBottomList .fileBottomBox {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  padding: 4px;
  transition: all 0.3s ease;
  cursor: pointer;
}
.fileBottomWrapper .fileBottomList .fileBottomBox.active,
.fileBottomWrapper .fileBottomList .fileBottomBox:focus,
.fileBottomWrapper .fileBottomList .fileBottomBox:hover {
  border-radius: 12px;
  background: rgba(198, 193, 203, 0.4);
  transition: all 0.3s ease;
}
.fileBottomWrapper .fileBottomList .fileBottomBox .fileBottomBoxInner {
  display: flex;
  align-items: center;
  min-width: 80px;
}
.fileBottomWrapper
  .fileBottomList
  .fileBottomBox
  .fileBottomBoxInner
  .fileIcon {
  width: 32px;
  height: 32px;
}
.fileBottomWrapper
  .fileBottomList
  .fileBottomBox
  .fileBottomBoxInner
  .fileType {
  padding: 4px 8px;
}

.fileBottomWrapper .fileBottomList .fileBottomBox .fileName {
  padding: 4px 8px;
}

.fileTypeShowWrapper {
  background: rgba(233, 233, 233, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 48px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
  /* width: 433px;
  min-width: 433px; */
}
.fileTypeShowWrapper .head {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.fileTypeShowWrapper .fileDataShowWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}
.fileTypeShowWrapper .fileDataShowWrapper .fileDataLeftWrapper {
  padding: 10px;
  text-align: center;
}
.fileTypeShowWrapper .fileDataShowWrapper .fileDataLeftWrapper .fileImgMain {
  padding-bottom: 12px;
}
.fileTypeShowWrapper
  .fileDataShowWrapper
  .fileDataLeftWrapper
  .fileImgMain
  img {
  width: 72px;
  height: 72px;
}
.fileTypeShowWrapper
  .fileDataShowWrapper
  .fileDataLeftWrapper
  .fileFeatureWrapper {
  display: flex;
  align-items: center;
}
.fileTypeShowWrapper
  .fileDataShowWrapper
  .fileDataLeftWrapper
  .fileFeatureWrapper
  img {
  width: 38px;
  min-width: 38px;
  height: 38px;
  cursor: pointer;
}
.fileTypeShowWrapper .fileDataShowWrapper .fileDataRightWrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.fileTypeShowWrapper .fileDataShowWrapper .fileDataRightWrapper .fileTitle {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.1px;
}
.fileTypeShowWrapper
  .fileDataShowWrapper
  .fileDataRightWrapper
  .fileTitle
  span {
  font-weight: 500;
}
.fileAvatarWrapper {
  padding: 4px 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.fileAvatarWrapper .avatar {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  object-fit: cover;
}
.fileAvatarWrapper .title {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.fileAvatarWrapper .subTitle {
  color: #1f1f1f;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.6px;
}

.fileDeleteModal {
  width: 480px;
  /* padding: 24px; */
}
.fileDeleteModal .modal-content {
  border-radius: 12px;
  background: #fdfdfd;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 24px;
}
.fileDeleteModal .modal-header {
  padding: 0;
  margin-bottom: 16px;
}
.fileDeleteModal .modal-header .modal-title {
  text-align: center;
}
.fileDeleteModal .modal-header .modal-title .icon {
  width: 88px;
  height: 88px;
  margin: 0 auto 12px;
}
.fileDeleteModal .modal-header .modal-title h2 {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-align: center;
  margin: 0;
  padding-bottom: 8px;
}
.fileDeleteModal .modal-body {
  padding: 0;
}
.fileDeleteModal h3 {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  margin-bottom: 16px;
}
.fileDeleteModal p {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 16px;
}
.fileDeleteModal .modal-footer {
  padding: 4px 0;
  margin: 0 -4px;
}

.themeOuterWrapper {
  background-color: #f8f9feb0;
  /* background: #666666; */
  /* padding: 24px; */
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  /* min-height: 948px; */
  min-height: 928px;
  border-radius: 12px;
}

.themeOuterWrapper .badge {
  padding: 2px 4px;
  color: #fdfdfd;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.6px;
  border-radius: 4px;
  background: #666;
  font-family: "Inter", sans-serif !important;
  text-transform: inherit;
  margin-bottom: 12px;
  margin-top: 8px;
}
.themeOuterInner {
  /* background-image: url("../../../img/e7d345fae0c7184e121fa3b3520ce064.png"); */
  /* background-image: url("../../../img/storyBgNew.png"); */
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  border-radius: 12px;
  padding: 24px;
  position: relative;
  min-height: 928px;
}
.dateBtn:hover,
.dateBtn {
  transform: none !important;
  border-color: #979798 !important;
  position: relative;
  text-transform: none;
  transition: all 0.15s ease;
  letter-spacing: 0.025em;
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  background-color: #fff;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  color: #32325d;
}

.adminAccountImg {
  display: flex;
  align-items: center;
}
.keepsakeAvatarCard {
  display: flex;
  align-items: center;
}
.keepsakeAvatarCard .avatarImg {
  width: 56px;
  height: 56px;
  border-radius: 100%;
  margin-right: 12px;
  object-fit: cover;
}
.keepsakeAvatarCard .title {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.4px;
}
.keepsakeAvatarCard .subTitle {
  color: #1f1f1f;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.6px;
}

.GeofenceCoordinates .Section_section__header__iO7WU {
  display: none;
}

.dropdownBoxWrapper .dropdownTriggered.guestGroupUpdated {
  height: auto !important;
}
.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.laodingForContainer {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
}

.honoredFilterDropdown {
  background: rgba(83, 215, 105, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);
  height: 40px;
  border-radius: 8px;
  outline: none;
  padding: 0 32px 0 12px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin: 0;
  color: #666666;
}

.pac-container {
  z-index: 99999;
}

.loggedInCheckbox {
  display: flex;
  align-items: center;
}
.loggedInCheckbox .form-check-input {
  width: 14px;
  height: 14px;
  margin-top: 0 !important;
}

.inviteRSVPTableWrapper .table th {
  border: 1px solid #d7d7d7 !important;
  background: rgba(207, 207, 207, 0.15);
}
.inviteRSVPTableWrapper .table th:nth-of-type(1) {
  width: 110px;
}
.inviteRSVPTableWrapper .table th:nth-of-type(2) {
  flex-grow: 1;
  width: 100%;
}
.inviteRSVPTableWrapper .table th:nth-of-type(3) {
  text-align: right;
}
.inviteRSVPTableWrapper .table td {
  border: none;
}
.appendIconNew .form-control {
  border-right: 0;
  padding-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.appendIconNew .input-group-append .input-group-text {
  border-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 1px solid #d7d7d7;
  border-left: 0;
}

.storyAboutEventWrapper .todolistst .storyzsection {
  max-height: 390px;
  min-height: inherit;
}

.Toastify__toast-container {
  z-index: 9999999 !important;
}
.two-line-trucate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.extentionWrapper {
  height: inherit !important;
  width: inherit !important;
  background: none !important;
  border: none !important;
}
.extentionWrapper .fileExtentionWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 24px;
}
.extentionWrapper .fileExtentionWrapper .imageCropper {
  width: inherit !important;
  height: inherit !important;
  object-fit: contain;
  /* max-width: 48px;
  max-height: 48px; */
}
.extentionWrapper .fileExtentionWrapper .truncate {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.loginFormNew .input-group {
  border-radius: 6px !important;
}
.passInput .form-control {
  border: 1px solid #d7d7d7 !important;
  border-radius: 6px !important;
}

.passInput .input-group-prepend {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.passInput .input-group-prepend .input-group-text {
  background: transparent;
}
.countryMobileWrpper .react-tel-input {
  height: 48px;
}
.countryMobileWrpper .form-control {
  width: 100% !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: 0 !important;
  height: 48px !important;
}
.countryMobileWrpper .form-control:focus {
  box-shadow: none;
}
.countryMobileWrpper .input-group-append {
  height: 48px !important;
}
.countryMobileWrpper .input-group-append .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid #cacaca;
}
.react-datepicker-wrapper {
  width: 100%;
}

.deleteModalFooter{
  flex-wrap: nowrap;
}
.deleteModalFooter .pinkBtn{
  justify-content: center;
}
.deleteModalFooter button{
  width: inherit;
}
body.g-sidenav-pinned.modal-open,
body.g-sidenav-hidden.modal-open{
  padding-right: 0 !important;
}

.loginMethod{
  color: #333;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; 
  letter-spacing: -0.96px;
  margin-bottom: 20px;
}
.loginEmail{
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.64px;
  margin-bottom: 10px;
}

.localLoginBtn{
  display: inline-flex;
  height: 48px;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  border: 1px solid #333;
  cursor: pointer;
  margin-bottom: 8px;
}

.loginMethodFooter{
  color: #828282;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  letter-spacing: -0.48px;
  padding-bottom: 8px;
  margin-bottom: 8px;
  padding-top: 20px;
  border-bottom: solid 1px #E7E7E7;
  border-top: solid 1px #E7E7E7;
  margin: 0 0 8px;
}
.loginMethodFooter a{
  text-decoration: underline;
  font-weight: 600;
  color: #828282;
}

.loginMethodFooter1{
  border: none;
  margin-bottom: 0;
  padding-top: 0;
}
.loginMethodFooter1 a{
  text-decoration: underline;
  font-weight: 600;
  color: #000;
}

.backBtnWrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logniMethodText{
  color: #828282;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; 
  letter-spacing: -0.56px;
}

.passReqwrapper{}
.passReqwrapper .passHead{
  font-weight: 600;
  font-size: 12px;
  color: #333;
  line-height: 1.1;
  margin-bottom: 16px;
}
.passReqwrapper .passReqList{
  list-style: none;
  margin: 0 0 16px 0;
  padding: 0 0 0 40px;
}
.passReqwrapper .passReqList li{
  font-size: 12px;
  color: #828282;
  line-height: 1.1;
  margin-bottom: 16px;
}
.passReqwrapper .passReqList li.active{
  color: #000;
  background: none;
}




.allSetModalWrapper .card-body{
  border-radius: 12px;
}
.allSetModalWrapper .card-footer{
  padding: 20px 0;
}
.allSetModalWrapper .card-footer .eventBtn{
  margin: 0 !important;
}

.allSetModalWrapper .heading{
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #333;
  padding-bottom: 16px;
  text-transform: none;
  border-bottom: solid 1px #E7E7E7;
  margin-bottom: 20px;
}
.allSetModalWrapper .heading img{
  margin-right: 12px;
}
.allSetModalWrapper .succesIcon{
  margin: 20px 0;
  text-align: center;
}
.allSetModalWrapper .succesIcon img{
  padding: 0;
}
.allSetModalWrapper p{
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: #828282;
}


.payNowWrapper{
  width: 600px;
  margin: 0 auto;
}
.payNowWrapper .heading{
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #333;
  padding-bottom: 16px;
  text-transform: none;
  border-bottom: solid 1px #E7E7E7;
  margin-bottom: 20px;
}
.payNowWrapper .heading img{
  margin-right: 12px;
}
.payNowWrapper p{
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: #828282;
}
.payNowWrapper .diyHeading{
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
  margin-bottom: 20px;
}
.payNowWrapper .diyHeading img{
  width: 32px;
  margin-right: 4px;
}
.payNowLine{
  margin: 20px 0;
  height: 1px;
  background: #E7E7E7;
}
.payNowWrapper .card-body{
  border-radius: 12px;
}
.payNowWrapper .card-footer{
  padding: 20px 0;
}

.payNowWrapper .card-footer .eventBtn{
  margin: 0 !important;
}
.paymerntIconWrapper{
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px;
}

.savingOuterWrapper{
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 20px;
}
.savingWrapper{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
}
.savingWrapper .savingList{
  display: flex;
  align-items: center;
  gap: 12px;
}

.savingWrapper .savingList .savingBox{
  padding: 12px 20px 12px 20px;
  border-radius: 12px;
  height: 46px;
  background: #286BEF;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  width: 100px;
  text-align: center;
}
.savingWrapper .savingList .savingTotalBox{
  padding: 12px 20px 12px 20px;
  border-radius: 12px;
  height: 46px;
  background: #FFEDB4;
  color: #333333;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  width: 100px;
  text-align: center;
}
.savingWrapper .savingList .text{
  text-align: right;
}
.cancelBtnText{
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: #000000;
}


.formGroupDiy{
  display: grid;
  grid-template-columns: 65% 1fr;
  gap: 24px;
  font-size: 18px;
  color: #333333;
  margin-bottom: 20px;
  align-items: center;
}
.formGroupDiy .textRight{
  text-align: center;
}
.formGroupDiy .form-check-input{
  margin-top: 5px !important;
}
.formGroupDiy .form-check-label{
  margin-left: 4px;
}
.formGroupDiy .form-check{
  background: #EDEDED;
  padding: 13px 12px 10px 36px;
  border-radius: 12px;
}




.selectServiceLevel .card-body{
  border-radius: 12px;
}
.selectServiceLevel .card-footer{
  padding: 20px 0;
}
.selectServiceLevel .card-footer .eventBtn{
  margin: 0 !important;
}

.selectServiceLevel .heading{
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #333;
  padding-bottom: 16px;
  text-transform: none;
  border-bottom: solid 1px #E7E7E7;
  margin-bottom: 20px;
}
.selectServiceLevel .heading img{
  margin-right: 12px;
}
.selectServiceLevel p{
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: #828282;
}

.changeServiceWrapper{
  margin-bottom: 20px;
}
.changeServiceWrapper .serviceListWrapper{
  display: grid;
  grid-template-columns: 40px 1fr;
  padding: 10px 10px 10px 0;
  /* margin-bottom: 12px; */
}
.changeServiceWrapper .serviceListWrapper.active{
  background: #F5F5F5;
}
.changeServiceWrapper .serviceListWrapper .serviceLeft{
  display: flex;
  align-items: center;
}
.changeServiceWrapper .serviceListWrapper .serviceLeft .form-check{
  margin-left: -12px;
  margin-top: -32px;
}
.changeServiceWrapper .serviceListWrapper .serviceRight{}
.changeServiceWrapper .serviceListInner{}
.changeServiceWrapper .serviceListInner p{
  font-size: 14px;
  font-weight: 500;
  color: #848484;
  line-height: 20px;
  margin-bottom: 8px;
}
.changeServiceWrapper .serviceListInner .serviceHeadWraper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.changeServiceWrapper .serviceListInner .serviceHead{
    display: inline-flex;
    align-items: center;
    gap: 12px;
    color: #FF8B85;
    font-size: 18px;
    font-weight: 600;
}
.changeServiceWrapper .serviceListInner .serviceHead img{}
.changeServiceWrapper .serviceListInner .serviecPrice{
  font-size: 14px;
  font-weight: 600;
  color: #286BEF;
}
.changeServiceWrapper .serviceListInner .serviecPrice span{
  color: #848484;
}

.serviceLineBox{
  padding: 8px 12px;
  position: relative;
}
.serviceLineBox .payNowLine{
  margin: 0;
}
.serviceLineBox img{
  position: absolute;
  margin: auto;
  right: 0;
  top: 0;
  bottom: 0;
}


@media (max-width: 1440px) {
  .g-sidenav-pinned .beaconsCheckbox {
    flex-direction: column;
  }
}

@media (max-width: 1399px) {
  .fileBottomWrapper .fileBottomList {
    grid-template-columns: 1fr 1fr;
  }
  .g-sidenav-pinned .fileBottomWrapper .fileBottomList {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 1299px) {
  .fileBottomWrapper .fileBottomList {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 1200px) {
  .fileBottomWrapper {
    grid-template-columns: 1fr 380px;
  }
  .sidenav {
    /* transform: translateX(0) !important; */
    transform: inherit !important;
  }
  .menuLinkWrapper {
    grid-template-columns: repeat(3, 1fr);
  }
  .eventCardLeft .title {
    font-size: 32px;
  }
  .menuLinkWrapper .menuLink img {
    width: 24px;
    height: 24px;
  }
  .geoSpaceModalWrapper.modal-dialog {
    margin: 8px;
  }
  .billingBoxWrapper {
    grid-template-columns: 1fr 1fr;
  }
  .billingBoxWrapper .billingBoxLeft {
    flex-direction: column;
    padding-left: 0;
  }
  .billingBoxWrapper .billingBoxRight {
    padding-left: 24px;
  }
  .orderSummaryWrapper,
  .paymentMethodWrapper {
    max-width: 100%;
  }

  .g-sidenav-pinned .caldiv .caldivList {
    display: flex;
    flex-direction: column;
  }
  .g-sidenav-pinned .caldiv .caldivList .top {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .eventDashboardCounting {
  }
}
.caldivList {
  display: flex;
  align-items: center;
}
.caldivList .statusIcon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
}
.caldivList .Jakartanormal {
  line-height: 1.3;
}

@media (max-width: 1280px) {
  .geospaceFormModalWrapper .inlineFormBox {
    flex-direction: column;
  }
  .todoTableWrapperInner {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
  }
  .orgTodoWrapper,
  .storyzTodoWrapper {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .aboutDeleteAccount,
  .aboutLogoutWrapper .aboutLogoutList {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 1023px) {
  .dataFundaTable .react-bootstrap-table-pagination {
    flex-direction: column-reverse;
  }
  .dataFundaTable .react-bootstrap-table-pagination .col-sm-6 {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .dataFundaTable .react-bootstrap-table-pagination .col-sm-6 .pagination {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dataFundaTable
    .react-bootstrap-table-pagination
    .col-sm-6
    .dataTables_length {
    padding: 0;
  }
  .dataFundaTable
    .react-bootstrap-table-pagination
    .col-sm-6
    .dataTables_length
    label {
    margin-bottom: 0;
  }
  .storyConfigureModal.modal-dialog {
    margin-left: 8px;
    margin-right: 8px;
  }

  .eventConfigurationFormWrapper
    .eventConfigurationFormRight
    .eventConfigureDateWrapper {
    grid-template-columns: 1fr;
  }

  .orgTodoWrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
  }
}
@media (max-width: 992px) {
  .upgrateRightSection {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  #__filestack-picker .fsp-modal__body {
    width: 100%;
  }
  #__filestack-picker .fsp-modal {
    margin: 5% auto;
    max-width: 90%;
  }
  #__filestack-picker .fsp-picker {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #__filestack-picker .fsp-modal__body--transformer {
    width: 100% !important;
  }

  .organizationInnerWrapper .organizationListheader {
    padding: 8px 0;
    font-size: 24px;
    margin-bottom: 0;
  }
  .organizationInnerWrapper .organizationListSelectAccount {
    padding: 12px 16px;
    margin-bottom: 0;
  }
  .organizationInnerWrapper .organizationListSearchWrapper .form-group {
    padding: 18px 0;
  }
  .organizationInnerWrapper .organizationListSearch {
    padding-left: 0;
    margin-right: -12px;
  }
  .organizationInnerWrapper
    .organizationListSearch
    .organizationListSearchList {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
  .organizationInnerWrapper
    .organizationListSearch
    .organizationListSearchList
    .openAccountBtn {
    margin-left: 48px;
  }
  .eventConfigurationFormWrapper {
    grid-template-columns: 1fr;
  }
  .storyTodoTableWrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
  }
  .inviteNewAdminModal .modal-header .modal-title img {
    width: 60px;
    height: 60px;
  }
  .desktopWebAccessModal.modal-dialog {
    max-width: 420px;
    margin-right: auto;
    margin-left: auto;
  }
  .webListingDesktopOuter {
    flex-direction: column;
  }
  .desktopWebStoreWrapper,
  .webListingDesktopWrapper {
    padding: 0;
    gap: 12px;
  }
  .adminMsgList {
    margin-bottom: 24px;
  }
  .billingBoxWrapper {
    grid-template-columns: auto;
  }
  .billingBoxWrapper .billingBoxLeft {
    padding-right: 0;
    margin-bottom: 24px;
  }
  .billingBoxWrapper .billingBoxRight {
    padding-left: 0;
  }
  .existingGuestLoginWrapper {
    flex-direction: column;
  }
  .existingGuestLoginWrapper .existingGuestLoginLeft {
    padding-right: 0;
  }
  .existingGuestLoginWrapper .existingGuestLoginRight {
    padding-left: 0;
  }
  .orBox {
    position: relative;
    width: 100%;
    height: inherit;
    top: 0;
    left: 0;
    right: 0;
    height: 40px;
    margin-bottom: 24px;
  }
  .orBox::after {
    height: 1px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
  .orBoxtext {
    /* position: relative;
    top: 0;
    left: 0;
    right: 0;
    transform: inherit; */
    height: auto;
    width: 60px;
    left: 50%;
    padding: 4px 24px;
  }
}

@media (max-width: 575px) {
  .eventConfigurationModal .modal-footer {
    flex-direction: column;
  }
  .eventConfigurationModal .modal-footer .btn {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .inviteNewAdminModal .roleTeamOuter {
    padding: 12px 0;
  }
  .settingUnitsWrapper {
    padding-left: 0;
  }
  .menuLinkWrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* @media (min-width: 320px) and (max-width: 1199px) {
  nav.navbar-top.navbar-expand.border-bottom.navbar-dark.navbar {
    position: absolute;
    z-index: 99;
    right: 0;
    top: 45px;
    display: block !important;
  }
  .g-sidenav-show.g-sidenav-pinned .userpading {
    margin-top: 20px;
  }
  body.g-sidenav-show.g-sidenav-pinned .sidenav-header {
    height: 23% !important;
  }
} */
/* @media (min-width: 1200px) {
  .sidenav:hover {
    max-width: 50px !important;
  }
  .sidenav .sidenav-toggler {
    padding: 1.5rem;
  }
} */
/* @media (min-width: 320px) and (max-width: 1199px) {
  nav.navbar-top.navbar-expand.border-bottom.navbar-dark.navbar {
    position: absolute;
    z-index: 99999 !important;
    right: 0;
    top: 45px;
    display: block !important;
  }
} */


/*  */