.TodoModalWrapper {
  &.modal-dialog {
    // max-width: 360px;
    max-width: 400px;
    right: 0;
    max-height: calc(100vh - 16px);
    margin: 8px 8px 8px auto;
  }
  .modal-content {
    height: 100%;
  }
  .modal-header {
    padding: 12px;
    height: 48px;
    background: #286bef;
    color: #fff;
    align-items: center;
    .modal-title {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #fdfdfd;
    }
    .close {
      margin: 0;
      position: absolute;
      right: 12px;
      top: 12px;
      padding: 0;
      opacity: 1;
      span {
        color: #fff;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
      }
    }
  }
  .formBox {
    padding: 8px 12px;
    margin-bottom: 4px !important;
  }
  .form-label {
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #666666;
  }
  .form-control {
    border: 1px solid #d7d7d7;
    border-radius: 6px;
    height: 48px;
    background: #ffffff;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #000000;
  }

  .todoThumWrapper {
    display: flex;
    align-items: center;
    padding: 4px 12px;
    margin-bottom: 12px;
    .todoDate {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #1f1f1f;
      margin: 0 8px 0 0;
    }
    .todoThumImg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .todoThum {
      margin: 0 8px 0 0;
      width: 48px;
      height: 48px;
      .todoThumImg {
        border-radius: 100%;
      }
    }
    .orgTodoLogo {
      margin: 0 8px 0 0;
      width: 32px;
      height: 32px;
      .todoThumImg{
        border-radius: 100%;
      }
    }
    .todoEventThumb {
      width: 79px;
      height: 53px;
      margin: 0 8px 0 0;
      .todoThumImg {
        border-radius: 12px;
      }
    }

    .todothumbTitle {
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #1f1f1f;
    }
    .todothumbSubTitle {
      font-weight: 300;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.4px;
      color: #1f1f1f;
    }
  }
  .dateWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
  .form-text {
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.4px;
    color: #666666;
  }
  textarea.form-control {
    height: inherit;
  }
  .arrowBtn {
    width: 36px;
    height: 36px;
    cursor: pointer;
  }
  .todoBtn:hover,
  .todoBtn {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.4px;
    color: #fdfdfd;
    background: #147efb;
    border-radius: 8px;
    padding: 8px 24px;
    margin: 0;
    height: 40px;
    border: none;
    transform: none;
    box-shadow: none;
  }
  .statusWrapper {
    background: #f8f8f8;
    padding: 6px 12px 0;
  }
  .statusListWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 12px 8px;
    gap: 12px;
    .statusList {
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #1f1f1f;
      padding: 8px 16px;
      cursor: pointer;
      &.active {
        background: rgba(254, 203, 46, 0.2);
        border-radius: 8px;
      }
      .icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }
  }

  // .modalContainerScroll{
  //   overflow-y: auto;
  //   max-height: calc(100vh - 144px);
  // }
}
