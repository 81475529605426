.dropdownBoxWrapperPort {
    // width: 100%;
    position: relative;
    &.with348 {
      max-width: 348px;
    }

    .dropdownTriggered {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 6px;
      width: 100% !important;
      height: 40px !important;
      position: relative;
      &.heightAuto{
        height: inherit !important;
        min-height: 40px;
      }
      .dropdownPlaceholder {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        .plaeholderInput {
          font-weight: 300;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.4px;
          color: #000000;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          height: 40px;
          padding-right: 44px;
          cursor: pointer;
          padding: 8px 12px;

        }
        .selectInput{
          background: rgba(83,215,105,0.1);
          border: 1px solid rgba(0,0,0,0.05);
          height: 38px;
          border-radius: 8px;
          outline: none;
          padding: 0 32px 0 12px;
          display: flex;
          align-items: center;
          // min-width: 120px;
          white-space: nowrap;
          overflow: hidden;
          margin: 0;
        }
      }

      .arrowIcon {
        border: none;
        background: none;
        color: #979798;
        margin: 0 8px;
        position: relative;
        z-index: 1;
        width: 24px;
        height: 100%;
        pointer-events: none;
        position: absolute;
        right: 0;
      }
    }

    .placeholderText{
        background: rgba(83,215,105,0.1);
        border: 1px solid rgba(0,0,0,0.05);
        border-radius: 8px;
        cursor: pointer;
        .dropdownPlaceholder{
            width: 100%;
            justify-content: space-between;
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.4px;
            color: #666666;
            height: 100%;
        }
    }

    
  }

.dropdownBoxMenuWrapperPort {
    width: 100%;
    max-width: 260px;
    padding: 12px 0;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;

    visibility: hidden;
    opacity: 0;
    transform: translateY(10px);
    transition: all 0.2s ease-in-out;
    position: absolute;
    top: calc(100% + 4px);
    z-index: 92;
    background: #fff;
    min-width: 200px;

    &.active {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      transition: all 0.2s ease-in-out;
      background: #fff;
    }


    &.guestListWidth430{
      max-width: 430px;
      width: 100%;
      right: 0;
    }


    .dropdownBoxMenuInner {
    }

    .dropdownSearchWrapper {
      margin: 15px 12px;
    }
    .dropdownMenuListWrapper {
      list-style: none;
      margin: 0;
      padding: 0;
      max-height: 200px;
      overflow-y: auto;
      .menuItem {
          padding: 6px 8px;
          transition: all 0.2s ease-in-out;
          border-radius: 0;
          font-weight: 300;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.4px;
          color: #1F1F1F;
          cursor: pointer;
          .icon{
              font-size: 24px;
              color: #1F1F1F;
              margin-right: 8px;
          }
          &:disabled{
           opacity: 0.5;
          }
          &:hover{
              background: #e9ecef;
              transition: all 0.2s ease-in-out;
          }
          &:focus{
              background: #dde0e3;
              transition: all 0.2s ease-in-out;
          }
          &.captionTitle{
              &:hover{
                  background: transparent;
              }
              .guestList{
                  font-weight: bold;
              }
          }
          a{
            color: #1F1F1F;
            display: block;
          }
      }
      .guestList {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 300;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.4px;
        color: #1f1f1f;
        padding: 4px 8px;
        cursor: pointer;
        .guestUserDetails {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .Avatar {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            border-radius: 100%;
            object-fit: cover;
          }
        }
        .guestType {
        }
      }
    }
    .bold{
      font-weight: bold !important;
    }
    &.left{
      left: 0;
    }
  }

.dropdownBoxWrapperSearch {
    width: 100%;
    position: relative;
    &.with348 {
      max-width: 348px;
    }

    .dropdownTriggered {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 6px;
      width: 100% !important;
      height: 40px !important;
      position: relative;

      .dropdownPlaceholder {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        .plaeholderInput {
          font-weight: 300;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.4px;
          color: #000000;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          height: 40px;
          padding-right: 44px;
          cursor: pointer;
          padding: 8px 12px;

        }
        .selectInput{
          background: rgba(83,215,105,0.1);
          border: 1px solid rgba(0,0,0,0.05);
          height: 38px;
          border-radius: 8px;
          outline: none;
          padding: 0 32px 0 12px;
          display: flex;
          align-items: center;
          // min-width: 120px;
          white-space: nowrap;
          overflow: hidden;
          margin: 0;
        }
      }

      .arrowIcon {
        border: none;
        background: none;
        color: #979798;
        margin: 0 8px;
        position: relative;
        z-index: 1;
        width: 24px;
        height: 100%;
        pointer-events: none;
        position: absolute;
        right: 0;
      }
    }

    .placeholderText{
        background: rgba(83,215,105,0.1);
        border: 1px solid rgba(0,0,0,0.05);
        border-radius: 8px;
        cursor: pointer;
        .dropdownPlaceholder{
            width: 100%;
            justify-content: space-between;
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.4px;
            color: #666666;
        }
    }

    
  }

.dropdownBoxMenuWrapperSearch {
    width: 100%;
    padding: 12px 0;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;

    visibility: hidden;
    opacity: 0;
    transform: translateY(10px);
    transition: all 0.2s ease-in-out;
    position: absolute;
    top: calc(100% + 4px);
    z-index: 92;
    background: #fff;
    min-width: 200px;

    &.active {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      transition: all 0.2s ease-in-out;
      background: #fff;
    }


    &.guestListWidth400{
      width: 400px;
      right: 0;
    }


    .dropdownBoxMenuInner {
    }

    .dropdownSearchWrapper {
      margin: 15px 12px;
    }
    .dropdownMenuListWrapper {
      list-style: none;
      margin: 0;
      padding: 0;
      max-height: 200px;
      overflow-y: auto;
      .menuItem {
          padding: 6px 8px;
          transition: all 0.2s ease-in-out;
          border-radius: 0;
          font-weight: 300;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.4px;
          color: #1F1F1F;
          cursor: pointer;
          .icon{
              font-size: 24px;
              color: #1F1F1F;
              margin-right: 8px;
          }
          &:disabled{
            opacity: 0.5;
          }
          &:hover{
              background: #e9ecef;
              transition: all 0.2s ease-in-out;
          }
          &:focus{
              background: #dde0e3;
              transition: all 0.2s ease-in-out;
          }
          &.captionTitle{
              &:hover{
                  background: transparent;
              }
              .guestList{
                  font-weight: bold;
              }
          }
          a{
            color: #1F1F1F;
            display: block;
          }
      }
      .guestList {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 300;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.4px;
        color: #1f1f1f;
        padding: 4px 8px;
        cursor: pointer;
        .guestUserDetails {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .Avatar {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            border-radius: 100%;
            object-fit: cover;
          }
        }
        .guestType {
        }
      }
    }
    .bold{
      font-weight: bold !important;
    }
    &.left{
      left: 0;
    }
  }