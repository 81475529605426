@use "../../styles/mixins";
@use "../../styles/variables";

.textfield {
  width: var(--width);
  display: flex;
  flex-direction: column;

  gap: 10px;

  &__label {
    @include mixins.text(14px, 400, 24px, variables.$secondary_color);
  }

  &__input {
    border: 1px solid #d7d7d7;
    border-radius: 6px;
    padding: 12px;
    @include mixins.text(14px, 300, 24px);
  }
}
