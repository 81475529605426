
.modal-dialog.mediaModalWrapper1 {
  width: 480px !important;
  position: fixed !important;
  z-index: 99991;
  right: 8px !important;
  top: 8px !important;
  bottom: 8px !important;
  margin: 0;
}
.mediaModalWrapper1 .modal-content{
  padding: 24px;
  height: 100%;
  overflow-y: auto;
}
.mediaOverlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
}
.mediaModalWrapper1 .closeIcon {
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 1;
  cursor: pointer;
}
.mediaModalWrapper1 .closeIcon .closeBtn {
  width: 16px;
  fill: rgba(0, 0, 0, 0.6);
}

.mediaModalWrapper1.ShowModal {
  display: block;
}
.mediaModalWrapper1 .mediaModalHeaderWrapper {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #000000;
  padding-bottom: 8px;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 24px;
}
.mediaModalWrapper1 .mediaModalHeaderWrapper .mediaLogo {
  border: 4px solid #286bef;
  width: 88px;
  height: 88px;
  border-radius: 100%;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mediaModalWrapper1 .mediaModalHeaderWrapper .mediaLogo .mediaLogoIcon {
  color: #286bef;
  font-size: 48px;
}
.mediaModalWrapper1 .mediaUserDetails {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 4px 8px;
}
.mediaModalWrapper1 .mediaUserDetails .mediaUserImgWrapper {
  width: 48px;
  margin-right: 8px;
}
.mediaModalWrapper1 .mediaUserDetails .mediaUserImgWrapper .mediaUserImg {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  object-fit: cover;
}
.mediaModalWrapper1 .mediaImageWrapper {
  // margin-bottom: 20px;
  max-width: 360px;
  width: 100%;
  margin: 0 auto;
}
.mediaModalWrapper1 .mediaImageWrapper .date {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #49454f;
  padding: 8px;
  text-align: center;
}
.mediaModalWrapper1 .imageCropperWrapper {
  height: 136px;
  width: 100%;
  position: relative;
  background: #d7d7d7;
  border: solid 1px #d7d7d7;
  margin-bottom: 8px;
}

.mediaModalWrapper1 .imageCropperWrapper .uploadImageFromLocalContent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  width: 40px;
  height: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mediaModalWrapper1 .imageCropperWrapper .uploadImageFromLocalContent .faPlus {
  color: rgba(0, 0, 0, 0.6);
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.mediaModalWrapper1 .imageCropperWrapper .imageCropper {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0 !important;
  /* object-fit: cover; */
}

.mediaModalWrapper1 .imageEditIcons {
  padding: 6px 0;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
}
.mediaModalWrapper1 .imageEditIcons .button {
  margin-left: 20px;
  font-size: 16px;
  border: none;
  background: no-repeat;
}

.mediaUserContentWrapper {
}
.mediaUserContentWrapper .mediaUserTitle {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #000000;
}
.mediaUserContentWrapper .mediaUserDesignation {
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.6px;
  color: #1f1f1f;
}

.mediaModalWrapper1 .filenameWrapper {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #49454f;
  margin-bottom: 4px;
  padding: 16px 12px 4px;
}
.mediaModalWrapper1 .filenameWrapper span {
  font-weight: 600;
  display: inline-block;
}
.mediaFormWrapper1 {
  padding: 0 8px;
}
.mediaFormWrapper1 .formBox {
  // padding-bottom: 36px;
  padding: 8px;
}
.mediaFormWrapper1 .formBox .form-label {
  padding: 4px 4px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #666666;
}
.mediaFormWrapper1 .locationMap {
  align-items: center;
  display: grid;
  grid-template-columns: 60% 1fr;
}
.mediaFormWrapper1 .map {
  display: inline-flex;
  flex-direction: column;
  margin: 0 0 0 40px;
}
.mediaFormWrapper1 .diamondTrunImg {
  font-size: 28px;
  width: 28px;
}
.mediaFormWrapper1 .formBox .form-control {
  height: 48px;
  border: 1px solid #d7d7d7;
  border-radius: 6px;
  background: #ffffff;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #1f1f1f;
}
.mediaFormWrapper1 .formBox .form-control::placeholder {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #979798;
}
.mediaFormWrapper1 .formBox textarea.form-control {
  height: 86px;
}
.mediaFormWrapper1 .actionWrapper {
  display: grid;
  grid-template-columns: 1fr 100px;
  grid-gap: 12px;
  margin-top: 64px;
  padding: 0;
}
.mediaFormWrapper1 .actionWrapper .confirmBtn {
  /* width: 327px; */
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #ffffff;
  height: 40px;
  background: #147efb;
  border-radius: 8px;
  width: 100%;
  margin: 0;
}
.mediaFormWrapper1 .actionWrapper .CancelBtn {
  width: 93px;
  height: 40px;
  border: 2px solid #147efb;
  border-radius: 8px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4px;
  color: #666666;
}
.modal-dialog.modal-dialog-centered.floatingSearchDialog.is-visible.is-visible-expend {
  width: 400px;
  height: 200px;
  left: 22px;
  position: absolute;
}
.datastorimg {
  margin-right: 10px;
  width: 195px;
  height: 90px;
  border-radius: 10px;
}
.modal {
  z-index: 9999 !important;
  background: rgba(0, 0, 0, 0.2);
}
.show {
  display: block !important;
}

.mediaFileListModal1.modal-dialog {
  max-width: 615px;
}


.mediaFileListModal1 .modal-dialog .modal-content {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}

.mediaFileListModal1 .btn-close {
  background: none;
  border: none;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.mediaFileListModal1 .modal-header {
    border-bottom: 1px solid #e7e7e7;
    align-items: center;
    padding: 24px 24px 12px;
}
.mediaFileListModal1 .modal-title {
    font-weight: 400;
    font-size: 14px;
    color: #000000;
}

.importFromLocalModal1 .modal-title {
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}
.importFromLocalModal1 .btn-close {
  background: none;
  border: none;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.importFromLocalModal1 .btn-close .closeBtn {
  fill: rgba(0, 0, 0, 0.6);
}
.importFromLocalModal1 .selectFilebtn {
  width: 105px;
  height: 39px;
  background: rgba(198, 193, 203, 0.2);
  border-radius: 100px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #1d192b;
  padding: 0;
  margin: 0;
}
.importFromLocalModal1 .modal-header {
  border-bottom: 1px solid #e7e7e7;
  align-items: center;
  padding: 24px 24px 12px;
}
.mediaFileListModal1 .modal-body {
  padding-top: 42px;
  padding-bottom: 20px;
}
.mediaFileListModal1 .btn-close .closeBtn {
  fill: rgba(0, 0, 0, 0.6);
}
.mediaFileListModal1 .importImageWrapper1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  float: right;
  padding-bottom: 3px;
  position: relative;
}
.fileInput {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

input[type="file"],
input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}

.mediaFileListModal1 .importImageListWrapper1 {
  padding: 0 24px;
}
.mediaFileListModal1 .importImageWrapper1 .importIcon {
  max-width: 25px;
  margin-bottom: 8px;
}
.mediaFileListModal1 .importedImageListWrapper1 {
  border-top: 1px solid #e7e7e7;
  padding: 3px 0;
  clear: both;
}

.mediaFileListModal1 .importedImageListWrapper1 .importedImageListInner1 {
  max-height: 340px;
  min-height: 100px;
  overflow-y: auto;
}
.mediaFileListModal1 .selectFilebtn {
  width: 105px;
  height: 39px;
  background: rgba(198, 193, 203, 0.2);
  border-radius: 100px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #1d192b;
  padding: 0;
  margin: 0;
}
.mediaFileListModal1 .modal-footer {
  padding-top: 0;
  padding-bottom: 46px;
}

.importedImageList1 {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  transition: all 0.3s ease-in-out;
}
.importedImageList1:hover,
.importedImageList1.selected {
  background: #f8f8f8;
  transition: all 0.3s ease-in-out;
}
.importedImageList1 .uploadedItem {
  margin-right: 24px;
}
.importedImageList1 .uploadedItem .uploadedImg {
  width: 60px;
  height: 48px;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}
.importedImageList1 .uploadedItem .uploadedImg .playIcon {
  background: rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(198, 193, 203, 0.4);
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
}
.importedImageList1 .uploadedItem .uploadedImg .playIcon .fa-play {
  font-size: 12px;
  color: #000;
}
.importedImageList1 .uploadedItem .uploadedImg .thum {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.importedImageList1 .filename {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.importFromLocalContent1 {
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #000000;
  display: flex;
  flex-wrap: wrap;
  line-height: 24px;
  margin-bottom: 20px;
}
.importFromLocalContent1 .importFromLocalContentLeft {
  margin-right: 10px;
}
.importFromLocalContent1 .importFromLocalContentLeft .faImage {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.6);
}

.uploadImageFromLocalWrapper1 {
  width: 100%;
  height: 144px;
  border-radius: 6px;
  overflow: hidden;
  background: #d7d7d7;
  border: 1px solid #d7d7d7;
  position: relative;
}
.uploadImageFromLocalWrapper1 .uploadImageFromLocalImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.uploadImageFromLocalWrapper1 .uploadImageFromLocalContent {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  width: 40px;
  height: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.uploadImageFromLocalWrapper1 .uploadImageFromLocalContent .faPlus {
  color: rgba(0, 0, 0, 0.6);
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
.importFromLocalModal1.modal-dialog{
  max-width: 615px;
}
.importFromLocalModal1 .modal-header {
  padding: 12px 24px;
}


.importImageFromLocalContainer1 {
  flex-direction: row !important;
  overflow: hidden;
}
.importFromLocalLeftWrapper1 {
  width: 234px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 12px 0px 0px 12px;
  min-height: 100%;
  overflow-y: auto;
  padding-bottom: 24px;
}
.importFromLocalLeftWrapper1 .importFromLocalLefList {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #1f1f1f;
  display: flex;
  align-items: center;
  padding: 6px 8px;
  background: #ffffff;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.importFromLocalLeftWrapper1 .importFromLocalLefList.active,
.importFromLocalLeftWrapper1 .importFromLocalLefList:hover {
  background: #f4f4f4;
  transition: all 0.3s ease-in-out;
}
.importFromLocalLeftWrapper1 .importFromLocalLefList .importFromLocalIconBox {
  margin-right: 12px;
}
.importFromLocalLeftWrapper1
  .importFromLocalLefList
  .importFromLocalIconBox
  .importFromLocalIcon {
  width: 48px;
  height: 48px;
}

