.dropdownBoxWrapper {
    width: 100%;
    position: relative;
    &.with348 {
      max-width: 348px;
    }

    .dropdownTriggered {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 6px;
      width: 100% !important;
      height: 40px !important;
      position: relative;

      .dropdownPlaceholder {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        .plaeholderInput {
          font-weight: 300;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.4px;
          color: #000000;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          height: 40px;
          padding-right: 44px;
          cursor: pointer;
          padding: 8px 12px;

        }
        .selectInput{
          background: rgba(83,215,105,0.1);
          border: 1px solid rgba(0,0,0,0.05);
          height: 38px;
          border-radius: 8px;
          outline: none;
          padding: 0 32px 0 12px;
          display: flex;
          align-items: center;
          // min-width: 120px;
          white-space: nowrap;
          overflow: hidden;
          margin: 0;
        }
      }

      .arrowIcon {
        border: none;
        background: none;
        color: #979798;
        margin: 0 8px;
        position: relative;
        z-index: 1;
        width: 24px;
        height: 24px;
        pointer-events: none;
        position: absolute;
        right: 0;
      }
    }

    .placeholderText{
        background: rgba(83,215,105,0.1);
        border: 1px solid rgba(0,0,0,0.05);
        border-radius: 8px;
        cursor: pointer;
        
        .dropdownPlaceholder{
            width: 100%;
            justify-content: space-between;
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.4px;
            color: #666666;
            padding-right: 24px;
        }
    }

    .dropdownBoxMenuWrapper {
      width: 100%;

      padding: 12px 0;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 12px;

      visibility: hidden;
      opacity: 0;
      transform: translateY(10px);
      transition: all 0.2s ease-in-out;
      position: absolute;
      top: calc(100% + 4px);
      z-index: 92;
      background: #fff;
      min-width: 200px;

      &.active {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
        transition: all 0.2s ease-in-out;
      }


      &.guestListWidth430{
        width: 430px;
        right: 0;
      }


      .dropdownBoxMenuInner {
      }

      .dropdownSearchWrapper {
        margin: 15px 12px;
      }
      .dropdownMenuListWrapper {
        list-style: none;
        margin: 0;
        padding: 0;
        // min-height: 160px;
        max-height: 200px;
        overflow-y: auto;
        overflow-x: hidden;
        .menuItem {
            padding: 6px 8px;
            transition: all 0.2s ease-in-out;
            border-radius: 0;
            font-weight: 300;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.4px;
            color: #1F1F1F;
            cursor: pointer;
            &:hover{
                background: #e9ecef;
                transition: all 0.2s ease-in-out;
            }
            &:focus{
                background: #dde0e3;
                transition: all 0.2s ease-in-out;
            }
            &.captionTitle{
                &:hover{
                    background: transparent;
                }
                .guestList{
                    font-weight: bold;
                }
            }
            a{
              color: #1F1F1F;
              display: block;
            }
            .icon{
              margin-right: 8px;
              white-space: nowrap;
            }
        }
        .guestList {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 300;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.4px;
          color: #1f1f1f;
          padding: 4px 8px;
          cursor: pointer;
          .guestUserDetails {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .Avatar {
              width: 24px;
              height: 24px;
              margin-right: 8px;
              border-radius: 100%;
              object-fit: cover;
            }
          }
          .guestType {
          }
        }
      }
      .bold{
        font-weight: bold !important;
      }
      &.left{
        left: 0;
      }
    }
  }

  .simpletDropdownWrapper{
    width: inherit;
    .dropdownTriggered{
      width: 36px !important;
      height: 36px !important;
      padding: 0;
    }
    .dropdownBoxMenuWrapper{
      width: 255px;
      .dropdownMenuListWrapper{
        // min-height: 160px;
        .menuItem{
          padding:6px 8px;
          color: #1F1F1F;
          font-weight: 300;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.4px;
          cursor: pointer;
          &.disabled{
            opacity: 0.5;
            pointer-events: none;
            cursor: not-allowed;
           }

          .icon{
            width: 20px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
            display: inline-block;
            img{
              max-width: 100%;
            }
          }
        }
      }
    }
  }
  .disabled{
    background: rgba(207, 207, 207, 0.15);
    pointer-events: none;
    opacity: 0.5;
    .css-1s2u09g-control{
        background: rgba(207, 207, 207, 0.15) !important;
    }
  }



.teamRoleDropdown  span.icon{
  margin-right: 8px;
  display: inline-block;
  width: 24px;
  height: 24px;
}
.teamRoleDropdown  span.icon img{
  max-width: 24px;
  max-height: 24px;
  padding: 2px;
}
.teamRoleDropdown .menuItem {
  cursor: pointer;
}
.teamRoleDropdown .disabled{
  background: none !important;
  opacity: 0.5 !important;
}
.teamRoleDropdown{
  max-width: 260px;
}
.teamRoleDropdown .dropdownPlaceholder,
.teamRoleDropdown .selectInput{
  width: 100%;
  font-size: 14px;
  color: #666;
}

.eventTypeDropdownOuter .placeholderText{
  background: none !important;
  border: none !important;
  border-radius: 0 !important;
}
.eventTypeDropdownOuter .arrowIcon{
  display: none;
}
.eventTypeDropdownOuter .dropdownBoxWrapper .placeholderText .dropdownPlaceholder{
  padding-right: 0;
  color: #1f1f1f;
}

.eventTypeDropdownOuter .dropdownBoxMenuWrapper{
  width: 280px;
}

.fullwidthDrodown{
  .dropdownPlaceholder{
    width: 100%;
  }
  .selectInput{
    width: 100%;
  }
}