@use "../../styles/mixins";
@use "../../styles/variables";

.numberfield {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__label {
    @include mixins.text(14px, 400, 24px, variables.$secondary_color);
  }

  &__input_container {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 6px;
  }

  &__arrow_container {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__input {
    width: var(--width);
    border: 1px solid #d7d7d7;
    border-radius: 6px;
    padding: 6px;
    @include mixins.text(14px, 300, 24px);

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }
  }
}
