@use "../../styles/mixins";

.section {
  width: 100%;
  min-width: 430px;

  &__header {
    padding: 12px;
    background-color: rgba(198, 193, 203, 0.3);
    border-radius: 6px;
  }

  .header {
    &__title {
      @include mixins.text(18px, 400, 24px);
      margin-bottom: 0;
    }
  }

  &__content {
    padding-right: 23px;
    padding-left: 23px;
    padding-bottom: 14px;
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
}

