@use "../../styles/mixins";
@use "../../styles/variables";

.address_field {
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 12px;

  &__label {
    @include mixins.text(14px, 400, 24px, variables.$secondary_color);
    margin-bottom: 0;
  }

  &__textarea {
    resize: none;
    border: 1px solid #666666;
    border-radius: 6px;

    padding-left: 40px;
    @include mixins.text(14px, 400, 24px);
    min-height: 80px;
  }

  &__dummy {
    border: 1px solid #666666;
    border-radius: 6px;
    padding: 0 40px;
    @include mixins.text(14px, 400, 24px);
    min-height: 80px;
    display: flex;
    align-items: center;
    word-break: break-word;
    &_disabled {
      background-color: #efefef4d;
      border-color: #d7d7d7;
      color: #666666;
    }
  }
}
